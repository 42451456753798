import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Box, Button, FormControl, MenuItem, Select, Typography } from '@mui/material';
import Header from '../../components/Header/Header';
import TransactionTable from '../../components/ReportsTable/MF/MFReportsTable';
import DatePicker from '../../components/DatePicker/DateRangePicker'; // Ensure the import path is correct
import { useDispatch } from 'react-redux';
import { SelectChangeEvent } from '@mui/material';
import { getMF } from '../../redux/mf/mfSlice'; // Ensure the import path is correct

const MFTransactions: React.FC = () => {
  const dispatch = useDispatch<any>();

  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
  const [selectedFundType, setSelectedFundType] = useState<string>('');
  const [mfData, setMfData] = useState<any[]>([]);

  const handleSearch = async () => {
    if (dateRange[0] && dateRange[1]) {
      const startDate = dateRange[0].valueOf();
      const endDate = dateRange[1].valueOf();

      try {
        const payload = {
          startDate,
          endDate,
          fundType: selectedFundType,
        };

        const response = await dispatch(getMF(payload));
        if (response.payload && response.payload.data) {
          setMfData(response.payload.data);
        } else {
          console.error('Invalid response structure', response);
        }
      } catch (error) {
        console.error('Failed to load user', error);
      }
    }
  };

  const handleDateRangeChange = (newRange: [Dayjs | null, Dayjs | null]) => {
    setDateRange(newRange);
  };

  const handleFundTypeChange = (event: SelectChangeEvent<string>) => {
    setSelectedFundType(event.target.value);
  };

  return (
    <>
      <Header />
      <div style={{ padding: '50px 100px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
          <DatePicker onDateRangeChange={handleDateRangeChange} />
          <FormControl style={{ width: '20%' }}>
            <Select
              labelId="fund-type-label"
              id="fund-type-select"
              value={selectedFundType}
              onChange={handleFundTypeChange}
              displayEmpty
            >
              <MenuItem value="" disabled>
                <em>Select Fund Type</em>
              </MenuItem>
              <MenuItem value="DEBT">Debt</MenuItem>
              <MenuItem value="EQUITY">Equity</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            style={{ width: '15%' }}
          >
            Search
          </Button>
        </div>
        <Box mt={4}>
          <TransactionTable
            startDate={dateRange[0]?.valueOf() || null}
            endDate={dateRange[1]?.valueOf() || null}
            fundType={selectedFundType}
            mfData={mfData} // Pass the fetched data to the TransactionTable
          />
        </Box>
      </div>
    </>
  );
};

export default MFTransactions;
