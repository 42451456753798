import api from "../../config/apiConfig";

const notification = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("admin/notification/getall", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    console.log('notification data',response.data)
    return response.data;
}

const getNotificationById = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("admin/notification/getById", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    console.log('notification data',response.data)
    return response.data;
}

const changeStatus = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("admin/updateRequest/changeStatus", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    console.log('status request',response.data)
    return response.data;
}


const requestCount = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("admin/notification/getCount", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    console.log('status request',response.data)
    return response.data;
}

const readRequest = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("admin/notification/read", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    console.log('status request',response.data)
    return response.data;
}

const notificationService = { notification, changeStatus, getNotificationById, requestCount, readRequest };
export default notificationService;