import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { colors } from '../../constants/colors';
import { getUserById } from '../../redux/user/userSlice';
import { useDispatch } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface Preference {
  [key: string]: number; // Allows dynamic keys
}

interface UserData {
  preferences: Preference;
  // other fields...
}

const Preferences: React.FC = () => {
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [noOptionsMessage, setNoOptionsMessage] = useState<string | null>(null);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const queryParams = new URLSearchParams(location.search);
      const userIdString = queryParams.get('userId');

      if (!userIdString) {
        setError('User ID is not available');
        setLoading(false);
        return;
      }

      const userId = Number(userIdString);

      if (isNaN(userId)) {
        setError('Invalid User ID');
        setLoading(false);
        return;
      }

      try {
        const result = await dispatch(getUserById({ userId }));
        setUserData(result.payload.data[0]);
        console.log('user data', userData);
        
        setLoading(false);

        const { preferences } = result.payload.data[0] || {};
        console.log(preferences, ' preferences');
        
        if (!preferences) {
          setNoOptionsMessage('No preferences available.');
          return;
        }

        const options = [
          { value: 'mutualFunds', label: 'Mutual Fund' },
          { value: 'corporateFD', label: 'Corporate FD' },
          { value: 'aif', label: 'AIF' },
          { value: 'pms', label: 'PMS' },
          { value: 'bonds', label: 'Bonds' },
          { value: 'equity', label: 'Equity' }
        ].filter(option => preferences[option.value] === 1);

        if (options.length === 0) {
          setNoOptionsMessage('No preferences available.');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to fetch user data');
        setLoading(false);
      }
    };

    fetchUserData();
  }, [location.search, dispatch]);

  const handleNext = () => {
    const queryParams = new URLSearchParams(location.search);
      const userIdString = queryParams.get('userId');

    if (selectedOption === '') {
      alert('Please select an option before proceeding.');
      return;
    }
    const userId = Number(userIdString);

    // Navigate to the Upload page after selecting an option
    navigate(`/preference/${selectedOption}/${userId}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const { preferences } = userData || {};

  const options = [
    { value: 'mutualFunds', label: 'Mutual Fund' },
    { value: 'corporateFD', label: 'Corporate FD' },
    { value: 'aif', label: 'AIF' },
    { value: 'pms', label: 'PMS' },
    { value: 'bonds', label: 'Bonds' },
    { value: 'equity', label: 'Equity' }
  ].filter(option => preferences && preferences[option.value] === 1);

  return (
    <>
      <Header />
      <div className="p-4 mb-48 mx-auto" style={{ width: '90%' }}>
        {/* <ArrowBackIcon  sx={{marginBottom:"5px",fontSize:"30px",marginLeft:"-5px",cursor:"pointer"}} onClick={()=>navigate(-1)}/> */}
        <div className="flex justify-between items-center mb-20">
          <h1 className="text-2xl font-bold" style={{ color: colors.primary }}>Preferences</h1>
        </div>

        <div className="flex flex-col items-start mb-10">
          <form>
            <p className="text-xl mb-4" style={{ color: colors.textGrey }}>
              Choose your option
            </p>

            {noOptionsMessage ? (
              <div className="text-red-500 mb-4">{noOptionsMessage}</div>
            ) : (
              <div className="flex gap-8 mb-4">
                {options.length > 0 ? (
                  options.map(option => (
                    <label key={option.value} className="flex items-center text-lg">
                      <input
                        type="radio"
                        value={option.value}
                        checked={selectedOption === option.value}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        className="mr-2 h-4 w-4"
                      />
                      {option.label}
                    </label>
                  ))
                ) : (
                  <div className="text-red-500">No options available.</div>
                )}
              </div>
            )}
            <button
              type="button"
              onClick={handleNext}
              className="mt-4 px-6 py-3 bg-primary text-white rounded"
              style={{ backgroundColor: colors.primary }}
            >
              Next
            </button>
            <button
              type="button"
              onClick={()=>navigate(-1)}
              className="mt-4 px-6 py-3 bg-primary text-white rounded ml-4"
              style={{ backgroundColor: colors.primary }}
            >
              Back
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Preferences;
