import api from "../../config/apiConfig";

const updateBank = async (data: FormData) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/bank/update", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data;
}

const bankService = { updateBank };
export default bankService;