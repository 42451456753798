import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Header from '../../components/Header/Header';
import MFTable from '../../components/ReportsTable/MF/MFTable';
import { notification } from '../../redux/notification/notificationSlice';
import { changeStatus } from '../../redux/portfolio/changeStatus/statusSlice';

const MFReports: React.FC = () => {
    const [reports, setReports] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const dispatch = useDispatch<any>();

    const fetchReports = async () => {
        setLoading(true);
        try {
            const data = {
                notifyType: 4,
                pageIndex,
                pageSize
            };
            const action = await dispatch(notification(data));
            const response = action.payload || action;

            console.log('API Response:', response);

            const reportsData = Array.isArray(response.data?.data) ? response.data.data : [];
            const filteredReports = reportsData.filter((report: any) => report.notifyType === 4);

            setReports(filteredReports);
            console.log('Filtered Reports:', filteredReports);

            setTotalPages(response.data?.totalPages);
            console.log(totalPages, ' total pages');

        } catch (error) {
            console.error("Error fetching reports:", error);
        } finally {
            setLoading(false);
        }
    };

useEffect(() => {
        fetchReports(); // Initial fetch
        const interval = setInterval(fetchReports, 180000); // Fetch every 3 minutes (180000 ms)

        return () => clearInterval(interval); // Cleanup on unmount
    }, [pageIndex, pageSize]);

    const updateSchemeStatus = useCallback(async (id: number, status: number) => {
        try {
            const data = { requestId: id, status };
            const response = await dispatch(changeStatus(data));
            if (response.payload.success) {
                console.log('Status updated successfully');
                fetchReports();  // Fetch reports again after status update
            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
    }, [dispatch]);

    const handlePageChange = useCallback((newPageIndex: number) => {
        if (newPageIndex >= 1 && newPageIndex <= totalPages) {
            setPageIndex(newPageIndex);
        }
    }, [totalPages]);

    const handlePageSizeChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
        setPageSize(Number(event.target.value));
        setPageIndex(1);
    }, []);

    return (
        <>
            <Header />
            <div>
                <MFTable
                    schemes={reports}
                    loading={loading}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    updateSchemeStatus={updateSchemeStatus}
                    refreshReports={fetchReports}  // Pass fetchReports to MFTable
                />
            </div>
        </>
    );
};

export default MFReports;
