import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  InputAdornment,
  IconButton,
  TableFooter,
  Typography,
  Box,
} from "@mui/material";
import { Search} from "@mui/icons-material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

interface MF {
  id: number;
  folioNo: string;
  schemeName: string;
  investmentDate: string;
  amount: number;
  purchaseNav: number;
  units: number;
  currentNav: number;
  currentDate: string;
  currentVal: number;
  gainNloss: number;
  absReturn: string;
  xirr: string;
  createdAt: string;
  noOfDays:string;
}

interface Props {
  data: MF[];
  totalPages: number; // Total number of users
  pageSize: number; // Current page size
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void; // Page change handler
  onPageSizeChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void; // Page size change handler
  currentPage: number; // Current page number
  fetchData: () => void;
}

const FDHoldingsTable: React.FC<Props> = ({
  data,
  totalPages,
  pageSize,
  currentPage,
  onPageChange,
  onPageSizeChange,
  fetchData,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState<MF[]>([]);
  const [sortColumn, setSortColumn] = useState<keyof MF | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  // const location = useLocation();
  // let selectedData = location?.state?.selectedData||{};

  // console.log("this is current page", selectedData);

  console.log("this is current page", data);
  console.log("this is page size", pageSize);

  const isEmptyObject = (obj: object): boolean => {
    return Object.keys(obj).length === 0;
  };

  useEffect(() => {
    // Filter and sort data whenever the data or search term changes
    let updatedData = data;

    // Search functionality
    if (searchTerm) {
      updatedData = updatedData?.filter((row) =>
        Object.values(row).some(
          (value) =>
            value != null &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    // Sorting functionality
    if (sortColumn) {
      updatedData = [...updatedData].sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) return sortOrder === "asc" ? -1 : 1;
        if (a[sortColumn] > b[sortColumn]) return sortOrder === "asc" ? 1 : -1;
        return 0;
      });
    }

    setFilteredData(updatedData);
  }, [data, searchTerm, sortColumn, sortOrder]);

  const handleSort = (column: keyof MF) => {
    setSortColumn(column);
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };



  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginBottom={2}
    >
      <Box width="90%" mb={2} display="flex" justifyContent="space-between">
        <Typography variant="h5">Mutual Fund</Typography>
        <Box display="flex" justifyContent="space-between" width="30%">
          <TextField
            size="small"
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Box width="90%">
        <TableContainer
          component={Paper}
          sx={{
            borderTop: "1.5px solid rgba(224, 224, 224, 1)",
            padding: "0 20px",
            maxWidth: "100%", // Ensure the table doesn't exceed the container's width
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
              <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("folioNo")}
                  >
                    Folio Number
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("schemeName")}
                  >
                    Scheme Name
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell>
                {/* <TableCell sx={{whiteSpace:"nowrap"}}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("transactionDate")}
                  >
                    Purchase Date
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell> */}
                <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("investmentDate")}
                  >
                    Date Of Investment
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell>
                <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("amount")}
                  >
                    Invested Amount
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell>
                <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("purchaseNav")}
                  >
                    Purchase NAV
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell>
                <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("units")}
                  >
                    Units
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell>
                <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("currentNav")}
                  >
                    Current NAV
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell>
                <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
                  <Typography variant="subtitle1" fontWeight="bold" onClick={() => handleSort("currentDate")}>
                    Current Date
                  </Typography>
                </TableCell>
                <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("currentVal")}
                  >
                    Current Value
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell>
                <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("gainNloss")}
                  >
                    Gain/Loss
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell>
                <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("noOfDays")}
                  >
                    No. Of Days
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell>
                <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("absReturn")}
                  >
                    Absolute Return (%)
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell>

                <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("xirr")}
                  >
                    XIRR (%)
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell>
                {/* <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Transactions
                  </Typography>
                </TableCell> */}
                {/* <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Actions
                  </Typography>
                </TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredData?.length>0 && (filteredData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row?.folioNo}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row?.schemeName}
                    </TableCell>
                    {/* <TableCell sx={{ textAlign: "center" }}>{row?.transactionDate}</TableCell> */}
                    <TableCell sx={{ textAlign: "center" }}>
                      {row?.investmentDate}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row?.amount?"Rs."+Number(row?.amount).toFixed(2):"-"}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                    {row?.purchaseNav}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                    {row?.units}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                    {row?.currentNav}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                    {row?.currentDate}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                    {row?.currentVal?"Rs."+Number(row?.currentVal).toFixed(2):"-"}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                    {row?.gainNloss?"Rs."+Number(row?.gainNloss).toFixed(2):"-"}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                    {row?.noOfDays}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                    {row?.absReturn}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                    {row?.xirr}
                    </TableCell>
                    {/* <TableCell sx={{ textAlign: "center" }}>
                      {row?.transactions?.length > 0 ? (
                        <GrTransaction
                          onClick={() => handleOpenModal(row?.transactions)}
                          className="text-gray-600 text-2xl font-bold hover:text-blue-600 mx-auto"
                        />
                      ) : (
                        "-"
                      )}
                    </TableCell> */}
                    {/* <TableCell>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ height: "100%" }}
                      >
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleEdit(row)}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          aria-label="add"
                          onClick={() => handlePlus(row)}
                        >
                          <Add />
                        </IconButton>
                      </Box>
                    </TableCell> */}
                  </TableRow>
                )))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={filteredData?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onPageSizeChange}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default FDHoldingsTable;
