import React, { useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Box, Typography } from '@mui/material';
import CommonInput from '../../components/Input/CommonInput';
import { useDispatch } from 'react-redux';
import { registerUser } from '../../redux/user/userSlice'; // Adjust the import path as necessary
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import toast from 'react-hot-toast';

const validationSchema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, 'Mobile number must be 10 digits')
    .required('Mobile number is required'),
  pancard: Yup.string()
    .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, 'Invalid PAN card number')
    .required('PAN card number is required'),
  password: Yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$.!%*#?&]{8,}$/,
      'Password must be at least 8 characters long, include at least one letter and one number'
    )
    .required('Password is required'),
});

const RegisterForm = () => {
  const dispatch = useDispatch<any>();
  const formikRef = useRef(null);
  const navigate = useNavigate(); // Initialize useNavigate

  const initialValues = {
    firstName: '',
    email: '',
    mobile: '',
    pancard: '',
    password: 'password#123', // Default password
  };

  const addUser = async (values: any) => {
    try {
      await dispatch(registerUser(values)).unwrap();
      toast.success('User registered successfully');
      navigate('/investor');
    } catch (error: any) {
      const errorMessage = error.message || 'An unknown error occurred';
      toast.error('Error: ' + errorMessage);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        addUser(values);
        setSubmitting(false);
      }}
    >
      {({ values, handleChange, handleSubmit, handleBlur, errors, touched, setFieldValue }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            console.log(values, 'values');
            console.log(errors, 'errors');
            handleSubmit();
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 5 }}>
            <Typography variant="h4" gutterBottom>
              Register User
            </Typography>
            <Box sx={{ display: 'flex', gap: 5 }}>
              <CommonInput
                label="First Name"
                type="text"
                variant="outlined" // Pass variant prop
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                placeholder="Enter your first name"
                required
                id="firstName"
                error={errors.firstName}
                touched={touched.firstName}
              />
              <CommonInput
                label="Email"
                type="email"
                variant="outlined" // Pass variant prop
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
                id="email"
                error={errors.email}
                touched={touched.email}
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 5}}>
              <CommonInput
                label="Mobile Number"
                type="text"
                variant="outlined" // Pass variant prop
                name="mobile"
                value={values.mobile}
                onChange={handleChange}
                placeholder="Enter your mobile number"
                required
                id="mobile"
                error={errors.mobile}
                touched={touched.mobile}
              />
              <CommonInput
                label="PAN Card"
                type="text"
                variant="outlined" // Pass variant prop
                name="pancard"
                value={values.pancard}
                onChange={(e) => {
                  setFieldValue('pancard', e.target.value.toUpperCase());
                }}
                placeholder="Enter your PAN card number"
                required
                id="pancard"
                error={errors.pancard}
                touched={touched.pancard}
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ width: '33%', alignSelf: 'flex-end' }}
            >
              Register
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default RegisterForm;
