import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  Backdrop,
  Box,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { getRMUser } from "../../redux/user/userSlice";
import SearchIcon from "@mui/icons-material/Search";
interface User {
  id: number;
  firstName: string;
  email: string;
  mobile: string;
  createdAt: string;
}

interface AssignRMTableProps {
  requests: any;
  totalPages: number;
  rowsPerPage: number;
  page: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  error: string | null;
  onChangeStatus: (requestId: number, status: number) => void;
  onOpenDetails: (id: number) => void;
}

const AssignRMTable: React.FC<AssignRMTableProps> = ({
  requests,
  totalPages,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  loading, // Pass loading prop here
  error,
  onChangeStatus,
  onOpenDetails,
}) => {
  const dispatch = useDispatch<any>();
  const [users, setUsers] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState(1); // Initial page
  const [pageSize, setPageSize] = useState(100);
  const [isAssigning, setIsAssigning] = useState(false); // Track global assignment loading
  const [searchQuery, setSearchQuery] = useState("");

  // Sort requests by createdAt in descending order
  const sortedRequests = Array.isArray(requests)
    ? [...requests].sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    ).filter(
      (request) =>
        request.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        request.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        request.email.toLowerCase().includes(searchQuery.toLowerCase())
    )
    : [];

  const handleChangeStatus = async (requestId: number, status: number) => {
    setIsAssigning(true); // Show loader over the entire page
    setTimeout(async () => {
      await onChangeStatus(requestId, status);
      setIsAssigning(false); // Hide loader after the delay
    }, 4000);
  };

  const fetchUsers = async (pageIndex: number, pageSize: number) => {
    try {
      const response = await dispatch(
        getRMUser({
          pageIndex,
          pageSize,
        })
      );
      const data = response.payload.data;
      setUsers(data);
      setCurrentPage(response.payload.pageIndex); // Set current page from response if needed
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUsers(currentPage, pageSize); // Fetch users with the current page
  }, [currentPage, pageSize, dispatch]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage); // Update current page and trigger a fetch
    onPageChange(event, newPage);
  };

  return (
    <div style={{
      position: "relative", display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "20px",
    }}> {/* Wrapping container */}
      {/* Full-page loading spinner */}
      {(isAssigning || loading) && (
        <Backdrop
          open={isAssigning || loading} // Show when loading is true
          style={{
            position: "fixed", // Changed to fixed for full page overlay
            zIndex: 10,
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            left: 0,
            top: 0,
            width: "100vw",
            height: "100vh",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <CircularProgress color="inherit" />
            <Typography variant="h6" style={{ marginTop: "16px" }}>
              Assigning manager...
            </Typography>
          </div>
        </Backdrop>
      )}
      <Box
        width="90%"
        mb={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" style={{ textAlign: "left" }}>
          Assign RM
        </Typography>
      </Box>
      <Box
        width="90%"
        mb={2}
        sx={{ display: "flex", justifyContent: "flex-start" }}
      >
          <div style={{ position: "relative", width: "800px" }}>
            {" "}
            {/* Set width of the input */}
            <input
              type="text"
              placeholder="Search users" // Add placeholder
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{
                width: "100%",
                padding: "10px 20px 10px 45px", // Increase left padding for the icon
                borderRadius: "5px",
                border: "1px solid #ccc",
                fontSize: "16px",
              }}
            />
            {/* Add search icon */}
            <SearchIcon
              style={{
                position: "absolute",
                top: "50%",
                left: "15px", // Position the icon closer to the left side
                transform: "translateY(-50%)",
                color: "#888",
              }}
            />
          </div>
      </Box>
      <TableContainer
        component={Paper}
        style={{ width: "90%", margin: "0 auto" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold", minWidth: 120 }}>
                User Name
              </TableCell>
              <TableCell style={{ fontWeight: "bold", minWidth: 120 }}>
                User Email
              </TableCell>
              <TableCell style={{ fontWeight: "bold", minWidth: 120 }}>
                Relation Manager
              </TableCell>
              <TableCell style={{ fontWeight: "bold", minWidth: 120 }}>
                Select Manager
              </TableCell>
              <TableCell style={{ fontWeight: "bold", minWidth: 120 }}>
                createdAt
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {error ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  {error}
                </TableCell>
              </TableRow>
            ) : sortedRequests?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No data available
                </TableCell>
              </TableRow>
            ) : (
              sortedRequests?.map((request) => (
                <TableRow key={request?.id}>
                  <TableCell>
                    {request?.firstName + " " + request?.lastName}
                  </TableCell>
                  <TableCell>{request?.email}</TableCell>
                  <TableCell>
                    {request?.relationManager?.rmName
                      ? request?.relationManager?.rmName
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    <Select
                      value={request?.requestStatus || ""}
                      onChange={(e) =>
                        handleChangeStatus(
                          request?.id,
                          e.target.value as number
                        )
                      }
                      displayEmpty
                      disabled={isAssigning} // Disable interaction when loading
                    >
                      <MenuItem value="" disabled>
                        Select Manager
                      </MenuItem>
                      {Array.isArray(users) &&
                        users.map((user) => (
                          <MenuItem key={user.id} value={user.id}>
                            {user.firstName}
                          </MenuItem>
                        ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    {new Date(request?.createdAt).toLocaleDateString()}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={totalPages * rowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page - 1} // Use 0-based index for Material-UI pagination
          onPageChange={handlePageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          labelDisplayedRows={({ from, to, count }) => `${page} – ${totalPages}`}
        />
      </TableContainer>
    </div>
  );
};

export default AssignRMTable;
