// src/App.tsx
import React from 'react';
import { Container } from '@mui/material';
import AgentManagement from '../../components/AgentMasterTable/AgentMasterTable';
import Header from '../../components/Header/Header';

const App: React.FC = () => {
    return (
      <>
            <Header/>
            <Container>
                <AgentManagement />
            </Container>
      </>
    );
};

export default App;
