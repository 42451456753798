// FDReporting.tsx
import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { useDispatch } from "react-redux";
import { FDModel } from "../../models/fd.model";
import { getFD } from "../../redux/fd/fdSlice";
import AifHoldingsTable from "../../components/HoldingsTable/AifHoldingsTable";
import { getUserHolding } from "../../redux/holdings/holdingSlice";
import { useParams } from "react-router-dom";

interface AIF {
  requestId:number;
  id: number;
  noteficationId:number;
  holdingName: string;
  category: string;
  investedAmount: number;
  currentAmount: number;
  transactionDate: string;
  currentNavDate: string;
  currentNAV:number,
  realisedPnL: number;
  transactions: [];
  xirr: string;
  days: number;
  unrealisedPnL: number;
  absolutePnL: number;
  type:string;
}

const AifHoldings: React.FC = () => {
  // Different dummy data
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const [AIF, setAIF] = useState<AIF[]>([]);
  const [totalFd, setTotalFd] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const { id } = useParams();

  const fetchAIF = async () => {
    setLoader(true);
    try {
      const response = await dispatch(
        getUserHolding({
          userId: Number(id),
          holdingType: "AIF",
        })
      );
      const data = response.payload;
      setAIF(data.holdings);
      setTotalPages(data.totalPages);
      setCurrentPage(data.pageIndex);
      console.log(totalPages, currentPage);
      console.log("reports page not the table", data);
    } catch (error) {
      console.error("Error fetching FD data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchAIF();
  }, [dispatch, currentPage, pageSize]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage);
    fetchAIF(); // Fetch data for the new page
  };

  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reset to first page whenever page size changes
  };

  return (
    <div>
      <Header />
      <div>
        <AifHoldingsTable
          data={AIF}
          totalPages={totalPages}
          currentPage={currentPage}
          pageSize={pageSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          fetchData={fetchAIF}
        />
      </div>
    </div>
  );
};

export default AifHoldings;
