import api from "../../config/apiConfig";

const updateNominee = async (data: FormData) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/nominee/update", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data;
}

const nomineeService = { updateNominee };
export default nomineeService;