import api from "../../../config/apiConfig";


const getAIF = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/aif/getAll", data, {
        headers: {
            Authorization: accessToken, // Ensure the token is formatted correctly
        },
    });
    console.log('aif data',response.data)
    return response.data;
   
};



const aifservice = { getAIF };
export default aifservice;