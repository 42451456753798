import api from "../../config/apiConfig";
import { MFReports } from "../../models/mf.model";

const getMF = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/funds/getUnrealisedReport", data, {
        headers: {
            Authorization: accessToken, // Ensure the token is formatted correctly
        },
    });
    console.log('mf data',response.data)
    return response.data;
   
};

const getMFHoldings = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("funds/getHoldings", data, {
        headers: {
            Authorization: accessToken, // Ensure the token is formatted correctly
        },
    });
    console.log('mf data',response.data)
    return response.data;
   
};

const mfService = { getMF,getMFHoldings };
export default mfService;