// FDReporting.tsx
import React, { useEffect, useState } from 'react';
import FDReportsTable from '../../components/ReportsTable/FD/FDReportsTable';
import Header from '../../components/Header/Header';
import { useDispatch } from 'react-redux';
import { colors } from "../../constants/colors";
import { notification } from '../../redux/notification/notificationSlice';


interface FD {
    fdId:number;
    id: number;
    noteficationId:number;
    schemeName: string;
    providerName:string;
    category: string;
    amount: number;
    fdStatus: number;
    transactionDate: string;
    investmentFrequency: string;
    interesetRate:number,
    grossInterest: number;
    transactions: [];
    endDate: number;
    investmentTenure: number;
    returnAmount: number;
    createdAt:string;
    type:string;
    daysLeft:number;
    userId: number;
    startDate: number;
    name: string;
    firstName: string;
  }


const FDReportingSell: React.FC = () => {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [notifyType, setNotifyType] = useState<any>(0);

    const dispatch = useDispatch<any>();

    const fetchReports = async () => {
        setLoading(true);
        try {
            const data = {
                notifyType: 10,
                pageIndex,
                pageSize,
            };
            const response = await dispatch(notification(data));
            console.log('Full response:', response); // Log the full response
            
            // Access the response properly
            if (response?.payload?.data?.data) {
                const filteredReports = response.payload.data.data.filter((report: any) => report.notifyType === 10);
                const uniqueNotifyTypes = Array.from(new Set(filteredReports.map((report: any) => report.notifyType)));
                
                console.log('Unique notifyTypes:', uniqueNotifyTypes);
                console.log('Filtered reports:', filteredReports);
                
                setReports(filteredReports);
                setNotifyType(uniqueNotifyTypes);
                setTotalPages(response.payload.data.totalPages); // Access totalPages from the correct path
            } else {
                console.error("Response data is undefined:", response);
            }
        } catch (error) {
            console.error("Error fetching reports", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchReports(); // Initial fetch
        const interval = setInterval(fetchReports, 180000); // Fetch every 3 minutes (180000 ms)

        return () => clearInterval(interval); // Cleanup on unmount
    }, [pageIndex, pageSize]);

    const handlePageChange = (newPageIndex: any) => {
        if (newPageIndex >= 1 && newPageIndex <= totalPages) {
            setPageIndex(newPageIndex);
        }
    };

    const handlePageSizeChange = (event: any) => {
        setPageSize(Number(event.target.value));
        setPageIndex(1); 
    };

    return (
        <>
            <Header />
            <div>
            <div className="flex justify-start items-center mb-4 mx-[5rem]">
          <h1 className="text-2xl font-bold" style={{ color: colors.primary }}>
            Fixed Deposits Sell Requests
          </h1>
        </div>
                <FDReportsTable 
                    schemes={reports}
                    notifyType={notifyType} 
                    loading={loading}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    fetchData={fetchReports}/>
            </div>
        </>
    );
};

export default FDReportingSell;
