import React, { useState, useEffect } from "react";
import notificationService from "../../redux/notification/notificationService";
import Header from "../../components/Header/Header";
import RequestsTable from "../../components/AllRequestsTable/RequestsTable";
import api from "../../config/apiConfig";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";
import { colors } from "../../constants/colors";
import toast, { Toaster } from "react-hot-toast";

const Requests: React.FC = () => {
  const [requests, setRequests] = useState<Notification[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [values, setValues] = useState<any[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        setLoading(true);
        const response = await notificationService.notification({
          notifyType: 3,
          pageIndex: page + 1,
          pageSize: rowsPerPage,
        });

        // Filter the requests to only include those with notifyType 3
        const filteredRequests = response.data.data.filter(
          (request: any) => request.notifyType === 3
        );

        setRequests(filteredRequests);
        setTotalPages(response.data.totalPages);
        setError(null);
      } catch (error) {
        setError("Failed to fetch notifications");
        setRequests([]);
        console.error("Failed to fetch notifications", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, [page, rowsPerPage]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChangeStatus = async (requestId: number, status: number) => {
    try {
      setLoading(true);
      await api.post(
        "admin/updateRequest/changeStatus",
        {
          requestId: requestId,
          status: status,
        },
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      // Optionally, you can fetch the updated data here, but the page will reload
      // so it's not necessary unless you want to show the update before reload

      window.location.reload(); // Reload the page after the status is changed
    } catch (error) {
      console.error("Failed to change status", error);
      toast.error("Failed to change status. Reloading the page.");
      window.location.reload(); // Reload the page if there's an error as well
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDetails = async (id: number) => {
    try {
      console.log("Fetching details for ID:", id);
      const response = await notificationService.getNotificationById({
        id: id,
      });
      setSelectedData(response.data);
      setValues(response?.data?.updateFor);
      setDialogOpen(true);
    } catch (error) {
      console.error("Failed to fetch notification by ID:", error);
    }
  };
  console.log(selectedData, "notification for single id");

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedData(null);
  };

  return (
    <>
      <Header />
      <div className="requests">
        <RequestsTable
          requests={requests}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          loading={loading}
          error={error}
          onChangeStatus={handleChangeStatus}
          onOpenDetails={handleOpenDetails}
        />
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle
            style={{ backgroundColor: colors.primary, color: colors.white }}
          >
            Notification Details
          </DialogTitle>
          <DialogContent
            style={{
              backgroundColor: colors.lightBg,
              paddingTop: 16,
              paddingBottom: 16,
            }}
          >
            {selectedData ? (
              <>
                <Typography variant="body1" style={{ marginBottom: 16 }}>
                  <strong style={{ color: colors.primary }}>User Name:</strong>{" "}
                  {selectedData?.userName}
                </Typography>
                <Typography variant="body1" style={{ marginBottom: 16 }}>
                  <strong style={{ color: colors.primary }}>Message:</strong>{" "}
                  {selectedData?.notifyMessage}
                </Typography>


                <Typography variant="body1" style={{ marginBottom: 16 }}>
  <strong style={{ color: colors.primary }}>Old Values:</strong>{" "}
  {selectedData?.updateFor && selectedData.updateFor.length > 0 ? (
    // Check for any changes in old values
    selectedData.updateFor.some(
      (value: any) => value.oldValue !== value.updateValue
    ) ? (
      <>
        {/* Collect removed binary values */}
        {selectedData.updateFor
          .filter(
            (value: any) =>
              value.oldValue === 1 && value.updateValue === 0
          )
          .map((value: any, index: number) => (
            <span key={index}>
              {value.updateColumn}
              {index < selectedData.updateFor.length - 1 ? ", " : ""}
            </span>
          ))}

        {/* Collect non-binary values that have changed */}
        {selectedData.updateFor
          .filter(
            (value: any) =>
              value.oldValue !== value.updateValue &&
              value.oldValue !== 1 &&
              value.oldValue !== 0
          )
          .map((value: any, index: number) => (
            <span key={index}>
              {value.oldValue}
              {index < selectedData.updateFor.length - 1 ? ", " : ""}
            </span>
          ))}

        {/* Indicate if any binary values were removed */}
        {selectedData.updateFor.some(
          (value: any) => value.oldValue === 1 && value.updateValue === 0
        ) && (
          <span> removed</span>
        )}

        {/* Check if no values were displayed */}
        {selectedData.updateFor.filter(
          (value: any) =>
            (value.oldValue === 1 && value.updateValue === 0) || 
            (value.oldValue !== value.updateValue &&
             value.oldValue !== 1 &&
             value.oldValue !== 0)
        ).length === 0 && (
          <span>No changes made</span>
        )}
      </>
    ) : (
      <span>No changes made</span>
    )
  ) : (
    <span>No changes made</span>
  )}
</Typography>

<Typography variant="body1" style={{ marginBottom: 16 }}>
  <strong style={{ color: colors.primary }}>New Values:</strong>{" "}
  {selectedData?.updateFor && selectedData.updateFor.length > 0 ? (
    // Check for any changes in new values
    selectedData.updateFor.some(
      (value: any) => value.oldValue !== value.updateValue
    ) ? (
      <>
        {/* Collect added binary values */}
        {selectedData.updateFor
          .filter(
            (value: any) =>
              value.oldValue === 0 && value.updateValue === 1
          )
          .map((value: any, index: number) => (
            <span key={index}>
              {value.updateColumn}
              {index < selectedData.updateFor.length - 1 ? ", " : ""}
            </span>
          ))}

        {/* Collect non-binary values that have changed */}
        {selectedData.updateFor
          .filter(
            (value: any) =>
              value.oldValue !== value.updateValue &&
              value.updateValue !== 1 &&
              value.oldValue !== 1
          )
          .map((value: any, index: number) => (
            <span key={index}>
              {value.updateValue}
              {index < selectedData.updateFor.length - 1 ? ", " : ""}
            </span>
          ))}

        {/* Indicate if any binary values were added */}
        {selectedData.updateFor.some(
          (value: any) => value.oldValue === 0 && value.updateValue === 1
        ) && (
          <span> added</span>
        )}

        {/* Check if no values were displayed */}
        {selectedData.updateFor.filter(
          (value: any) =>
            (value.oldValue === 0 && value.updateValue === 1) || 
            (value.oldValue !== value.updateValue &&
             value.updateValue !== 1 &&
             value.oldValue !== 1)
        ).length === 0 && (
          <span>No changes made</span>
        )}
      </>
    ) : (
      <span>No changes made</span>
    )
  ) : (
    <span>No changes made</span>
  )}
</Typography>








                <Typography variant="body1">
                  <strong style={{ color: colors.primary }}>Created At:</strong>{" "}
                  {new Date(selectedData.createdAt || "").toLocaleDateString()}
                </Typography>
              </>
            ) : (
              <Typography variant="body1">No details available</Typography>
            )}
          </DialogContent>

          <DialogActions>
            <Button
              onClick={handleCloseDialog}
              style={{ color: colors.primary }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Toaster />
      </div>
    </>
  );
};

export default Requests;
