import React, { useState } from "react";
import { Container } from "@mui/material";
import toast from 'react-hot-toast';
import Header from "../../components/Header/Header";
import api from "../../config/apiConfig";

const Bonds: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [dragActive, setDragActive] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileSelect(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      handleFileSelect(e.target.files[0]);
    }
  };

  const handleFileSelect = (selectedFile: File) => {
    setFile(selectedFile);
    setFileName(selectedFile.name);
  };

  const handleSubmit = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("listingType", "bond");

      try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await api.post(
          "admin/uploadListingData",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `${accessToken}`,
            },
          }
        );
        toast.success("File uploaded successfully.");
        console.log(response.data);
        setFile(null);
        setFileName(null);
        
        // Trigger re-render by updating refreshKey
        setRefreshKey(prevKey => prevKey + 1);
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error("Invalid file type.");
      }
    }
  };

  const handleChangeFile = () => {
    setFile(null);
    setFileName(null);
  };

  return (
    <>
      <Header />
      <Container
        key={refreshKey} // Key attribute to force re-render
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          textAlign: "center",
        }}
      >
        <div
          className="flex flex-col items-center justify-center w-[60%] h-auto"
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div
            className={`w-full h-full flex flex-col items-center justify-center cursor-pointer ${
              dragActive ? "bg-gray-100" : "bg-gray-50"
            }`}
          >
            <div className="flex items-center justify-center w-full">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-5 hover:bg-gray-10"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to box</span> or drag and drop
                  </p>
                </div>
                <input 
                  id="dropzone-file" 
                  type="file" 
                  className="hidden" 
                  accept=".xls,.xlsx" // Only accept Excel files
                  onChange={handleChange} 
                />
              </label>
            </div>
          </div>
          {fileName && (
            <div className="mt-4 text-gray-700">
              <strong>Selected File:</strong> {fileName}
            </div>
          )}
          <div className="mt-4 flex space-x-4">
            {/* Show "Change File" button only after a file is selected */}
            {file && (
              <button
                onClick={handleChangeFile}
                className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400"
              >
                Cancel
              </button>
            )}
            {/* Show "Browse File" button only before a file is selected */}
            {!file && (
              <label
                htmlFor="file-input"
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 cursor-pointer"
              >
                Browse File
              </label>
            )}
            <input
              id="file-input"
              type="file"
              className="hidden"
              accept=".xls,.xlsx" // Only accept Excel files
              onChange={handleChange}
            />
            {/* Show "Upload" button only after a file is selected */}
            {file && (
              <button
                onClick={handleSubmit}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Upload
              </button>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Bonds;
