import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "./userService";
import { UserDetails,RMUserDetails} from "../../models/user.model";
import { UserId } from "../../models/userId.model";


export const getUser = createAsyncThunk(
  "users/getUser",
  async (data: any, thunkAPI) => {
    try {
       const response = await userService.getUser(data);
       return response.data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getUserById = createAsyncThunk(
  "users/getUserById",
  async (data: UserId, thunkAPI) => {
    try {
      const response = await userService.getUserById(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getRMUser = createAsyncThunk(
  "admin/getAllRm",
  async (data: RMUserDetails, thunkAPI) => {
    try {
       const response = await userService.getRMUser(data);
       return response.data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAllUserForRM = createAsyncThunk(
  "admin/rm/getAllUsers",
  async (data: RMUserDetails, thunkAPI) => {
    try {
       const response = await userService.getAllUserForRM(data);
       return response.data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


export const downloadUserData = createAsyncThunk(
  "users/downloadUserData",
  async (data: any, thunkAPI) => {
    try {
       const response = await userService.downloadUserData(data);
       return response
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (data: FormData, thunkAPI) => {
    try {
      const response = await userService.updateUser(data);
      console.log('update user api called', response)
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const registerUser = createAsyncThunk(
  "users/registerUser",
  async (data: any, thunkAPI) => {
    try {
      const response = await userService.registerUser(data);
      console.log('register user api called', response)
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const registerRMUser = createAsyncThunk(
  "admin/registerRM",
  async (data: any, thunkAPI) => {
    try {
      const response = await userService.registerRMUser(data);
      console.log('register user api called', response)
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const updatePassword = createAsyncThunk(
  "/user/changePassword",
  async (data: any, thunkAPI: any) => {
    try {
      return await userService.updatePassword(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "user/update",
  async (data: any, thunkAPI: any) => {
    try {
      return await userService.updateProfile(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);



const initialState = {
  users: [],
  data: {},
  details:{},
  totalPages: 0,
  pageIndex: 1,
  isError: false,
  isSuccess: false,
  isLoading: false,
  isDownloading: false, // New state to manage download operation
  message: "",
};


export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.users = action.payload.fdList; // Extract users from fdList
        state.totalPages = action.payload.totalPages;
        state.pageIndex = action.payload.pageIndex;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(getRMUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.users = action.payload.data; // Extract data
        state.totalPages = action.payload.totalPages;
        state.pageIndex = action.payload.pageIndex;
      })
      .addCase(getRMUser.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(getAllUserForRM.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.users = action.payload.data; // Extract data
        state.totalPages = action.payload.totalPages;
        state.pageIndex = action.payload.pageIndex;
      })
      .addCase(getAllUserForRM.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(downloadUserData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(downloadUserData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        // Handle success for downloadUserData if needed
      })
      .addCase(downloadUserData.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.users = { ...state.users, ...action.payload.data };
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(getUserById.pending, (state) => {
        state.isLoading = true;
    })
    .addCase(getUserById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.details = action.payload; // Update to hold a single user object
        console.log('getbyid userslice', state.details)
    })
    .addCase(getUserById.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
    }) 
    .addCase(registerUser.pending, (state) => {
      state.isLoading = true;
  })
  .addCase(registerUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.details = action.payload; // Update to hold a single user object
      console.log('getbyid userslice', state.details)
  })
  .addCase(registerUser.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = action.error.message || "";
  })

  .addCase(registerRMUser.pending, (state) => {
    state.isLoading = true;
})
.addCase(registerRMUser.fulfilled, (state, action) => {
    state.isLoading = false;
    state.isError = false;
    state.isSuccess = true;
    state.details = action.payload; // Update to hold a single user object
    console.log('getbyid userslice', state.details)
})
.addCase(registerRMUser.rejected, (state, action) => {
    state.isError = true;
    state.isLoading = false;
    state.isSuccess = false;
    state.message = action.error.message || "";
})
.addCase(updateProfile.pending, (state) => {
  state.isLoading = true;
})
.addCase(updateProfile.fulfilled, (state, action) => {
  state.isLoading = false;
  state.isError = false;
  state.isSuccess = true;
  state.data = action.payload.data;
})
.addCase(updateProfile.rejected, (state, action) => {
  state.isError = true;
  state.isLoading = false;
  state.isSuccess = false;
  state.message = action.error.message || "";
})
.addCase(updatePassword.pending, (state) => {
  state.isLoading = true;
})
.addCase(updatePassword.fulfilled, (state, action) => {
  state.isLoading = false;
  state.isError = false;
  state.isSuccess = true;
  state.data = action.payload.data;
})
.addCase(updatePassword.rejected, (state, action) => {
  state.isError = true;
  state.isLoading = false;
  state.isSuccess = false;
  state.message = action.error.message || "";
})
    
  }
  

});

export const { resetState } = userSlice.actions;
export default userSlice.reducer;
