import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { colors } from "../../constants/colors";
import Typography from "@mui/material/Typography";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import Header from "../../components/Header/Header";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { useEffect, useState } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { requestCount } from "../../redux/notification/notificationSlice";
import { getUser } from "../../redux/user/userSlice";
import { dashboard } from "../../redux/dashboard/dashboardSlice";
import BasicBars from "../../components/BarChart/barChart";

// Define the type for chart data
type ChartDataItem = {
  label: string;
  value: number;
};

// Define the colors for each chart segment
const segmentColors: { [key: string]: string } = {
  MF: colors.primary,
  AIF: colors.secondary,
  PMS: colors.cyan,
  BOND: colors.orange,
  FD: colors.lightGrey,
};

interface RmSales {
  fd: number;
  mutualFunds: number;
  BOND: number;
  PMS: number;
  AIF: number;
}

interface BarChartProps {
  data: Array<{
    name: string; // Adjust this type if you expect something different
    value: number; // Adjust this type if necessary
  }>;
  xField: string;
  yField: string;
  width: number;
  height: number;
}

interface RmSales {
  fd: number;
  mutualFunds: number;
  BOND: number;
  PMS: number;
  AIF: number;
}

interface PendingRequests {
  fd: number;
  mutualFunds: number;
  BOND: number;
  PMS: number;
  AIF: number;
}

interface UserInvestment {
  fd: number;
  mutualFunds: number;
  AIF: number;
  PMS: number;
  BOND: number;
}

const AnalyticsDash = () => {
  const dispatch = useDispatch<any>();
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [userCount, setUserCount] = useState<number>(0);
  const [sales, setSales] = useState<number>(0);
  const [investmentData, setInvestmentData] = useState<{
    [key: string]: number;
  }>({});
  const [userInvestments, setUserInvestments] = useState<any[]>([]); // New state for user investments
  const [selectedInvestmentType, setSelectedInvestmentType] = useState<
    string | null
  >(null);
  const [rmSalesData, setRmSalesData] = useState<any>();
  const [assignedUsers, setAssignedUsers] = useState<any>(0);
  const [pendingRequestsData, setPendingRequestsData] = useState<any>();
  const [totalRms, setTotalRms] = useState<any>();
  const [userInvestmentData, setUserInvestmentData] = useState<{
    [key: string]: number;
  }>({});

  // Accessing the dashboard data from Redux state
  const dashboardData = useSelector((state: any) => state.dashboard.data);

  // Fetch userType from localStorage
  const [userType, setUserType] = useState<number | null>(null);
  useEffect(() => {
    const userTypeString = localStorage.getItem("userType");
    const type = userTypeString ? parseInt(userTypeString, 10) : null;
    setUserType(type);
  }, []);

  // Fetch unread count
  useEffect(() => {
    const fetchUnreadCount = async () => {
      try {
        const response = await dispatch(requestCount({}));
        const count = response.payload?.data || 0;
        setUnreadCount(count);
      } catch (error) {
        console.error("Failed to fetch unread notification count", error);
      }
    };

    fetchUnreadCount();
    const interval = setInterval(() => fetchUnreadCount(), 10000);
    return () => clearInterval(interval);
  }, [dispatch]);

  // Fetch user count
  useEffect(() => {
    const fetchUser = async () => {
      const payload = { pageIndex: 1, pageSize: 10 };
      try {
        const response = await dispatch(getUser(payload));
        const data = response.payload?.fdList;
        const count = data?.length || 0;
      } catch (error) {
        console.error("Failed to fetch user data", error);
      }
    };

    fetchUser();
    const interval = setInterval(() => fetchUser(), 10000);
    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      if (userType === 2 || userType === 4) {
        try {
          const payload = { userType };
          const response = await dispatch(dashboard(payload));
  
          if (userType === 4) {
            const {
              rmSales,
              totalAssingedUsers,
              pendingRequests,
              userInvestment,
              totalActiveRm,
            } = response.payload.data;
  
            // RM Sales Data (fd, mutualFunds, BOND, PMS, AIF)
            const totalRmSales = {
              fd: (rmSales?.fd || []).reduce(
                (acc: any, curr: { totalInvestment: any }) =>
                  acc + curr.totalInvestment,
                0
              ),
              mutualFunds: (rmSales?.mutualFunds || []).reduce(
                (acc: any, curr: { totalInvestment: any }) =>
                  acc + curr.totalInvestment,
                0
              ),
              BOND: (rmSales?.BOND || []).reduce(
                (acc: any, curr: { totalInvestment: any }) =>
                  acc + curr.totalInvestment,
                0
              ),
              PMS: (rmSales?.PMS || []).reduce(
                (acc: any, curr: { totalInvestment: any }) =>
                  acc + curr.totalInvestment,
                0
              ),
              AIF: (rmSales?.AIF || []).reduce(
                (acc: any, curr: { totalInvestment: any }) =>
                  acc + curr.totalInvestment,
                0
              ),
            };
  
            // Calculate total of all categories
            const grandTotal = Object.values(totalRmSales).reduce(
              (acc, curr) => acc + curr,
              0
            );
  
            const AssignedUser =
              (totalAssingedUsers || []).reduce(
                (sum: any, user: any) => sum + user.totalAssignedUsers,
                0
              ) || 0;
  
            const totalRms = totalActiveRm;
  
            // Pending Requests Data (fd, mutualFunds, BOND, PMS, AIF)
            const totalPendingRequests = {
              fd: (pendingRequests?.fd || []).reduce(
                (acc: any, curr: { pendingRequests: any }) =>
                  acc + curr.pendingRequests,
                0
              ),
              mutualFunds: (pendingRequests?.mutualFunds || []).reduce(
                (acc: any, curr: { pendingRequests: any }) =>
                  acc + curr.pendingRequests,
                0
              ),
              BOND: (pendingRequests?.BOND || []).reduce(
                (acc: any, curr: { pendingInvestments: any }) =>
                  acc + curr.pendingInvestments,
                0
              ),
              PMS: (pendingRequests?.PMS || []).reduce(
                (acc: any, curr: { pendingInvestments: any }) =>
                  acc + curr.pendingInvestments,
                0
              ),
              AIF: (pendingRequests?.AIF || []).reduce(
                (acc: any, curr: { pendingInvestments: any }) =>
                  acc + curr.pendingInvestments,
                0
              ),
            };
  
            const totalPending = Object.values(totalPendingRequests).reduce(
              (acc, curr) => acc + curr,
              0
            );
  
            // Summarize user investment data
            const totalUserInvestment = {
              fd: (userInvestment?.fd || []).reduce(
                (acc: any, curr: { totalInvestment: any }) =>
                  acc + curr.totalInvestment,
                0
              ),
              mutualFunds: (userInvestment?.mutualFunds || []).reduce(
                (acc: any, curr: { totalInvestment: any }) =>
                  acc + curr.totalInvestment,
                0
              ),
              AIF: (userInvestment?.AIF || []).reduce(
                (acc: any, curr: { totalInvestment: any }) =>
                  acc + curr.totalInvestment,
                0
              ),
              PMS: (userInvestment?.PMS || []).reduce(
                (acc: any, curr: { totalInvestment: any }) =>
                  acc + curr.totalInvestment,
                0
              ),
              BOND: (userInvestment?.BOND || []).reduce(
                (acc: any, curr: { totalInvestment: any }) =>
                  acc + curr.totalInvestment,
                0
              ),
            };
  
            const totalUserInvestments = Object.values(totalUserInvestment).reduce(
              (acc, curr) => acc + curr,
              0
            );
  
            if (response) {
              setRmSalesData(grandTotal);
              setAssignedUsers(AssignedUser);
              setTotalRms(totalRms);
              setPendingRequestsData(totalPending);
              setUserInvestmentData(totalUserInvestment);
            }
          } else if (userType === 2) {
            const { userInvestment, totalAssingedUsers } =
              response.payload.data;
  
              const { rmSales }: { rmSales: RmSales } = response.payload.data;
              console.log("RM Sales Data:", rmSales);
  
            const totalSales = Object.values(rmSales).reduce(
              (acc: number, value: number) => acc + value,
              0
            );
  
            setSales(totalSales);
            setUserCount(totalAssingedUsers);
  
            const totalInvestmentSummary = {
              fd: (userInvestment?.fd || []).reduce(
                (acc: any, curr: { totalInvestment: any }) =>
                  acc + curr.totalInvestment,
                0
              ),
              mutualFunds: (userInvestment?.mutualFunds || []).reduce(
                (acc: any, curr: { totalInvestment: any }) =>
                  acc + curr.totalInvestment,
                0
              ),
              AIF: (userInvestment?.AIF || []).reduce(
                (acc: any, curr: { totalInvestment: any }) =>
                  acc + curr.totalInvestment,
                0
              ),
              PMS: (userInvestment?.PMS || []).reduce(
                (acc: any, curr: { totalInvestment: any }) =>
                  acc + curr.totalInvestment,
                0
              ),
              BOND: (userInvestment?.BOND || []).reduce(
                (acc: any, curr: { totalInvestment: any }) =>
                  acc + curr.totalInvestment,
                0
              ),
            };
  
            setInvestmentData(totalInvestmentSummary);
          } else {
            console.error("Response does not contain valid data");
          }
        } catch (error) {
          console.error("Failed to fetch dashboard data", error);
        }
      }
    };
  
    fetchDashboardData();
  }, [dispatch, userType]);
  

  const handleItemClick = (event: any, d: any) => {
    console.log("Clicked item:", d); // Log the clicked item

    // Get the data index
    const dataIndex = d.dataIndex;

    // Retrieve the series data using the data index
    const investmentLabels = ["MF", "AIF", "PMS", "BOND", "FD"];

    const labelMap: { [key: string]: string } = {
      MF: "mutualFunds",
      AIF: "AIF",
      PMS: "PMS",
      BOND: "BOND",
      FD: "fd",
    };

    const label = investmentLabels[dataIndex];

    if (label) {
      setSelectedInvestmentType(label);
      const investments = dashboardData.userInvestment[labelMap[label]] || [];
      setUserInvestments(investments);
      console.log("User Investments:", investments);
    } else {
      console.log("Label not found for dataIndex:", dataIndex);
    }
  };

  console.log("Dashboard Data:", dashboardData);

  useEffect(() => {
    console.log("Selected Investment Type:", selectedInvestmentType);
    console.log("User Investments:", userInvestments);
  }, [selectedInvestmentType, userInvestments]);

  useEffect(() => {
    console.log("rm sales admin", rmSalesData);
  }, [rmSalesData]);

  useEffect(() => {
    console.log("assigned users admin", assignedUsers);
  }, [assignedUsers]);

  useEffect(() => {
    console.log("pending admin", pendingRequestsData);
  }, [pendingRequestsData]);

  useEffect(() => {
    console.log("user invested admin", userInvestmentData);
  }, [userInvestmentData]);

  return (
    <>
      <Header />
      <Container maxWidth={false} sx={{ width: "100%" }}>
        <Box my={4} mx={2}>
          <Typography variant="h5" gutterBottom>
            <span className="font-bold" style={{ color: colors.primary }}>
              Analytics
            </span>{" "}
            Dashboard
          </Typography>
        </Box>

        {userType === 2 && (
          <>
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              gap={4}
            >
              <Box
                flex={1}
                display="grid"
                gridTemplateColumns="repeat(2, 1fr)"
                gap={4}
              >
                <Box bgcolor="white" p={3} boxShadow={3} borderRadius={1}>
                  <div className="flex justify-between align-items-center">
                    <Typography
                      variant="subtitle1"
                      color={colors.primary}
                      fontWeight="bold"
                    >
                      Sales
                    </Typography>
                    <TrendingUpOutlinedIcon
                      style={{ fontSize: 50, color: colors.primary }}
                    />
                  </div>
                  <Box my={2} />
                  <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                    ₹ {sales || 0}
                  </Typography>
                </Box>

                <Box bgcolor="white" p={3} boxShadow={3} borderRadius={1}>
                  <div className="flex justify-between align-items-center">
                    <Typography
                      variant="subtitle1"
                      color={colors.primary}
                      fontWeight="bold"
                    >
                      Users
                    </Typography>
                    <PeopleOutlinedIcon
                      style={{ fontSize: 50, color: colors.primary }}
                    />
                  </div>
                  <Box my={2} />
                  <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                    {userCount || 0}
                  </Typography>
                </Box>

                <Box bgcolor="white" p={3} boxShadow={3} borderRadius={1}>
                  <div className="flex justify-between align-items-center">
                    <Typography
                      variant="subtitle1"
                      color={colors.primary}
                      fontWeight="bold"
                    >
                      Pending Requests
                    </Typography>
                    <InsertDriveFileOutlinedIcon
                      style={{ fontSize: 50, color: colors.primary }}
                    />
                  </div>
                  <Box my={2} />
                  <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                    {unreadCount}
                  </Typography>
                </Box>
              </Box>

              <Box flex={1}>
                <Stack direction={{ xs: "column", md: "row" }} spacing={4}>
                  {investmentData.mutualFunds ||
                  investmentData.AIF ||
                  investmentData.PMS ||
                  investmentData.BOND ||
                  investmentData.fd ? (
                    <Box
                      flex={1}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      bgcolor="white"
                      p={2}
                      boxShadow={3}
                      borderRadius={1}
                      height={350} // Set a fixed height for the outer box
                    >
                      <PieChart
                        series={[
                          {
                            data: [
                              {
                                label: "MF",
                                value: investmentData.mutualFunds || 0,
                                color: segmentColors.MF,
                              },
                              {
                                label: "AIF",
                                value: investmentData.AIF || 0,
                                color: segmentColors.AIF,
                              },
                              {
                                label: "PMS",
                                value: investmentData.PMS || 0,
                                color: segmentColors.PMS,
                              },
                              {
                                label: "BOND",
                                value: investmentData.BOND || 0,
                                color: segmentColors.BOND,
                              },
                              {
                                label: "FD",
                                value: investmentData.fd || 0,
                                color: segmentColors.FD,
                              },
                            ],
                            innerRadius: 90,
                            outerRadius: 140,
                            startAngle: 0,
                            endAngle: 360,
                            cx: 150,
                            cy: 150,
                          },
                        ]}
                        width={450}
                        height={300}
                        onItemClick={handleItemClick} // Ensure this is calling the correct function
                      />
                    </Box>
                  ) : null}
                </Stack>
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              {selectedInvestmentType && userInvestments.length > 0 && (
                <Box width="100%" display="flex" justifyContent="end">
                  <div className="flex flex-col">
                    <Typography
                      variant="h6"
                      align="left"
                      gutterBottom
                      sx={{ color: colors.primary, fontWeight: "bold" }}
                    >
                      {selectedInvestmentType} User Investments
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="end"
                      alignItems="center"
                    >
                      <BasicBars
                        data={[...userInvestments] // Create a copy of the array to avoid mutating the original
                          .sort((a, b) => b.totalInvestment - a.totalInvestment) // Sort by investment value in descending order
                          .slice(0, 20) // Take only the top 20 users
                          .map((user) => ({
                            name: user.firstName,
                            value: user.totalInvestment,
                          }))}
                      />
                    </Box>
                  </div>
                </Box>
              )}
            </Box>
          </>
        )}

        {userType === 4 && (
          <>
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              gap={4}
              mt={4}
            >
              <Box
                flex={1}
                display="grid"
                gridTemplateColumns="repeat(2, 1fr)"
                gap={4}
              >
                {/* Sales */}
                <Box bgcolor="white" p={3} boxShadow={3} borderRadius={1}>
                  <div className="flex justify-between align-items-center">
                    <Typography
                      variant="subtitle1"
                      color={colors.primary}
                      fontWeight="bold"
                    >
                      Sales
                    </Typography>
                    <TrendingUpOutlinedIcon
                      style={{ fontSize: 50, color: colors.primary }}
                    />
                  </div>
                  <Box my={2} />
                  <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                    ₹ {rmSalesData || 0} {/* Fetch sales number dynamically */}
                  </Typography>
                </Box>

                {/* Users */}
                <Box bgcolor="white" p={3} boxShadow={3} borderRadius={1}>
                  <div className="flex justify-between align-items-center">
                    <Typography
                      variant="subtitle1"
                      color={colors.primary}
                      fontWeight="bold"
                    >
                      Users
                    </Typography>
                    <PeopleOutlinedIcon
                      style={{ fontSize: 50, color: colors.primary }}
                    />
                  </div>
                  <Box my={2} />
                  <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                    {assignedUsers || 0} {/* Display total assigned users */}
                  </Typography>
                </Box>

                {/* Pending Requests */}
                <Box bgcolor="white" p={3} boxShadow={3} borderRadius={1}>
                  <div className="flex justify-between align-items-center">
                    <Typography
                      variant="subtitle1"
                      color={colors.primary}
                      fontWeight="bold"
                    >
                      Pending Requests
                    </Typography>
                    <InsertDriveFileOutlinedIcon
                      style={{ fontSize: 50, color: colors.primary }}
                    />
                  </div>
                  <Box my={2} />
                  <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                    {pendingRequestsData || 0} {/* Pending requests data */}
                  </Typography>
                </Box>

                <Box bgcolor="white" p={3} boxShadow={3} borderRadius={1}>
                  <div className="flex justify-between align-items-center">
                    <Typography
                      variant="subtitle1"
                      color={colors.primary}
                      fontWeight="bold"
                    >
                      Total Active RMs
                    </Typography>
                    <PersonOutlineIcon
                      style={{ fontSize: 50, color: colors.primary }}
                    />
                  </div>
                  <Box my={2} />
                  <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                    {totalRms || 0} {/* Pending requests data */}
                  </Typography>
                </Box>
              </Box>

              {/* Pie Chart for Investments */}
              <Box flex={1}>
                <Stack direction={{ xs: "column", md: "row" }} spacing={4}>
                  {userInvestmentData.mutualFunds ||
                  userInvestmentData.AIF ||
                  userInvestmentData.PMS ||
                  userInvestmentData.BOND ||
                  userInvestmentData.fd ? (
                    <Box
                      flex={1}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      bgcolor="white"
                      p={2}
                      boxShadow={3}
                      borderRadius={1}
                    >
                      <PieChart
                        series={[
                          {
                            data: [
                              {
                                label: "MF",
                                value: userInvestmentData.mutualFunds || 0,
                                color: segmentColors.MF,
                              },
                              {
                                label: "AIF",
                                value: userInvestmentData.AIF || 0,
                                color: segmentColors.AIF,
                              },
                              {
                                label: "PMS",
                                value: userInvestmentData.PMS || 0,
                                color: segmentColors.PMS,
                              },
                              {
                                label: "BOND",
                                value: userInvestmentData.BOND || 0,
                                color: segmentColors.BOND,
                              },
                              {
                                label: "FD",
                                value: userInvestmentData.fd || 0,
                                color: segmentColors.FD,
                              },
                            ],
                            innerRadius: 90,
                            outerRadius: 140,
                            startAngle: 0,
                            endAngle: 360,
                            cx: 150,
                            cy: 150,
                          },
                        ]}
                        width={450}
                        height={300}
                        onItemClick={handleItemClick}
                      />
                    </Box>
                  ) : null}{" "}
                  {/* If no data, do not render the Box */}
                </Stack>
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              {selectedInvestmentType && userInvestments.length > 0 && (
                <Box width="100%" display="flex" justifyContent="end">
                  <div className="flex flex-col">
                    <Typography
                      variant="h6"
                      align="left"
                      gutterBottom
                      sx={{ color: colors.primary, fontWeight: "bold" }}
                    >
                      {selectedInvestmentType} User Investments
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="end"
                      alignItems="center"
                    >
                      <BasicBars
                        data={[...userInvestments] // Create a copy of the array to avoid mutating the original
                          .sort((a, b) => b.totalInvestment - a.totalInvestment) // Sort by investment value in descending order
                          .slice(0, 20) // Take only the top 20 users
                          .map((user) => ({
                            name: user.userName,
                            value: user.totalInvestment,
                          }))}
                      />
                    </Box>
                  </div>
                </Box>
              )}
            </Box>
          </>
        )}
      </Container>
    </>
  );
};

export default AnalyticsDash;
