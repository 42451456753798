import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import fdService from "./fdService";
import { FDModel } from "../../models/fd.model";

export const getFD = createAsyncThunk(
  "fd/getFD",
  async (data: FDModel, thunkAPI) => {
    try {
       const response = await fdService.getFD(data);
       console.log('API call successful. Response:', response);
       return response;
    } catch (error: any) {
      console.error('Error in API call:', error);
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const getFDHoldings = createAsyncThunk(
  "fd/getHoldings",
  async (data: any, thunkAPI) => {
    try {
       const response = await fdService.getFDHoldings(data);
       console.log('API call successful. Response:', response);
       return response;
    } catch (error: any) {
      console.error('Error in API call:', error);
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const changeFdStatus = createAsyncThunk(
  'fd/changeStatus',
  async (data: any, thunkAPI) => {
    try {
      const response = await fdService.changeFdStatus(data);
      return response;
    } catch (error:any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const changeFdWithdrawStatus = createAsyncThunk(
  'admin/fd/withdraw',
  async (data: any, thunkAPI) => {
    try {
      const response = await fdService.changeFdWithdrawStatus(data);
      return response;
    } catch (error:any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const getFdScheme = createAsyncThunk(
  'fd/getFdNames',
  async (data: any, thunkAPI) => {
    try {
      const response = await fdService.getFDScheme(data);
      return response;
    } catch (error:any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const getFdSchemeByID = createAsyncThunk(
  'fd/getSchemeInfo',
  async (data: any, thunkAPI) => {
    try {
      const response = await fdService.getFDSchemeByID(data);
      return response;
    } catch (error:any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);


const initialState = {
    data: {},
    totalPages: 0,
    pageIndex: 1,
    isError: false,
    isSuccess: false,
    isLoading: false,
    isDownloading: false, // New state to manage download operation
    message: "",
};

export const fdSlice = createSlice({
    name: "fd",
    initialState,
    reducers: {
      resetState: (state) => initialState,
    },
    
    extraReducers: (builder) => {
      builder
        .addCase(getFD.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getFD.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.data = action.payload; 
          console.log('hey this is fd slice', state.data)
        })
        .addCase(getFD.rejected, (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = action.error.message || "";
        })
        .addCase(getFDHoldings.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getFDHoldings.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.data = action.payload; 
          console.log('hey this is fd slice', state.data)
        })
        .addCase(getFDHoldings.rejected, (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = action.error.message || "";
        })
        .addCase(changeFdStatus.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(changeFdStatus.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.data = action.payload; 
        })
        .addCase(changeFdStatus.rejected, (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = action.error.message || "";
        })
        .addCase(getFdScheme.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getFdScheme.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.data = action.payload; 
        })
        .addCase(getFdScheme.rejected, (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = action.error.message || "";
        })
        .addCase(getFdSchemeByID.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getFdSchemeByID.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.data = action.payload; 
        })
        .addCase(getFdSchemeByID.rejected, (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = action.error.message || "";
        })
        .addCase(changeFdWithdrawStatus.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(changeFdWithdrawStatus.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.data = action.payload; 
        })
        .addCase(changeFdWithdrawStatus.rejected, (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = action.error.message || "";
        });
    }
    
  
  });
  
  export const { resetState } = fdSlice.actions;
  export default fdSlice.reducer;
