import api from "../../config/apiConfig";

const updatePreferences = async (data: FormData) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/user/addPreferences", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data;
}

const preferenceService = { updatePreferences };
export default preferenceService;