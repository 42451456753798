import React, { useState, useEffect } from 'react';
import notificationService from '../../redux/notification/notificationService';
import Header from '../../components/Header/Header';
import AssignRMTable from '../../components/AssignRMTable/AssignRMTable';
import api from '../../config/apiConfig';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { getUser } from '../../redux/user/userSlice';

interface User {
    id: number;
    firstName: string;
    lastName: string;
    userName: string;
    email: string;
    mobile: string;
    logInBy: number;
    onboardStatus: number;
    createdAt: string;
}

const AssignRM: React.FC = () => {
    const dispatch=useDispatch<any>();
  const [requests, setRequests] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);


  const fetchUsers = async (page: number, rowsPerPage: number) => {
    setLoading(true);
    try {
      const response = await dispatch(
        getUser({
          id: 0,
          firstName: '',
          lastName: '',
          userName: '',
          email: '',
          mobile: '',
          logInBy: 0,
          onboardStatus: 0,
          createdAt: '',
          pageIndex: page, // Pass page as 1-based index
          pageSize: rowsPerPage
        })
      );
  
      const data = response.payload.fdList;
      const totalPages = response.payload.totalPages;
      const currentPage = Math.min(response.payload.pageIndex, totalPages);
  
      setRequests(data);
      setTotalPages(totalPages);
      setError(null);
    } catch (error) {
      setError('Failed to fetch users');
      setRequests([]);
    } finally {
      setLoading(false);
    }
  };
  

    useEffect(() => {
        fetchUsers(page,rowsPerPage);
    }, [dispatch,page, rowsPerPage]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage + 1); // Increment by 1 to align with the 1-based index
    };
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(1); // Reset to the first page when changing rows per page
    };
    

  const handleChangeStatus = async (requestId: number, status: number) => {
    try {
      setLoading(true);
      await api.post("admin/rm/assign", {
        rmId: status,
        userId: [requestId],
      }, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
  

        window.location.reload();

    } catch (error) {
      console.error("Failed to change status", error);
      toast.error("Failed to update Manager. Reloading the page.");
      setTimeout(() => {
        window.location.reload();
      }, 2000); 
     // Reload the page if there's an error as well
    } finally {
      setLoading(false);
    }
  };
  

  const handleOpenDetails = async (id: number) => {
    try {
      console.log("Fetching details for ID:", id);
      const response = await notificationService.getNotificationById({ id: id });
      setSelectedData(response.data);
      setDialogOpen(true);
    } catch (error) {
      console.error('Failed to fetch notification by ID:', error);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedData(null);
  };

  return (
    <>
      <Header />
      <div className="requests">
        <AssignRMTable
          requests={requests}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          loading={loading}
          error={error}
          onChangeStatus={handleChangeStatus}
          onOpenDetails={handleOpenDetails}
        />
        <Toaster />
      </div>
    </>
  );
};

export default AssignRM;
