import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Box,
    Typography,
    TextField,
    Button,
    TablePagination,
    TableFooter,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    FormControl,
    InputLabel,
    Select,
    Checkbox,
    ListItemText,
    MenuItem,
    Input,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import * as XLSX from 'xlsx';
import { Search } from '@mui/icons-material';
import { colors } from '../../constants/colors';
import DownloadIcon from '@mui/icons-material/Download';

// src/interfaces/AgentData.ts
export interface AgentData {
    id: number;
    AgentCode: string;
    ExistCode: string;
    AgentName: string;
    Address1: string;
    Address2: string;
    AddressProof: string;
    City: string;
    State: string;
    PinCode: string;
    Phone: string;
    Mobile: string;
    FAX: string;
    EmailId: string;
    ContactPer: string;
    CPEmailId: string;
    Joiningdate: string;
    CategoryId: string;
    IPOCategoryId: string;
    FDCategoryId: string;
    BranchId: string;
    DOB: string;
    Status: string;
    PAN: string;
    AMFICert: string;
    AMFIExTypeId: string;
    AMFIID: string;
    AMFICertPassDate: string;
    AMFICertExpiryDate: string;
    BankId: string;
    BankBranchAddress: string;
    AccTypeId: string;
    BankAccNo: string;
    MICRCode: string;
    RTGSCode: string;
    Website: string;
    RMCode: string;
    PaymentModeId: string;
    Remark: string;
    TDS: string;
    ValidTracking: string;
    SubAgent: string;
    SourceCode: string;
    Commision: string;
    CalOn: string;
    AnnualIncome: string;
    NomineeName: string;
    NomineeGardian: string;
    Relationship: string;
    NomineeAddress: string;
    NomineePhone: string;
    NomineeDOB: string;
    ARNNo: string;
    CreatedBy: string;
    CreationDate: string;
    ChangedRemarks: string;
    StateCode: string;
    ModifiedBy: string;
    ModifiedDate: string;
    EUINNo: string;
    StartDate: string;
    CategoryIdDirect: string;
    StartDateDirect: string;
    BankName: string;
    RBIBankId: string;
    AgentArnName: string;
    AgentARN: string;
    GSTIN: string;
    IsGlobalAgent: string;
    city1: string;
    ExistCode2: string;
    ParentAgentCode: string;
    DeactivationDate: string;
    ARNStartDate: string;
    ARNEndDate: string;
    AgTypeId: string;
}

// Define the default values for a new agent
export const defaultAgentData: AgentData = {
    id: 0,
    AgentCode: '',
    ExistCode: '',
    AgentName: '',
    Address1: '',
    Address2: '',
    AddressProof: '',
    City: '',
    State: '',
    PinCode: '',
    Phone: '',
    Mobile: '',
    FAX: '',
    EmailId: '',
    ContactPer: '',
    CPEmailId: '',
    Joiningdate: '',
    CategoryId: '',
    IPOCategoryId: '',
    FDCategoryId: '',
    BranchId: '',
    DOB: '',
    Status: '',
    PAN: '',
    AMFICert: '',
    AMFIExTypeId: '',
    AMFIID: '',
    AMFICertPassDate: '',
    AMFICertExpiryDate: '',
    BankId: '',
    BankBranchAddress: '',
    AccTypeId: '',
    BankAccNo: '',
    MICRCode: '',
    RTGSCode: '',
    Website: '',
    RMCode: '',
    PaymentModeId: '',
    Remark: '',
    TDS: '',
    ValidTracking: '',
    SubAgent: '',
    SourceCode: '',
    Commision: '',
    CalOn: '',
    AnnualIncome: '',
    NomineeName: '',
    NomineeGardian: '',
    Relationship: '',
    NomineeAddress: '',
    NomineePhone: '',
    NomineeDOB: '',
    ARNNo: '',
    CreatedBy: '',
    CreationDate: '',
    ChangedRemarks: '',
    StateCode: '',
    ModifiedBy: '',
    ModifiedDate: '',
    EUINNo: '',
    StartDate: '',
    CategoryIdDirect: '',
    StartDateDirect: '',
    BankName: '',
    RBIBankId: '',
    AgentArnName: '',
    AgentARN: '',
    GSTIN: '',
    IsGlobalAgent: '',
    city1: '',
    ExistCode2: '',
    ParentAgentCode: '',
    DeactivationDate: '',
    ARNStartDate: '',
    ARNEndDate: '',
    AgTypeId: ''
};


const AgentManagement: React.FC = () => {
    const [data, setData] = useState<AgentData[]>([]);
    const [editingData, setEditingData] = useState<AgentData | null>(null);
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState<AgentData>({ ...defaultAgentData, id: 0 });
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filteredData, setFilteredData] = useState<AgentData[]>(data);
    const [filters, setFilters] = useState<Record<string, string[]>>({});  // Selected filters


    // useEffect(() => {
    //     // Fetch data from API or any source and set it to data state
    //     // Example: fetchData().then(data => setData(data));
    //     // For demonstration, using an empty array
    //     setData([]);
    // }, []);

    // useEffect(() => {
    //     // Apply filters whenever data or filters change
    //     applyFilters();
    // }, [data, filters]);


    const applyFilters = () => {
        // Filter data based on search term and selected filters
        let filtered = data.filter(row => {
            // Filter by search term
            const matchesSearch = Object.values(row).some(value =>
                value.toLowerCase().includes(searchTerm.toLowerCase())
            );

            // Filter by selected filters
            const filterKeys = Object.keys(filters);
            const matchesFilters = filterKeys.every(key => {
                if (!filters[key].length) return true; // No filter selected
                return filters[key].includes(row[key as keyof AgentData] as string);
            });
            

            return matchesSearch && matchesFilters;
        });

        // Update filtered data state
        setFilteredData(filtered);
    };

    useEffect(() => {
        setFilteredData(data.filter(row =>
            Object.values(row).some(value =>
                typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
            )
        ));
        setPage(0);
    }, [searchTerm, data]);
    
    const getColumnValues = (column: keyof AgentData): string[] => {
        const values = data.map(row => row[column]);
        const stringValues = values.filter((value): value is string => typeof value === 'string');
        return Array.from(new Set(stringValues));
    };


    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleDropdownChange = (event: React.ChangeEvent<{ value: unknown }>, column: keyof AgentData) => {
        const { value } = event.target as { value: string[] };
        const newFilters = { ...filters, [column]: value };
        setFilters(newFilters);

        const filtered = data.filter(row =>
            Object.entries(newFilters).every(([key, valueList]) =>
                valueList.length === 0 || valueList.includes(row[key as keyof AgentData] as string)
            )
        );
        setFilteredData(filtered);
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleEditClick = (row: AgentData) => {
        setFormValues(row);
        setEditingData(row);
        setOpen(true);
    };

    const handleDeleteClick = (id: number) => {
        setData(data.filter(item => item.id !== id));
    };

    const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleFormSubmit = () => {
        if (editingData) {
            setData(data.map(item => (item.id === editingData.id ? formValues : item)));
        } else {
            setData([...data, { ...formValues, id: data.length + 1 }]);
        }
        setOpen(false);
        setFormValues({ ...defaultAgentData, id: 0 });
        setEditingData(null);
    };

    const handleFormCancel = () => {
        setOpen(false);
        setFormValues({ ...defaultAgentData, id: 0 });
        setEditingData(null);
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                const bstr = e.target?.result;
                const wb = XLSX.read(bstr, { type: 'binary' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const excelData = XLSX.utils.sheet_to_json(ws, { header: 1 });
                const newRows = excelData.slice(1).map((row: any, index: number) => ({
                    id: data.length + index + 1,
                        AgentCode: row[0],
                        ExistCode: row[1],
                        AgentName: row[2],
                        Address1: row[3],
                        Address2: row[4],
                        AddressProof: row[5],
                        City: row[6],
                        State: row[7],
                        PinCode: row[8],
                        Phone: row[9],
                        Mobile: row[10],
                        FAX: row[11],
                        EmailId: row[12],
                        ContactPer: row[13],
                        CPEmailId: row[14],
                        Joiningdate: row[15],
                        CategoryId: row[16],
                        IPOCategoryId: row[17],
                        FDCategoryId: row[18],
                        BranchId: row[19],
                        DOB: row[20],
                        Status: row[21],
                        PAN: row[22],
                        AMFICert: row[23],
                        AMFIExTypeId: row[24],
                        AMFIID: row[25],
                        AMFICertPassDate: row[26],
                        AMFICertExpiryDate: row[27],
                        BankId: row[28],
                        BankBranchAddress: row[29],
                        AccTypeId: row[30],
                        BankAccNo: row[31],
                        MICRCode: row[32],
                        RTGSCode: row[33],
                        Website: row[34],
                        RMCode: row[35],
                        PaymentModeId: row[36],
                        Remark: row[37],
                        TDS: row[38],
                        ValidTracking: row[39],
                        SubAgent: row[40],
                        SourceCode: row[41],
                        Commision: row[42],
                        CalOn: row[43],
                        AnnualIncome: row[44],
                        NomineeName: row[45],
                        NomineeGardian: row[46],
                        Relationship: row[47],
                        NomineeAddress: row[48],
                        NomineePhone: row[49],
                        NomineeDOB: row[50],
                        ARNNo: row[51],
                        CreatedBy: row[52],
                        CreationDate: row[53],
                        ChangedRemarks: row[54],
                        StateCode: row[55],
                        ModifiedBy: row[56],
                        ModifiedDate: row[57],
                        EUINNo: row[58],
                        StartDate: row[59],
                        CategoryIdDirect: row[60],
                        StartDateDirect: row[61],
                        BankName: row[62],
                        RBIBankId: row[63],
                        AgentArnName: row[64],
                        AgentARN: row[65],
                        GSTIN: row[66],
                        IsGlobalAgent: row[67],
                        city1: row[68],
                        ExistCode2: row[69],
                        ParentAgentCode: row[70],
                        DeactivationDate: row[71],
                        ARNStartDate: row[72],
                        ARNEndDate: row[73],
                        AgTypeId: row[74],
                }));
                setData([...data, ...newRows]);
            };
            reader.readAsBinaryString(file);
        }
    };

    const handleDownloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Agents");
        XLSX.writeFile(workbook, "agents_master.xlsx");
    };

    return (
        <Box mt={10} mb={10}>
             <Box mb={2} display="flex" justifyContent="space-between">
               <Box>
                    <Button variant="contained" sx={{backgroundColor:colors.primary}} onClick={() => setOpen(true)}>
                        Add New Agent
                    </Button>
                    <Button
                        variant="contained"
                        component="label"
                        style={{ marginLeft: '10px' }}
                        sx={{backgroundColor:colors.primary}}
                    >
                        Import from Excel
                        <input
                            type="file"
                            hidden
                            onChange={handleFileUpload}
                        />
                    </Button>
               </Box>
               <Box display="flex" alignItems="center">
    <TextField
        size="small"
        label="Search"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton>
                        <Search />
                    </IconButton>
                </InputAdornment>
            ),
        }}
    />
    <Button
        variant="contained"
        onClick={handleDownloadExcel}
        sx={{
            marginLeft: 2,
            backgroundColor: colors.orange,
            '&:hover': {
                backgroundColor: 'darkorange',
            },
        }}
    >
        <DownloadIcon/>(.xlsx)
    </Button>
</Box>

               
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                    <TableRow>
                                {['AgentCode', 'ExistCode', 'AgentName', 'City', 'State', 'Mobile', 'Email', 'Pincode', 'PAN', 'DateOfBirth', 'Bank'].map((column, index) => (
                                    <TableCell key={index}>
                                        <FormControl fullWidth>
                                            <InputLabel>{`Select`}</InputLabel>
                                            <Select
                                                multiple
                                                value={filters[column] || []}
                                                displayEmpty={false}
                                                input={<Input id={`select-${column}`} disableUnderline={true} />}
                                                onChange={(e:any) => handleDropdownChange(e, column as keyof AgentData)}
                                                renderValue={selected => (selected as string[]).join(', ')}
                                            >
                                                {getColumnValues(column as keyof AgentData).map((value, index) => (
                                                    <MenuItem key={index} value={value}>
                                                        <Checkbox
                                                            checked={(filters[column] || []).includes(value)}
                                                        />
                                                        <ListItemText primary={value} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                ))}
                            </TableRow>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Agent Code</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Exist Code</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Agent Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Address 1</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Address 2</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Address Proof</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>City</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>State</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Pin Code</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Phone</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Mobile</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>FAX</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Email Id</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Contact Per</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>CP EmailId</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Joining Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Category Id</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>IPO Category Id</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>FD Category Id</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Branch Id</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>DOB</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Status</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>PAN</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>AMFI Cert</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>AMFI Ex Type Id</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>AMFI ID</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>AMFI Cert Pass Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>AMFI Cert Expiry Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Bank Id</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Bank Branch Address</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Acc Type Id</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Bank Acc No</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>MICR Code</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>RTGS Code</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Website</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>RM Code</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Payment Mode Id</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Remark</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>TDS</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Valid Tracking</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>SubAgent</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Source Code</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Commision</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Cal On</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Annual Income</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Nominee Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Nominee Gardian</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Relationship</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Nominee Address</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Nominee Phone</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Nominee DOB</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>ARN No</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Created By</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Creation Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Changed Remarks</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>State Code</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Modified By</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Modified Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>EUIN No</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Start Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Category Id Direct</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Start Date Direct</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Bank Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>RBI Bank Id</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Agent Arn Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Agent ARN</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>GSTIN</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Is Global Agent</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>City 1</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Exist Code 2</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Parent Agent Code</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Deactivation Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>ARN Start Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>ARN End Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Ag Type Id</TableCell>
                            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.AgentCode}</TableCell>
                                <TableCell>{row.ExistCode}</TableCell>
                                <TableCell>{row.AgentName}</TableCell>
                                <TableCell>{row.Address1}</TableCell>
                                <TableCell>{row.Address2}</TableCell>
                                <TableCell>{row.AddressProof}</TableCell>
                                <TableCell>{row.City}</TableCell>
                                <TableCell>{row.State}</TableCell>
                                <TableCell>{row.PinCode}</TableCell>
                                <TableCell>{row.Phone}</TableCell>
                                <TableCell>{row.Mobile}</TableCell>
                                <TableCell>{row.FAX}</TableCell>
                                <TableCell>{row.EmailId}</TableCell>
                                <TableCell>{row.ContactPer}</TableCell>
                                <TableCell>{row.CPEmailId}</TableCell>
                                <TableCell>{row.Joiningdate}</TableCell>
                                <TableCell>{row.CategoryId}</TableCell>
                                <TableCell>{row.IPOCategoryId}</TableCell>
                                <TableCell>{row.FDCategoryId}</TableCell>
                                <TableCell>{row.BranchId}</TableCell>
                                <TableCell>{row.DOB}</TableCell>
                                <TableCell>{row.Status}</TableCell>
                                <TableCell>{row.PAN}</TableCell>
                                <TableCell>{row.AMFICert}</TableCell>
                                <TableCell>{row.AMFIExTypeId}</TableCell>
                                <TableCell>{row.AMFIID}</TableCell>
                                <TableCell>{row.AMFICertPassDate}</TableCell>
                                <TableCell>{row.AMFICertExpiryDate}</TableCell>
                                <TableCell>{row.BankId}</TableCell>
                                <TableCell>{row.BankBranchAddress}</TableCell>
                                <TableCell>{row.AccTypeId}</TableCell>
                                <TableCell>{row.BankAccNo}</TableCell>
                                <TableCell>{row.MICRCode}</TableCell>
                                <TableCell>{row.RTGSCode}</TableCell>
                                <TableCell>{row.Website}</TableCell>
                                <TableCell>{row.RMCode}</TableCell>
                                <TableCell>{row.PaymentModeId}</TableCell>
                                <TableCell>{row.Remark}</TableCell>
                                <TableCell>{row.TDS}</TableCell>
                                <TableCell>{row.ValidTracking}</TableCell>
                                <TableCell>{row.SubAgent}</TableCell>
                                <TableCell>{row.SourceCode}</TableCell>
                                <TableCell>{row.Commision}</TableCell>
                                <TableCell>{row.CalOn}</TableCell>
                                <TableCell>{row.AnnualIncome}</TableCell>
                                <TableCell>{row.NomineeName}</TableCell>
                                <TableCell>{row.NomineeGardian}</TableCell>
                                <TableCell>{row.Relationship}</TableCell>
                                <TableCell>{row.NomineeAddress}</TableCell>
                                <TableCell>{row.NomineePhone}</TableCell>
                                <TableCell>{row.NomineeDOB}</TableCell>
                                <TableCell>{row.ARNNo}</TableCell>
                                <TableCell>{row.CreatedBy}</TableCell>
                                <TableCell>{row.CreationDate}</TableCell>
                                <TableCell>{row.ChangedRemarks}</TableCell>
                                <TableCell>{row.StateCode}</TableCell>
                                <TableCell>{row.ModifiedBy}</TableCell>
                                <TableCell>{row.ModifiedDate}</TableCell>
                                <TableCell>{row.EUINNo}</TableCell>
                                <TableCell>{row.StartDate}</TableCell>
                                <TableCell>{row.CategoryIdDirect}</TableCell>
                                <TableCell>{row.StartDateDirect}</TableCell>
                                <TableCell>{row.BankName}</TableCell>
                                <TableCell>{row.RBIBankId}</TableCell>
                                <TableCell>{row.AgentArnName}</TableCell>
                                <TableCell>{row.AgentARN}</TableCell>
                                <TableCell>{row.GSTIN}</TableCell>
                                <TableCell>{row.IsGlobalAgent}</TableCell>
                                <TableCell>{row.city1}</TableCell>
                                <TableCell>{row.ExistCode2}</TableCell>
                                <TableCell>{row.ParentAgentCode}</TableCell>
                                <TableCell>{row.DeactivationDate}</TableCell>
                                <TableCell>{row.ARNStartDate}</TableCell>
                                <TableCell>{row.ARNEndDate}</TableCell>
                                <TableCell>{row.AgTypeId}</TableCell>
                                <TableCell>
                                <IconButton onClick={() => handleEditClick(row)}>
                                <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDeleteClick(row.id)}>
                                <DeleteIcon />
                                </IconButton>
                                </TableCell>
                                </TableRow>
                                ))}
                                </TableBody>
                                {/* <TableFooter>
                                <TableRow>
                                <TablePagination
                                rowsPerPageOptions={[10, 30, 50, 100]}
                                count={filteredData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                                </TableRow>
                                </TableFooter> */}
                                </Table>
                               
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 30, 50, 100]}
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ borderTop: 'none', padding: 0, marginLeft: 0 }}
            />

            <Dialog open={open} onClose={handleFormCancel}>
                <DialogTitle>{editingData ? 'Edit Agent' : 'Add New Agent'}</DialogTitle>
                <DialogContent>
                    <TextField
                        name="AgentCode"
                        label="Agent Code"
                        fullWidth
                        margin="dense"
                        value={formValues.AgentCode}
                        onChange={handleFormChange}
                    />
                    <TextField
                        name="ExistCode"
                        label="Exist Code"
                        fullWidth
                        margin="dense"
                        value={formValues.ExistCode}
                        onChange={handleFormChange}
                    />
                    <TextField
                        name="AgentName"
                        label="Agent Name"
                        fullWidth
                        margin="dense"
                        value={formValues.AgentName}
                        onChange={handleFormChange}
                    />
                    <TextField
                        name="Address1"
                        label="Address 1"
                        fullWidth
                        margin="dense"
                        value={formValues.Address1}
                        onChange={handleFormChange}
                    />
                    <TextField
                        name="Address2"
                        label="Address 2"
                        fullWidth
                        margin="dense"
                        value={formValues.Address2}
                        onChange={handleFormChange}
                    />
                    <TextField
                        name="City"
                        label="City"
                        fullWidth
                        margin="dense"
                        value={formValues.City}
                        onChange={handleFormChange}
                    />
                    <TextField
                        name="State"
                        label="State"
                        fullWidth
                        margin="dense"
                        value={formValues.State}
                        onChange={handleFormChange}
                    />
                    <TextField
                        name="PinCode"
                        label="Pin Code"
                        fullWidth
                        margin="dense"
                        value={formValues.PinCode}
                        onChange={handleFormChange}
                    />
                    <TextField
                        name="BankId"
                        label="Bank ID"
                        fullWidth
                        margin="dense"
                        value={formValues.BankId}
                        onChange={handleFormChange}
                    />
                    <TextField
                        name="BankBranchAddress"
                        label="Bank Branch Address"
                        fullWidth
                        margin="dense"
                        value={formValues.BankBranchAddress}
                        onChange={handleFormChange}
                    />
                    <TextField
                        name="AccTypeId"
                        label="Account Type ID"
                        fullWidth
                        margin="dense"
                        value={formValues.AccTypeId}
                        onChange={handleFormChange}
                    />
                    <TextField
                        name="BankAccNo"
                        label="Bank Account Number"
                        fullWidth
                        margin="dense"
                        value={formValues.BankAccNo}
                        onChange={handleFormChange}
                    />
                    <TextField
                        name="MICRCode"
                        label="MICR Code"
                        fullWidth
                        margin="dense"
                        value={formValues.MICRCode}
                        onChange={handleFormChange}
                    />
                    <TextField
                        name="RTGSCode"
                        label="RTGS Code"
                        fullWidth
                        margin="dense"
                        value={formValues.RTGSCode}
                        onChange={handleFormChange}
                    />
                    {/* Add more fields as needed */}
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleFormCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        {editingData ? 'Update' : 'Add'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AgentManagement;
