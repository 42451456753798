// FDReporting.tsx
import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { useDispatch } from "react-redux";
import MFHoldingsTable from "../../components/HoldingsTable/MFHoldingsTable";
import { useParams } from "react-router-dom";
import { getMFHoldings } from "../../redux/mf/mfSlice";

interface MF {
  id: number;
  folioNo: string;
  schemeName: string;
  investmentDate: string;
  amount: number;
  purchaseNav: number;
  units: number;
  currentNav: number;
  currentDate: string;
  currentVal: number;
  gainNloss: number;
  absReturn: string;
  xirr: string;
  createdAt: string;
  noOfDays:string;
}

const MFHoldings: React.FC = () => {
  // Different dummy data
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const [MF, setMF] = useState<MF[]>([]);
  const [totalFd, setTotalFd] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const { id } = useParams();

  const fetchMF = async () => {
    setLoader(true);
    try {
      const response = await dispatch(
        getMFHoldings({
          userId: Number(id),
          startDate: 0,
          endDate: 0,
        })
      );
      const data = response.payload;
      setMF(data?.data);
      setTotalPages(data.totalPages);
      setCurrentPage(data.pageIndex);
      console.log(totalPages, currentPage);
      console.log("reports page not the table", data);
    } catch (error) {
      console.error("Error fetching FD data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchMF();
  }, [dispatch, currentPage, pageSize]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage);
    fetchMF(); // Fetch data for the new page
  };

  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reset to first page whenever page size changes
  };

  return (
    <div>
      <Header />
      <div>
        <MFHoldingsTable
          data={MF}
          totalPages={totalPages}
          currentPage={currentPage}
          pageSize={pageSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          fetchData={fetchMF}
        />
      </div>
    </div>
  );
};

export default MFHoldings;
