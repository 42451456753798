import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

const AppLoader = ({ loading }: any) => {
  return (
    <Dialog open={loading}>
      <DialogTitle sx={{ textAlign: "center", overflow: "hidden" }}>
        Please Wait...
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginBottom="8px"
          overflow="hidden"
        >
          <CircularProgress />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AppLoader;
