import React, { useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TableFooter,
  FormControl,
  CircularProgress,
  Select,
  MenuItem,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  InputAdornment,
} from '@mui/material';
import { UnfoldMore as UnfoldMoreIcon, CheckCircle as CheckCircleIcon, Cancel as CancelIcon, Visibility as EyeIcon, Search } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { changeStatus } from '../../../redux/portfolio/changeStatus/statusSlice';
import { getNotificationById } from '../../../redux/notification/notificationSlice';
import { colors } from '../../../constants/colors';
import { Edit as EditIcon, Save as SaveIcon } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import { getAIFSchemes, withdrawRequest } from '../../../redux/holdings/holdingSlice';
import toast from 'react-hot-toast';

interface InvestmentData {
  assetName: string;
  category: string;
  inception: string;
  fundSize: number;
  currentNAV: number;
  returnRate: number;
  promoter: string;
  raisedBar: string;
  investmentAmount: number;
  currentAmount:number;
}

interface Scheme {
  id: number;
  notifyTitle: string;
  notifyMessage: string;
  isRead: number;
  requestStatus: number | ""; 
  createdAt: string;
  investmentData?: InvestmentData; // Add this field to the Scheme interface
}

interface PMSReportsTableProps {
  schemes: Scheme[];
  loading: boolean;
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  onPageChange: (newPageIndex: number) => void;
  onPageSizeChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  notifyType?: number[];
  fetchData: () => void
}

interface PMS {
  requestId:number;
  id: number;
  noteficationId:number;
  holdingName: string;
  category: string;
  investedAmount: number;
  currentAmount: number;
  transactionDate: string;
  currentNavDate: string;
  currentNAV: number;
  realisedPnL: number;
  transactions: [];
  xirr: string;
  days: number;
  unrealisedPnL: number;
  absolutePnL: string;
  type:string;
}

const PMSReportsTable: React.FC<PMSReportsTableProps> = ({
  schemes,
  loading,
  pageIndex,
  pageSize,
  totalPages,
  onPageChange,
  onPageSizeChange,
  notifyType,
  fetchData
}) => {
  const [loadingRows, setLoadingRows] = useState<number[]>([]);
  const [confirmedStatus, setConfirmedStatus] = useState<{ [key: number]: number | undefined }>({});
  const [selectedInvestmentData, setSelectedInvestmentData] = useState<InvestmentData | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [dialogType, setDialogType] = useState<string>("");
  const dispatch = useDispatch<any>();
  const [Status, setStatus] = useState(0);
  const [selectedData, setSelectedData] = useState<PMS | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [scheme, setScheme] = useState<{ id: number; assetName: string }[]>([]);
  const [selectedSchemeId, setSelectedSchemeId] = useState<number | null>(null);
  const [selectedSchemeName, setSelectedSchemeName] = useState<string>("");
  const [Open, setOpen] = useState(false);
  const [userID, setUserID] = useState(null);
  const [notifecationID, setNoteficationID] = useState(null);
  const [loadingRedirect, setLoadingRedirect] = useState(false);
  const navigate = useNavigate();


  const fetchNotificationById = (id: number) => {
    dispatch(getNotificationById({ id }))
      .then((response: any) => {
        if (response.payload.data) {
          setStatus(response.payload.data.reqStatus || response.payload.data.requestStatus);
          setSelectedInvestmentData(response.payload.data.investmentData); // Set investmentData
          setDialogType(notifyType && notifyType[0] === 6 ? "Buy" : "Sell");
          setDialogOpen(true); // Open dialog with the details
          setUserID(response?.payload?.data?.userId);
          handleSelectChange(
            response?.payload?.data?.investmentData?.assetName
          );
          setNoteficationID(response?.payload?.data?.id);
          setSelectedData({
            requestId:Number(response?.payload?.data?.id),
            id: Number(response?.payload?.data?.investmentData?.id),
            noteficationId:Number(response?.payload?.data?.id),
            holdingName: response?.payload?.data?.investmentData?.assetName,
            category: "",
            investedAmount: Number(
              response?.payload?.data?.investmentData?.investmentAmount
            ),
            currentAmount: Number(response?.payload?.data?.investmentData?.currentAmount) || 0,
            transactionDate: "",
            currentNavDate: "",
            currentNAV:0,
            realisedPnL: 0,
            transactions: [],
            xirr: "",
            days: 0,
            unrealisedPnL: 0,
            absolutePnL: "",
            type:notifyType && notifyType[0] === 6 ? "Buy" : "Sell",
          });
        }
        else {
          console.error('Unexpected response structure:', response);
        }
      })
      .catch((error: any) => {
        console.error(`Error fetching notification for ID: ${id}`, error);
      });
  };

  const handleStatusChange = (id: number, status: number) => {
    setLoadingRows((prev) => [...prev, id]);
    let userId:any;
    let Data:any;
    dispatch(getNotificationById({ id }))
      .then((response: any) => {
        if (response.payload.data) {
          setSelectedInvestmentData(response.payload.data.investmentData); // Set investmentData
          userId=response?.payload?.data?.userId;
          setDialogType(notifyType && notifyType[0] === 5 ? "Buy" : "Sell");
          setNoteficationID(response?.payload?.data?.id);
          Data={
            requestId:Number(response?.payload?.data?.id),
            id: Number(response?.payload?.data?.investmentData?.id),
            noteficationId:Number(response?.payload?.data?.id),
            holdingName: response?.payload?.data?.investmentData?.assetName,
            category: "",
            investedAmount: Number(
              response?.payload?.data?.investmentData?.investmentAmount
            ),
            currentAmount: Number(response?.payload?.data?.investmentData?.currentAmount) || 0,
            transactionDate: "",
            currentNavDate: "",
            currentNAV:0,
            realisedPnL: 0,
            transactions: [],
            xirr: "",
            days: 0,
            unrealisedPnL: 0,
            absolutePnL: "",
            type:notifyType && notifyType[0] === 6 ? "Buy" : "Sell",
          };
        } else {
          console.error("Unexpected response structure:", response);
        }
      })
      .catch((error: any) => {
        console.error(`Error fetching notification for ID: ${id}`, error);
      });

    if (status === 1||status===4) {
      setOpen(true);
      setLoadingRedirect(true); // Start the loader
      // After 5 seconds, navigate to the new route
      setTimeout(() => {
        setLoadingRedirect(false); // Stop the loader
        console.log(selectedData, "selectedData");
        sessionStorage.setItem('selectedData', JSON.stringify(selectedData?selectedData:Data));
        navigate(`/preference/pms/${userID?userID:userId}`);
      }, 5000); // 5-second delay
    }else{
      if(status===2){
        dispatch(changeStatus({ id, status }))
        .then(() => {
          setConfirmedStatus((prev) => ({ ...prev, [id]: status }));
          
          fetchData();  
          
        })
        .catch((error: any) => {
          console.error(`Error changing status for ID: ${id}`, error);
        })
        .finally(() => {
          setLoadingRows((prev) => prev.filter((rowId) => rowId !== id));
        });
      }else if(status===5){
        dispatch(withdrawRequest({ id, status:2, amount:0 }))
        .then(() => {
          setConfirmedStatus((prev) => ({ ...prev, [id]: 2 }));          
          fetchData();            
        })
        .catch((error: any) => {
          console.error(`Error changing status for ID: ${id}`, error);
        })
        .finally(() => {
          setLoadingRows((prev) => prev.filter((rowId) => rowId !== id));
        });
      }
    }
  };

  const handleEditClick = () => {
    setEditMode(!editMode); // Toggle edit mode
    setScheme([]);
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedInvestmentData) {
      setSelectedInvestmentData({
        ...selectedInvestmentData,
        [e.target.name]: e.target.value, // Update investment data fields on change
      });
    }
    const { name, value } = e.target;
    // If the field is for the scheme dropdown, call handleSelectChange
    if (name === "assetName") {
      setSearchTerm(value);
      handleSelectChange(value); // Call the scheme-specific function
    }
  };

  const handleSelectChange = async (value: string) => {
    setSearchTerm(value); // Update the search input value

    try {
      const response = await dispatch(
        getAIFSchemes({
          pageIndex: 1,
          pageSize: 100,
          search: value, // Use search term for real-time filtering
          holdingType: 6,
        })
      );
      // Filter out schemes where schemesName is "-" or ""
      const filteredSchemes = response?.payload?.schemeNames?.filter(
        (scheme: { assetName: string }) =>
          scheme.assetName !== "-" && scheme.assetName !== ""
      );
      setScheme(filteredSchemes || []);
      if (filteredSchemes?.length === 1) {
        setSelectedSchemeId(filteredSchemes?.[0]?.id);
        setSelectedSchemeName(filteredSchemes?.[0]?.assetName);
        // handleSchemeSelect(filteredSchemes?.[0]?.id,filteredSchemes?.[0]?.assetName);
      }
    } catch (error) {
      console.error("Error fetching schemes:", error);
      toast.error("An error occurred while fetching schemes.");
    }
  };

  const handleSave = () => {
    // Implement the save functionality (API call or state update)
    setSelectedData({
      requestId:notifecationID||0,
      id: Number(selectedSchemeId),
      noteficationId:Number(notifecationID),
      holdingName: notifyType && notifyType[0] === 6 ?selectedSchemeName:selectedInvestmentData?.assetName ||"",
      category: "",
      investedAmount: Number(selectedInvestmentData?.investmentAmount),
      currentAmount: Number(selectedInvestmentData?.currentAmount) ||0,
      transactionDate: "",
      currentNavDate: "",
      currentNAV: 0,
      realisedPnL: 0,
      transactions: [],
      xirr: "",
      days: 0,
      unrealisedPnL: 0,
      absolutePnL: "",
      type:dialogType,
    });
    console.log("Updated Investment Data:", selectedInvestmentData);
    setEditMode(false); // Switch back to view mode after saving
  };
  const handleSchemeSelect = (schemeId: number, schemeName: string) => {
    // Set the selected scheme ID and update searchTerm with the selected name
    setSelectedSchemeId(schemeId);
    setSearchTerm(schemeName);
    setSelectedSchemeName(schemeName);
    setScheme([]);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedInvestmentData(null);
    setEditMode(false); // Reset edit mode when dialog is closed
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center" marginBottom={2}>
      <Box width="90%" mb={2} display="flex" justifyContent="space-between"></Box>
      <Box width="90%">
        <TableContainer component={Paper} sx={{ padding: '0 20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                {[
                  { label: 'Title', key: 'notifyTitle' },
                  { label: 'Message', key: 'notifyMessage' },
                  { label: 'Status', key: 'requestStatus' },
                  { label: 'Date', key: 'createdAt' },
                  { label: 'Details', key: 'details' },
                ].map(({ label, key }) => (
                  <TableCell key={key}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {label}
                      <UnfoldMoreIcon sx={{ fontSize: 'small' }} />
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {schemes.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.notifyTitle}</TableCell>
                  <TableCell>{row.notifyMessage}</TableCell>
                  <TableCell
                      sx={{
                        width: "150px",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      {row.requestStatus === 1 ? (
                        <CheckCircleIcon style={{ color: "green" }} />
                      ) : row.requestStatus === 2 ? (
                        <CancelIcon style={{ color: "red" }} />
                      ) : (
                        <FormControl
                          fullWidth
                          disabled={loadingRows.includes(row.id)}
                        >
                          <Select
                            value={row.requestStatus ?? ""} // Default to empty string if undefined
                            onChange={(e) => {
                              const newValue = e.target.value;
                              if (newValue !== undefined) {
                                handleStatusChange(row.id, newValue as number);
                              }
                            }}
                            sx={{ width: "100%" }}
                          >
                             <MenuItem value={0} disabled>
                              Select
                            </MenuItem>
                            <MenuItem value={notifyType && notifyType[0] === 6?1:4}>Accept</MenuItem>
                            <MenuItem value={notifyType && notifyType[0] === 6?2:5}>Reject</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                      {loadingRows.includes(row.id) && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "rgba(255, 255, 255, 0.8)",
                            zIndex: 1,
                          }}
                        >
                          <CircularProgress size={20} />
                        </div>
                      )}
                    </TableCell>
                  <TableCell>{new Date(row.createdAt).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => fetchNotificationById(row.id)}>
                        <EyeIcon sx={{color:colors.primary}}/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  count={(totalPages) * pageSize}
                  rowsPerPage={pageSize}
                  page={pageIndex - 1}
                  onPageChange={(event, newPage) => onPageChange(newPage + 1)}
                  onRowsPerPageChange={onPageSizeChange}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${pageIndex} – ${totalPages}`
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          style={{ backgroundColor: colors.primary, color: colors.white }}
        >
          Investment Details
          {!editMode && (
            <IconButton
              onClick={
                Status === 1 || Status === 2 ? () => {} : handleEditClick
              }
              style={{ color: colors.white, float: "right" }}
            >
              {Status === 1 || Status === 2 ? <></> : <EditIcon />}
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent
          style={{
            backgroundColor: colors.lightBg,
            paddingTop: 16,
            paddingBottom: 16,
          }}
        >
          {selectedInvestmentData ? (
            <Box>
              {editMode ? (
                <>
                  {/* Editable fields using TextField */}
                  {notifyType && notifyType[0] === 5 ?(
                  <FormControl fullWidth margin="normal">
                    <TextField
                      label="Search Schemes"
                      variant="outlined"
                      name="assetName"
                      value={searchTerm || selectedInvestmentData?.assetName}
                      onChange={handleFieldChange}
                      fullWidth
                      margin="normal"
                      InputProps={{
                        endAdornment: (                          
                          <InputAdornment position="end">
                            <IconButton>
                              <Search />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    {/* Display the filtered schemes */}
                    {scheme?.map((sch, index) => (
                      <MenuItem
                        key={index}
                        value={sch.assetName}
                        onClick={() =>
                          handleSchemeSelect(sch.id, sch.assetName)
                        } // Set ID and Name
                      >
                        {sch.assetName}
                      </MenuItem>
                    ))}
                  </FormControl>):(
                  <TextField
                    fullWidth
                    label="Asset Name"
                    name="assetName"
                    value={selectedInvestmentData.assetName}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{opacity:0.5}}
                    style={{ marginBottom: 8 }}
                  />)}
                  {/* <TextField
                  fullWidth
                  label="Current NAV"
                  name="currentNAV"
                  value={selectedInvestmentData.currentNAV}
                  onChange={handleFieldChange}
                  style={{ marginBottom: 8 }}
                /> */}
                  <TextField
                    fullWidth
                    label= {notifyType && notifyType[0] === 6 ?"Investment Amount":"Withdrawal Amount"}
                    name="investmentAmount"
                    value={selectedInvestmentData.investmentAmount}
                    onChange={handleFieldChange}
                    style={{ marginBottom: 8 }}
                  />
                  {/* <TextField
                  fullWidth
                  label="Type"
                  name="dialogType"
                  value={dialogType}
                  onChange={(e) => setDialogType(e.target.value)}
                  style={{ marginBottom: 8 }}
                /> */}
                </>
              ) : (
                <>
                  {/* Non-editable view mode */}
                  <Typography variant="body1" style={{ marginBottom: 8 }}>
                    <strong style={{ color: colors.primary }}>
                      Asset Name:
                    </strong>{" "}
                    {selectedSchemeName || selectedInvestmentData?.assetName}
                  </Typography>
                  {/* <Typography variant="body1" style={{ marginBottom: 8 }}>
                  <strong style={{ color: colors.primary }}>Current NAV:</strong>{" "}
                  {selectedInvestmentData.currentNAV}
                </Typography> */}
                  <Typography variant="body1" style={{ marginBottom: 8 }}>
                    <strong style={{ color: colors.primary }}>
                    {notifyType && notifyType[0] === 6 ?"Investment Amount:":"Withdrawal Amount:"}
                    </strong>{" "}
                    {selectedInvestmentData?.investmentAmount||0}
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: 8 }}>
                    <strong style={{ color: colors.primary }}>Type:</strong>{" "}
                    {dialogType}
                  </Typography>
                </>
              )}
            </Box>
          ) : (
            <Typography>No details available.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} style={{ color: colors.primary }}>
            Close
          </Button>
          {editMode && (
            <Button onClick={handleSave} style={{ color: colors.primary }}>
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
        {/* Modal for transaction message */}
      <Dialog open={Open} onClose={() => setOpen(false)}>
        <DialogTitle sx={{ textAlign: "center",overflow:"hidden"}}>
          Transaction Required
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            You will be redirected to transaction window.
          </Typography>
        </DialogContent>
          {loadingRedirect && (
            <Box display="flex" justifyContent="center" alignItems="center" marginBottom="8px" overflow="hidden">
              <CircularProgress />
            </Box>
          )}
      </Dialog>
    </Box>
  );
};

export default PMSReportsTable;
