import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  Backdrop,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { colors } from '../../constants/colors';

interface Notification {
  id: number;
  userId: number;
  isRead: number;
  notifyType: number;
  notifyTitle: string;
  notifyMessage: string;
  createdAt: string;
  userName?: string;
  oldValue?: string;
  newValue?: string;
  requestStatus?: number;
}

interface RequestsTableProps {
  requests: any;
  totalPages: number;
  rowsPerPage: number;
  page: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  error: string | null;
  onChangeStatus: (requestId: number, status: number) => void;
  onOpenDetails: (id: number) => void;
}

const RequestsTable: React.FC<RequestsTableProps> = ({
  requests,
  totalPages,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  loading,
  error,
  onChangeStatus,
  onOpenDetails,
}) => {
  // State to track the currently loading row and status message
  const [loadingRowId, setLoadingRowId] = useState<number | null>(null);
  const [statusMessage, setStatusMessage] = useState<string>(''); // Message state

  // Sort requests by createdAt in descending order
  const sortedRequests = [...requests].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  const handleChangeStatus = async (requestId: number, status: number) => {
    setLoadingRowId(requestId); // Set the loading state to the current row
    setStatusMessage('Changing status'); // Set status message
    
    await onChangeStatus(requestId, status); // Perform the status change
    
    // Wait for 2 seconds before clearing the loading state
    setTimeout(() => {
      setLoadingRowId(null); // Clear the loading state after 2 seconds
      setStatusMessage('');   // Clear the status message
    }, 2000); // 2000 milliseconds = 2 seconds
  };
  
  return (
    <>
      {/* Backdrop loader for global loading state */}
      <Backdrop open={loading} style={{ zIndex: 999 }}>
        <CircularProgress />
      </Backdrop>

      <TableContainer component={Paper} style={{ width: '90%', margin: '0 auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Title</TableCell>
              <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Message</TableCell>
              <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Status</TableCell>
              <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Created At</TableCell>
              <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {error ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  {error}
                </TableCell>
              </TableRow>
            ) : sortedRequests.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No data available
                </TableCell>
              </TableRow>
            ) : (
              sortedRequests.map((request) => (
                <TableRow
                  key={request.id}
                  style={{ backgroundColor: request.isRead === 1 ? '#f0f0f0' : '#ffffff' }}
                >
                  <TableCell>{request.notifyTitle}</TableCell>
                  <TableCell>{request.notifyMessage}</TableCell>
                  <TableCell>
                    {loadingRowId === request.id ? (
                      <Typography variant="body2" color="primary">
                        {statusMessage}...
                      </Typography>
                    ) : request.requestStatus === 1 ? (
                      <CheckCircleIcon style={{ color: 'green' }} />
                    ) : request.requestStatus === 2 ? (
                      <CancelIcon style={{ color: 'red' }} />
                    ) : (
                      <Select
                        value={request.requestStatus || ''}
                        onChange={(e) => handleChangeStatus(request.id, e.target.value as number)}
                        displayEmpty
                        IconComponent={
                          loadingRowId === request.id ? () => <CircularProgress size={14} /> : undefined
                        }
                      >
                        <MenuItem value="" disabled>
                          Select Status
                        </MenuItem>
                        <MenuItem value={1}>Accept</MenuItem>
                        <MenuItem value={2}>Reject</MenuItem>
                      </Select>
                    )}
                  </TableCell>
                  <TableCell>{new Date(request.createdAt).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <IconButton
                      style={{ color: colors.primary }}
                      onClick={() => onOpenDetails(request.id)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalPages * rowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          labelDisplayedRows={({ from, to, count }) => `${page + 1} – ${totalPages}`}
        />
      </TableContainer>
    </>
  );
};

export default RequestsTable;
