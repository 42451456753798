import React, { useEffect, useState } from 'react';
import UserTable from '../../components/UserDetailsTable/UserDetailsTable';
import Header from '../../components/Header/Header';
import { getUser,getAllUserForRM } from '../../redux/user/userSlice';
import { useDispatch } from 'react-redux';

interface User {
    id: number;
    firstName: string;
    lastName: string;
    userName: string;
    email: string;
    mobile: string;
    logInBy: number;
    onboardStatus: number;
    createdAt: string;
}

const UserDetails: React.FC = () => {
    const [editUser, setEditUser] = useState<User | null>(null);
    const [loader, setLoader] = useState<boolean>(false);
    const [users, setUsers] = useState<User[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);    
    const dispatch = useDispatch<any>();
    const userType=localStorage?.getItem("userType");

    const handleEdit = (user: User) => {
        setEditUser(user);
    };

    const handleSave = () => {
        if (editUser) {
            setUsers((prevUsers) =>
                prevUsers.map((user) => (user.id === editUser.id ? editUser : user))
            );
            setEditUser(null);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (editUser) {
            setEditUser((prevUser) => ({
                ...prevUser!,
                [name]: value,
            }));
        }
    };

    const handleCancel = () => {
        setEditUser(null);
    };

    const fetchUsers = async (pageIndex: number, pageSize: number) => {
        setLoader(true);
        try {
            let response;
        
        if (userType === "4") {
            response = await dispatch(
                getUser({
                    id: 0,
                    firstName: '',
                    lastName: '',
                    userName: '',
                    email: '',
                    mobile: '',
                    logInBy: 0,
                    onboardStatus: 0,
                    createdAt: '',
                    pageIndex,
                    pageSize
                })
            );
        } else {
            response = await dispatch(
                getAllUserForRM({
                    pageIndex,
                    pageSize
                })
            );
        }

    
            console.log('Response payload:', response.payload);
    
            const data = response.payload.fdList; 
            const data1 = response.payload.data;
            const totalPages = response.payload.totalPages; 
            console.log('total pages', totalPages);
            const currentPage = Math.min(response.payload.pageIndex, totalPages);
    
            setUsers(data||data1);
            setTotalPages(totalPages);
            setCurrentPage(currentPage);
    
            console.log('Users data:', data);
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoader(false);
        }
    };    
    
    useEffect(() => {
        fetchUsers(currentPage, pageSize); 
      }, [dispatch, currentPage, pageSize]);
      

    const handlePageChange = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
      ) => {
        setCurrentPage(newPage + 1); // MUI's pagination is 0-based, but your API might expect 1-based
      };
      
    

      const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setCurrentPage(1); // Reset to first page whenever page size changes
    };


    return (
        <div>
            <Header />
            <UserTable
                users={users}
                editUser={editUser}
                onEdit={handleEdit}
                onSave={handleSave}
                onChange={handleChange}
                onCancel={handleCancel}
                totalPages={totalPages}
                currentPage = {currentPage}
                pageSize={pageSize}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
        </div>
    );
};

export default UserDetails;
