import { Navigate, Outlet } from 'react-router-dom';


const isAuthenticated = () => {
  return localStorage.getItem('accessToken'); 
};

const PublicRoute = () => {
  return isAuthenticated() ? <Navigate to="/analytics" /> : <Outlet />;
};

export default PublicRoute;
