import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { Box, Typography } from '@mui/material';
import { Dayjs } from 'dayjs';

// Define a callback prop to send the selected date range back to the parent component
interface DatePickerProps {
  onDateRangeChange: (range: [Dayjs | null, Dayjs | null]) => void;
}

export default function DatePicker({ onDateRangeChange }: DatePickerProps) {
  const [dateRange, setDateRange] = React.useState<[Dayjs | null, Dayjs | null]>([null, null]);

  const handleDateRangeChange = (newRange: [Dayjs | null, Dayjs | null]) => {
    setDateRange(newRange); // Update internal state
    onDateRangeChange(newRange); // Send the new range to the parent component
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box position="relative" sx={{ paddingTop: '1rem' }}>
        {/* <Typography
          variant="body2"
          sx={{
            position: 'absolute',
            top: '-0.7rem', // Adjusted to prevent overflow
            left: '0',
            zIndex: 10,
            backgroundColor: 'white',
            color: 'black',
          }}
        >
          Select date range
        </Typography> */}
        <DateRangePicker
          slots={{ field: SingleInputDateRangeField }}
          value={dateRange}
          onChange={handleDateRangeChange}
          format="DD/MM/YYYY" // Set the desired date format
        />
      </Box>
    </LocalizationProvider>
  );
}
