import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  InputAdornment,
  IconButton,
  TableFooter,
  Typography,
  Box,
  FormControl,
  Input,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import {
  Search,
  UnfoldMore,
  Edit,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Visibility as EyeIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import EditForm from "./FDEditForm";
import { changeFdStatus, changeFdWithdrawStatus, getFdScheme } from "../../../redux/fd/fdSlice";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { getNotificationById } from "../../../redux/notification/notificationSlice";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../constants/colors";

interface FD {
  fdId: number;
  id: number;
  noteficationId: number;
  schemeName: string;
  providerName: string;
  category: string;
  amount: number;
  fdStatus: number;
  transactionDate: string;
  investmentFrequency: string;
  interesetRate: number;
  grossInterest: number;
  transactions: [];
  endDate: number;
  investmentTenure: number;
  returnAmount: number;
  createdAt: string;
  daysLeft: number;
  userId: number;
  startDate: number;
  name: string;
  firstName: string;
  type: string;
}
interface InvestmentData {
  assetName: string;
  category: string;
  inception: string;
  fundSize: number;
  currentNAV: number;
  returnRate: number;
  promoter: string;
  raisedBar: string;
  investmentAmount: number;
}

interface Scheme {
  id: number;
  userId: number;
  notifyTitle: string;
  notifyMessage: string;
  isRead: number;
  requestStatus: number | "";
  createdAt: string;
  investmentData?: InvestmentData; // Add this field to the Scheme interface
}

interface Props {
  schemes: Scheme[];
  loading: boolean;
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  onPageChange: (newPageIndex: number) => void;
  onPageSizeChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  notifyType?: number[];
  fetchData: () => void;
}

interface FDSubset {
  firstName: string[];
  providerName: string[];
  amount: string[];
  interesetRate: string[];
  returnAmount: string[];
  startDate: string[];
  endDate: string[];
  fdStatus: string[];
  createdAt: string[];
}

const FDReportsTable: React.FC<Props> = ({
  schemes,
  loading,
  pageIndex,
  pageSize,
  totalPages,
  onPageChange,
  onPageSizeChange,
  notifyType,
  fetchData,
}) => {
  const [loadingRows, setLoadingRows] = useState<number[]>([]);
  const [confirmedStatus, setConfirmedStatus] = useState<{
    [key: number]: number | undefined;
  }>({});
  const [selectedInvestmentData, setSelectedInvestmentData] =
    useState<FD | null>(null);
  const [selectedNotifyType, setSelectedNotifyType] = useState<number | null>(
    null
  );
  const [editMode, setEditMode] = useState(false); // Track whether fields are editable
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<string>("");
  const [Status, setStatus] = useState(0);
  const dispatch = useDispatch<any>();
  const [selectedData, setSelectedData] = useState<FD | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [scheme, setScheme] = useState<{ id: number; schemeName: string }[]>(
    []
  );
  const [selectedSchemeId, setSelectedSchemeId] = useState<number | null>(null);
  const [selectedSchemeName, setSelectedSchemeName] = useState<string>("");
  const [Open, setOpen] = useState(false);
  const [userID, setUserID] = useState(null);
  const [notifecationID, setNoteficationID] = useState(null);
  const [loadingRedirect, setLoadingRedirect] = useState(false);
  const navigate = useNavigate();

  const fetchNotificationById = (id: number) => {
    const scheme = schemes.find((scheme) => scheme.id === id);
    dispatch(getNotificationById({ id }))
      .then((response: any) => {
        if (response.payload.data) {
          setStatus(
            response.payload.data.reqStatus ||
              response.payload.data.requestStatus
          );
          setSelectedInvestmentData(response.payload.data); // Set investmentData
          setUserID(response?.payload?.data?.userId);
          setDialogType(notifyType && notifyType[0] === 2 ? "Buy" : "Sell");
          setDialogOpen(true); // Open dialog with the details
          handleSelectChange(
            response?.payload?.data?.investmentData?.assetName
          );
          setNoteficationID(response?.payload?.data?.id);
          setSelectedData({
            fdId: Number(response?.payload?.data?.fdId),
            id: 0,
            noteficationId: Number(response?.payload?.data?.id),
            schemeName: selectedSchemeName
              ? selectedSchemeName
              : response?.payload?.data?.schemeName,
            providerName: "",
            category: "",
            amount: Number(response?.payload?.data?.amount),
            fdStatus: 0,
            transactionDate: response?.payload?.data?.transactionDate,
            investmentFrequency: response?.payload?.data?.investmentFrquency,
            interesetRate: Number(response?.payload?.data?.interesetRate),
            grossInterest: Number(response?.payload?.data?.grossInterest),
            transactions: [],
            endDate: Number(response?.payload?.data?.endDate),
            investmentTenure: Number(response?.payload?.data?.investmentTenure),
            returnAmount: Number(response?.payload?.data?.returnAmount),
            createdAt: response?.payload?.data?.createdAt,
            daysLeft: 0,
            userId:
              Number(response?.payload?.data?.userId) ||
              Number(selectedData?.userId),
            startDate: 0,
            name: "",
            firstName: response?.payload?.data?.userName,
            type: notifyType && notifyType[0] === 2 ? "Buy" : "Sell",
          });
        } else {
          console.error("Unexpected response structure:", response);
        }
      })
      .catch((error: any) => {
        console.error(`Error fetching notification for ID: ${id}`, error);
      });
  };

  const handleStatusChange = (id: number, status: number,UserId:number) => {
    setLoadingRows((prev) => [...prev, id]);
    let userId: any;
    let Data: any;
    dispatch(getNotificationById({ id }))
      .then((response: any) => {
        if (response.payload.data) {
          setSelectedInvestmentData(response.payload.data); // Set investmentData
          userId = response?.payload?.data?.userId;
          setDialogType(notifyType && notifyType[0] === 2 ? "Buy" : "Sell");
          setNoteficationID(response?.payload?.data?.id);
          Data = {
            fdId: Number(response?.payload?.data?.fdId),
            id: 0,
            noteficationId: Number(response?.payload?.data?.id),
            schemeName: searchTerm || response?.payload?.data?.schemeName,
            providerName: "",
            category: "",
            amount: Number(response?.payload?.data?.amount),
            fdStatus: 0,
            transactionDate: response?.payload?.data?.transactionDate,
            investmentFrequency: response?.payload?.data?.investmentFrequency,
            interesetRate: Number(response?.payload?.data?.interesetRate),
            grossInterest: Number(response?.payload?.data?.grossInterest),
            transactions: [],
            endDate: Number(response?.payload?.data?.endDate),
            investmentTenure: Number(response?.payload?.data?.investmentTenure),
            returnAmount: Number(response?.payload?.data?.returnAmount),
            createdAt: response?.payload?.data?.createdAt,
            daysLeft: 0,
            userId:
              Number(response?.payload?.data?.userId) ||
              Number(selectedData?.userId),
            startDate: 0,
            name: "",
            firstName: response?.payload?.data?.userName,
            type: notifyType && notifyType[0] === 2 ? "Buy" : "Sell",
          };
        } else {
          console.error("Unexpected response structure:", response);
        }
      })
      .catch((error: any) => {
        console.error(`Error fetching notification for ID: ${id}`, error);
      });
    if (status === 1) {
      setOpen(true);
      setLoadingRedirect(true); // Start the loader
      // After 5 seconds, navigate to the new route
      setTimeout(() => {
        setLoadingRedirect(false); // Stop the loader
        console.log(Data, "000selectedData000");
        sessionStorage.setItem(
          "selectedData",
          JSON.stringify(selectedData ? selectedData : Data)
        );
        navigate(`/preference/corporateFD/${userID ? userID : userId}`);
      }, 5000); // 5-second delay
    } else {
      if(notifyType && notifyType[0] === 2){
        const updateData = { id: id, status: status, userId: userID?userID:UserId };
      console.log(updateData,"UpdateData");
      setLoadingRows((prev) => [...prev, id]);

      // Simulate loading with a timeout
      setTimeout(() => {
        dispatch(changeFdStatus(updateData))
          .then(() => {
            setLoadingRows((prev) => prev.filter((rowId) => rowId !== id));
            setConfirmedStatus((prev) => ({ ...prev, [id]: status }));

            fetchData();
          })
          .catch(() => {
            setLoadingRows((prev) => prev.filter((rowId) => rowId !== id));
          });
      }, 1000); // 1 second delay
      }
      else{
        const updateData = { id: id, status: status };
      console.log(updateData,"UpdateData");
      setLoadingRows((prev) => [...prev, id]);

      // Simulate loading with a timeout
      setTimeout(() => {
        dispatch(changeFdWithdrawStatus(updateData))
          .then(() => {
            setLoadingRows((prev) => prev.filter((rowId) => rowId !== id));
            setConfirmedStatus((prev) => ({ ...prev, [id]: status }));

            fetchData();
          })
          .catch(() => {
            setLoadingRows((prev) => prev.filter((rowId) => rowId !== id));
          });
      }, 1000); // 1 second delay
      }
    }
  };

  const handleEditClick = () => {
    setEditMode(!editMode); // Toggle edit mode
    setScheme([]);
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedInvestmentData) {
      setSelectedInvestmentData({
        ...selectedInvestmentData,
        [e.target.name]: e.target.value, // Update investment data fields on change
      });
    }
    const { name, value } = e.target;
    // If the field is for the scheme dropdown, call handleSelectChange
    if (name === "schemeName") {
      setSearchTerm(value);
      handleSelectChange(value); // Call the scheme-specific function
    }
  };

  const handleSelectChange = async (value: string) => {
    setSearchTerm(value); // Update the search input value

    try {
      const response = await dispatch(
        getFdScheme({
          search: value, // Use search term for real-time filtering
        })
      );
      // Filter out schemes where schemesName is "-" or ""
      const filteredSchemes = response?.payload?.data?.filter(
        (scheme: { schemeName: string }) =>
          scheme.schemeName !== "-" && scheme.schemeName !== ""
      );
      setScheme(filteredSchemes || []);
      if (filteredSchemes?.length === 1) {
        setSelectedSchemeId(filteredSchemes?.[0]?.id);
        setSelectedSchemeName(filteredSchemes?.[0]?.assetName);
        // handleSchemeSelect(filteredSchemes?.[0]?.id,filteredSchemes?.[0]?.assetName);
      }
    } catch (error) {
      console.error("Error fetching schemes:", error);
      toast.error("An error occurred while fetching schemes.");
    }
  };

  const handleSchemeSelect = (schemeId: number, schemeName: string) => {
    // Set the selected scheme ID and update searchTerm with the selected name
    setSelectedSchemeId(schemeId);
    setSearchTerm(schemeName);
    setSelectedSchemeName(schemeName);
    setScheme([]);
  };
  // console.log(selectedSchemeId,selectedSchemeName,"aif reports")

  const handleSave = () => {
    // Implement the save functionality (API call or state update)
    console.log(selectedSchemeName, "selectedSchemeName");
    setSelectedData({
      fdId: Number(selectedInvestmentData?.fdId),
      id: 0,
      noteficationId: Number(selectedInvestmentData?.id),
      schemeName: searchTerm || selectedInvestmentData?.schemeName || "",
      providerName: "",
      category: "",
      amount: Number(selectedInvestmentData?.amount),
      fdStatus: 0,
      transactionDate: selectedInvestmentData?.transactionDate || "",
      investmentFrequency: selectedInvestmentData?.investmentFrequency || "",
      interesetRate: Number(selectedInvestmentData?.interesetRate),
      grossInterest: Number(selectedInvestmentData?.grossInterest),
      transactions: [],
      endDate: Number(selectedInvestmentData?.endDate),
      investmentTenure: Number(selectedInvestmentData?.investmentTenure),
      returnAmount: Number(selectedInvestmentData?.returnAmount),
      createdAt: selectedInvestmentData?.createdAt || "",
      daysLeft: 0,
      userId:
        Number(selectedInvestmentData?.userId) || Number(selectedData?.userId),
      startDate: 0,
      name: "",
      firstName: "",
      type: dialogType,
    });
    console.log("Updated Investment Data:", selectedInvestmentData);
    setEditMode(false); // Switch back to view mode after saving
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedInvestmentData(null);
    setEditMode(false); // Reset edit mode when dialog is closed
  };
  console.log(selectedData, "selected data");
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginBottom={2}
    >
      <Box
        width="90%"
        mb={2}
        display="flex"
        justifyContent="space-between"
      ></Box>
      <Box width="90%">
        <TableContainer component={Paper} sx={{ padding: "0 20px" }}>
          <Table>
            <TableHead>
              <TableRow>
                {[
                  { label: "Title", key: "notifyTitle" },
                  { label: "Message", key: "notifyMessage" },
                  { label: "Status", key: "requestStatus" },
                  { label: "Date", key: "createdAt" },
                  { label: "Details", key: "details" },
                ].map(({ label, key }) => (
                  <TableCell key={key}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {label}
                      <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {schemes.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.notifyTitle}</TableCell>
                  <TableCell>{row.notifyMessage}</TableCell>
                  <TableCell
                    sx={{
                      width: "150px",
                      textAlign: "center",
                      position: "relative",
                    }}
                  >
                    {row.requestStatus === 1 ? (
                      <CheckCircleIcon style={{ color: "green" }} />
                    ) : row.requestStatus === 2 ? (
                      <CancelIcon style={{ color: "red" }} />
                    ) : (
                      <FormControl
                        fullWidth
                        disabled={loadingRows.includes(row.id)}
                      >
                        <Select
                          value={row.requestStatus ?? ""} // Default to empty string if undefined
                          onChange={(e) => {
                            const newValue = e.target.value;
                            if (newValue !== undefined) {
                              handleStatusChange(
                                row.id,
                                e.target.value as number,
                                row.userId
                              );
                            }
                          }}
                          sx={{ width: "100%" }}
                        >
                          <MenuItem value={0} disabled>
                            Select
                          </MenuItem>
                          <MenuItem
                            value={
                              (notifyType && notifyType[0] === 2) ||
                              (notifyType && notifyType[0] === 10)
                                ? 1
                                : 0
                            }
                          >
                            Accept
                          </MenuItem>
                          <MenuItem
                            value={
                              (notifyType && notifyType[0] === 2) ||
                              (notifyType && notifyType[0] === 10)
                                ? 2
                                : 0
                            }
                          >
                            Reject
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    {loadingRows.includes(row.id) && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "rgba(255, 255, 255, 0.8)",
                          zIndex: 1,
                        }}
                      >
                        <CircularProgress size={20} />
                      </div>
                    )}
                  </TableCell>

                  <TableCell>
                    {new Date(row.createdAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => fetchNotificationById(row.id)}>
                      <EyeIcon sx={{ color: colors.primary }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  count={totalPages * pageSize}
                  rowsPerPage={pageSize}
                  page={pageIndex - 1}
                  onPageChange={(event, newPage) => onPageChange(newPage + 1)}
                  onRowsPerPageChange={onPageSizeChange}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${pageIndex} – ${totalPages}`
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          style={{ backgroundColor: colors.primary, color: colors.white }}
        >
          Investment Details
          {!editMode && (
            <IconButton
              onClick={
                Status === 1 || Status === 2 ? () => {} : handleEditClick
              }
              style={{ color: colors.white, float: "right" }}
            >
              {Status === 1 || Status === 2 || dialogType==="Sell" ? <></> : <EditIcon />}
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent
          style={{
            backgroundColor: colors.lightBg,
            paddingTop: 16,
            paddingBottom: 16,
          }}
        >
          {selectedInvestmentData ? (
            <Box>
              {editMode ? (
                <>
                  {/* Editable fields using TextField */}
                  {notifyType && notifyType[0] === 2 ? (
                    <FormControl fullWidth margin="normal">
                      <TextField
                        label="Search Schemes"
                        variant="outlined"
                        name="schemeName"
                        value={searchTerm || selectedInvestmentData?.schemeName}
                        onChange={handleFieldChange}
                        fullWidth
                        margin="normal"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <Search />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {/* Display the filtered schemes */}
                      {scheme?.map((sch, index) => (
                        <MenuItem
                          key={index}
                          value={sch.schemeName}
                          onClick={() =>
                            handleSchemeSelect(sch.id, sch.schemeName)
                          } // Set ID and Name
                        >
                          {sch.schemeName}
                        </MenuItem>
                      ))}
                    </FormControl>
                  ) : (
                    <TextField
                      fullWidth
                      label="Scheme Name"
                      name="schemeName"
                      value={selectedInvestmentData.schemeName}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{ opacity: 0.5 }}
                      style={{ marginBottom: 8 }}
                    />
                  )}
                  {/* <TextField
                  fullWidth
                  label="Current NAV"
                  name="currentNAV"
                  value={selectedInvestmentData.currentNAV}
                  onChange={handleFieldChange}
                  style={{ marginBottom: 8 }}
                /> */}
                  <TextField
                    fullWidth
                    label={
                      notifyType && notifyType[0] === 2
                        ? "Investment Amount"
                        : "Withdrawal Amount"
                    }
                    name="amount"
                    value={selectedInvestmentData.amount}
                    onChange={handleFieldChange}
                    style={{ marginBottom: 8 }}
                  />
                  {/* <TextField
                  fullWidth
                  label="Type"
                  name="dialogType"
                  value={dialogType}
                  onChange={(e) => setDialogType(e.target.value)}
                  style={{ marginBottom: 8 }}
                /> */}
                </>
              ) : (
                <>
                  {/* Non-editable view mode */}
                  <Typography variant="body1" style={{ marginBottom: 8 }}>
                    <strong style={{ color: colors.primary }}>
                      Scheme Name:
                    </strong>{" "}
                    {selectedSchemeName || selectedInvestmentData?.schemeName}
                  </Typography>
                  {/* <Typography variant="body1" style={{ marginBottom: 8 }}>
                  <strong style={{ color: colors.primary }}>Current NAV:</strong>{" "}
                  {selectedInvestmentData.currentNAV}
                </Typography> */}
                  <Typography variant="body1" style={{ marginBottom: 8 }}>
                    <strong style={{ color: colors.primary }}>
                      {notifyType && notifyType[0] === 2
                        ? "Investment Amount:"
                        : "Withdrawal Amount:"}
                    </strong>{" "}
                    {selectedInvestmentData?.amount || 0}
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: 8 }}>
                    <strong style={{ color: colors.primary }}>Type:</strong>{" "}
                    {dialogType}
                  </Typography>
                </>
              )}
            </Box>
          ) : (
            <Typography>No details available.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} style={{ color: colors.primary }}>
            Close
          </Button>
          {editMode && (
            <Button onClick={handleSave} style={{ color: colors.primary }}>
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {/* Modal for transaction message */}
      <Dialog open={Open} onClose={() => setOpen(false)}>
        <DialogTitle sx={{ textAlign: "center", overflow: "hidden" }}>
          Transaction Required
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            You will be redirected to transaction window.
          </Typography>
        </DialogContent>
        {loadingRedirect && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginBottom="8px"
            overflow="hidden"
          >
            <CircularProgress />
          </Box>
        )}
      </Dialog>
    </Box>
  );
};

export default FDReportsTable;
