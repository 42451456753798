import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, Box, FormControl, FormHelperText, InputLabel } from '@mui/material';
import { styled } from '@mui/system';

type InputProps = {
    label: string;
    type: string;
    value: any;
    id: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    name: string;
    suffix?: React.ReactNode;
    isPassword?: boolean;
    error?: string;
    touched?: boolean;
    variant?: string;
};

const StyledInput = styled('input')(({ theme }) => ({
    width: '100%',
    padding: '10px 14px',
    border: '1px solid #ced4da',
    borderRadius: '4px',
    fontSize: '16px',
    boxSizing: 'border-box',
    paddingRight: '40px',
    '@media (max-width: 960px)': { // Tablet size
        padding: '8px 12px',
        fontSize: '14px',
    },
    '@media (min-width: 960px)': { // Large screens
        padding: '12px 16px',
        fontSize: '18px',
    },
}));

const CommonInput = ({ label, type, onChange, value, suffix, isPassword, name, id, error, touched, disabled }: InputProps) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible((prev) => !prev);
    };

    return (
        <FormControl margin="normal" fullWidth error={!!error && touched}>
            <InputLabel shrink htmlFor={id} style={{ background: '#fff', padding: '0 4px', marginLeft: '4px' }}>
                {label}
            </InputLabel>
            <Box position="relative" display="flex" alignItems="center">
                <StyledInput
                    type={isPassword && passwordVisible ? 'text' : type}
                    name={name}
                    onChange={onChange}
                    value={type === 'file' ? undefined : value} // Handle file input
                    id={id}
                    placeholder={label}
                    disabled={disabled}
                />
                {isPassword && (
                    <IconButton
                        onClick={togglePasswordVisibility}
                        style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                    >
                        {passwordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                )}
                {suffix && <Box ml={1}>{suffix}</Box>}
            </Box>
            {touched && error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};

export default CommonInput;
