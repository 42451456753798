import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute'; // Import the private route component
import PublicRoute from './PublicRoute';
// Public pages
import Login from '../pages/Login/Login';
import PageNotFound from '../pages/PageNotFound/PageNotFound';

// Protected pages
import AnalyticsDash from '../pages/AnalyticsDashboard/AnalyticsDash';
import FDReporting from '../pages/Reports/FDReports';
import UserDetails from '../pages/UserDetails/UserDetails';
import NomineeDetails from '../pages/NomineeDetails/NomineeDetails';
import DematDetails from '../pages/DematDetails/DematDetails';
import FDRequest from '../pages/Requests/FD/FDRequest';
import AgentMaster from '../pages/AgentMaster/AgentMaster';
import UserEdit from '../pages/UserDetails/UserEdit';
import AddUser from '../pages/AddUser/AddUser';
import MFTransactions from '../pages/Reports/MFTransactions';
import Requests from '../pages/AllRequests/Requests';
import AIF from '../pages/Upload/AIF';
import PMS from '../pages/Upload/PMS';
import Bonds from '../pages/Upload/Bonds';
import RMDetails from '../pages/RelationManager/RMDetails';
import AddRM from '../pages/AddRM/AddRM';
import AssignRM from '../pages/RelationManager/AssignRM';
import AIFReports from '../pages/Reports/AIFBuyReports';
import MFReports from '../pages/Reports/MFReports';
import PMSReports from '../pages/Reports/PMSBuyReports';
import BondsReports from '../pages/Reports/BondsReports';
import Holdings from '../pages/Holdings/Holdings';
import Preferences from '../pages/Holdings/Preferences';
import UploadComponent from '../pages/Holdings/Upload';
import AifHolding from '../pages/Holdings/AifHoldings';
import PmsHoldings from '../pages/Holdings/PmsHoldings';
import AIFSellReports from "../pages/Reports/AIFSellReports";
import PMSSellReports from "../pages/Reports/PMSSellReports";
import UploadHoldings from '../pages/Holdings/UploadHoldings';
import UploadPreferences from '../pages/Holdings/UploadPreferences';
import Profile from '../pages/Profile Page/Profile';
import FDHoldings from '../pages/Holdings/FDHoldings';
import EditModalForHoldings from '../components/Modal/EditModalForAIFHoldings';
import MFHoldings from '../pages/Holdings/MFHoldings';
import FDReportingSell from '../pages/Reports/FDReportsSell';
import Append from '../pages/Holdings/Append';
import Replace from '../pages/Holdings/Replace';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route element={<PublicRoute />}>
          <Route path="/" element={<Login />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
        {/* Protected Routes (wrapped inside PrivateRoute) */}
        <Route element={<PrivateRoute />}>
          <Route path="/analytics" element={<AnalyticsDash />} />
          <Route path="/requests" element={<Requests />} />
          <Route path="/reports/fd/buy" element={<FDReporting />} />
          <Route path="/reports/fd/sell" element={<FDReportingSell />} />
          <Route path="/investor" element={<UserDetails />} />
          <Route path="/investor/demat" element={<DematDetails />} />
          <Route path="/investor/nominee" element={<NomineeDetails />} />
          <Route path="/requests/fd" element={<FDRequest />} />
          <Route path="/userDetails/:id" element={<UserEdit />} />
          <Route path="/addDetails" element={<AddUser />} />
          <Route path="/investor/agent" element={<AgentMaster />} />
          <Route path="/reports/mf/transactions" element={<MFTransactions />} />
          <Route path="/upload/file-aif" element={<AIF />} />
          <Route path="/upload/file-pms" element={<PMS />} />
          <Route path="/upload/file-bonds" element={<Bonds />} />
          <Route path="/rm" element={<RMDetails />} />
          <Route path="/addRM" element={<AddRM />} />
          <Route path="/assign-rm" element={<AssignRM />} />
          <Route path="/aif/buy" element={<AIFReports />} />
          <Route path="/aif/sell" element={<AIFSellReports />} />
          <Route path="/pms/buy" element={<PMSReports />} />
          <Route path="/pms/sell" element={<PMSSellReports />} />
          <Route path="/bonds" element={<BondsReports />} />
          <Route path="/holdings" element={<Holdings />} />
          <Route path="/uploadHoldings" element={<UploadHoldings/>} />
          <Route path="/preferences" element={<Preferences />} />
          <Route path="/upload/preferences" element={<UploadPreferences />} />
          <Route path="/upload/preference/append/:id" element={<Append />} />
          <Route path="/upload/preference/replace/:id" element={<Replace />} />
          <Route path="/preference/corporateFD/:id" element={<FDHoldings />} />
          <Route path="/preference/mutualFunds/:id" element={<MFHoldings />} />
          <Route path="/preference/aif/:id" element={<AifHolding />} />
          <Route path="/preference/pms/:id" element={<PmsHoldings/>}/>
          <Route path="/upload" element={<UploadComponent />} />
          <Route path="/reports/mf" element={<MFReports />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;
