import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../auth/authService";
import { LoginModel } from "../../models/login.model";


export const login = createAsyncThunk(
    "auth/login",
    async (data: LoginModel, thunkAPI: any) => {
      try {
        return await authService.login(data);
      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );


  const initialState = {
    otp: "",
    accessToken: "",
    refreshToken: "",
    signUpStatus: 0,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    addedPassword: "",
    data: {},
    socialLoggedIn: false,
  };


  export const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
      resetState: (state) => initialState,
    },
    extraReducers: (builder) => {
      builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.accessToken = action.payload.data.accessToken;
        state.refreshToken = action.payload.data.refreshToken;
      })
      .addCase(login.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
    },
});

export const { resetState } = authSlice.actions;
export default authSlice.reducer;