import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import nomineeService from "./nomineeService";

export const updateNominee = createAsyncThunk(
    "bank/update",
    async (data: FormData, thunkAPI: any) => {
      try {

        const response = await nomineeService.updateNominee(data);
        console.log(response, ' this is the update nominee response');
        return response

      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  )

  const initialState = {
    data: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  };

  export const nomineeSlice = createSlice({
    name: "nominee",
    initialState: initialState,
    reducers: {
      resetState: (state) => initialState,
    },
    extraReducers: (builder) => {
      builder
        .addCase(updateNominee.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(updateNominee.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.data = action.payload.data;
        })
        .addCase(updateNominee.rejected, (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = action.error.message || "";
        })
    },
  });
  
  export const { resetState } = nomineeSlice.actions;
  export default nomineeSlice.reducer;