import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Box,
    Typography,
    TablePagination,
    TableFooter,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { colors } from '../../constants/colors';
import SearchIcon from '@mui/icons-material/Search';

interface User {
    id: number;
    firstName: string;
    email: string;
    mobile: string;
    createdAt: string;
}

interface RMTableProps {
    users: User[];
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onCancel: () => void;
    totalPages: number; // Total number of users
    pageSize: number; // Current page size
    onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void; // Page change handler
    onPageSizeChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void; // Page size change handler
    currentPage: number; // Current page number
}

const RMTable: React.FC<RMTableProps> = ({users, totalPages, currentPage, pageSize, onPageChange, onPageSizeChange }) => {
   
    console.log('this is current page',currentPage)
    console.log('this is page size',pageSize)
    const [searchQuery, setSearchQuery] = useState('');

    console.log(users)
    function formatDate(dateString:any) {
        const date = new Date(dateString);
        
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JavaScript
        const year = date.getFullYear();
    
        return `${day}-${month}-${year}`;
    }
    
    const filteredUsers = users.filter(
        (user) =>
          user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.mobile.includes(searchQuery)
      )

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom:'20px' }}>
            <Box width="90%" mb={2} sx={{display:'flex', justifyContent:'space-between'}}>
                <Typography variant="h5" style={{ textAlign: 'left' }}>Relation Managers Details</Typography>
                <Button variant='contained' color='primary' component={Link} to={`/addRM`}>Add Manager</Button>
            </Box>
            <Box
        width="90%"
        mb={2}
        sx={{ display: "flex", justifyContent: "flex-start" }}
      >
          <div style={{ position: "relative", width: "800px" }}>
            {" "}
            {/* Set width of the input */}
            <input
              type="text"
              placeholder="Search users" // Add placeholder
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{
                width: "100%",
                padding: "10px 20px 10px 45px", // Increase left padding for the icon
                borderRadius: "5px",
                border: "1px solid #ccc",
                fontSize: "16px",
              }}
            />
            {/* Add search icon */}
            <SearchIcon
              style={{
                position: "absolute",
                top: "50%",
                left: "15px", // Position the icon closer to the left side
                transform: "translateY(-50%)",
                color: "#888",
              }}
            />
          </div>
      </Box>
            <div style={{ display: 'flex', justifyContent: 'center', width: '90%' }}>
                <TableContainer component={Paper} style={{ width: '100%', marginTop: '2rem' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Name</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Email</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Mobile</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Registered At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                {filteredUsers && filteredUsers?.map((user,index) => (
                                <TableRow key={index}>
                                    <TableCell>{user?.firstName}</TableCell>
                                    <TableCell>{user?.email}</TableCell>
                                    <TableCell>{user?.mobile}</TableCell>
                                    <TableCell>{formatDate(user?.createdAt)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                            <TablePagination
    rowsPerPageOptions={[10, 20, 50, 100]}
    count={totalPages * pageSize} // Total number of rows
    rowsPerPage={pageSize}
    page={currentPage - 1} // Page is zero-indexed
    onPageChange={onPageChange}
    onRowsPerPageChange={onPageSizeChange}
    labelDisplayedRows={({ from, to, count }) =>
        `${currentPage} – ${totalPages} `
    }
/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default RMTable;
