import React, { useState } from 'react';
import Header from '../../../components/Header/Header';
import FDRequestTable from '../../../components/RequestsTable/FD/FDRequestTable';

interface UserDetailsProps {
    id: number;
    name: string;
    email: string;
    mobile: string;
    aadharNumber: string;
    panDetails: string;
    dob: string;
    address: string;
}

interface FDRequestProps {
    id: number;
    userId: number;
    investorAadhar: string;
    investorPan: string;
    amountInvested: number;
    schemeName: string;
    units: number;
    AUM: number;
    rank: number;
    currentNAV: number;
    risk: string;
    user?: UserDetailsProps;
}

const NomineeDetails: React.FC = () => {
    const initialUserDetails: UserDetailsProps[] = [
        {
            id: 1,
            name: 'John Doe',
            email: 'john.doe@example.com',
            mobile: '123-456-7890',
            aadharNumber: '9876 5432 1098',
            panDetails: 'FGHIJ5678K',
            dob: '1985-09-20',
            address: '789, Maple Avenue, Chicago, 60601',
        },
        {
            id: 2,
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
            mobile: '987-654-3210',
            aadharNumber: '1234 5678 9012',
            panDetails: 'ABCDE1234F',
            dob: '1990-05-15',
            address: '456, Elm Street, Los Angeles, 90001',
        },
        // Add more user details as needed
    ];

    const initialFDRequestDetails: FDRequestProps[] = [
        {
            id: 1,
            userId: 1,
            investorAadhar: '1122 3344 5566',
            investorPan: 'ABCDE1234X',
            amountInvested: 500000,
            schemeName: 'Scheme 1',
            units: 10000,
            AUM: 100000,
            rank: 1,
            currentNAV: 15,
            risk: 'High',
        },
        {
            id: 2,
            userId: 2,
            investorAadhar: '2233 4455 6677',
            investorPan: 'XYZ9876543',
            amountInvested: 1000000,
            schemeName: 'Scheme 2',
            units: 20000,
            AUM: 200000,
            rank: 2,
            currentNAV: 20,
            risk: 'Medium',
        },
        // Add more FD request details as needed
    ];

    const [userData, setUserData] = useState<UserDetailsProps[]>(initialUserDetails);
    const [fdRequestData, setFDRequestData] = useState<FDRequestProps[]>(initialFDRequestDetails);

    const handleInvestorEdit = (fdRequest: FDRequestProps) => {
        // Logic to handle editing FD request details
    };

    const mergedFDRequestDetails: FDRequestProps[] = fdRequestData.map(fdRequest => ({
        ...fdRequest,
        user: userData.find(user => user.id === fdRequest.userId),
    }));

    return (
        <div>
            <Header />
            <FDRequestTable data={mergedFDRequestDetails} onEdit={handleInvestorEdit} />
        </div>
    );
};

export default NomineeDetails;
