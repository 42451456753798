import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dematService from "./dematService";

export const updateDemat = createAsyncThunk(
    "demat/update",
    async (data: FormData, thunkAPI: any) => {
      try {

        const response = await dematService.updateDemat(data);
        console.log(response, ' this is the update nominee response');
        return response

      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  )

  const initialState = {
    data: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  };

  export const dematSlice = createSlice({
    name: "demat",
    initialState: initialState,
    reducers: {
      resetState: (state) => initialState,
    },
    extraReducers: (builder) => {
      builder
        .addCase(updateDemat.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(updateDemat.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.data = action.payload.data;
          console.log(state.data, ' this is demat slice')
        })
        .addCase(updateDemat.rejected, (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = action.error.message || "";
        })
    },
  });
  
  export const { resetState } = dematSlice.actions;
  export default dematSlice.reducer;