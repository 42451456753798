import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import notificationService from "./notificationService";


export const notification = createAsyncThunk(
    "notification/getAll",
    async (data: any, thunkAPI) => {
      try {

        const response = await notificationService.notification(data);
        console.log(response,'this is the notification response');
        return response

      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  )

export const getNotificationById = createAsyncThunk(
    "notification/getById",
    async (data: any, thunkAPI: any) => {
      try {

        const response = await notificationService.getNotificationById(data);
        console.log(response,'this is the notification response');
        return response

      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  )

export const changeStatus = createAsyncThunk(
    "notification/change",
    async (data: any, thunkAPI: any) => {
      try {

        const response = await notificationService.changeStatus(data);
        console.log(response,'this is the notification response');
        return response

      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  )

export const requestCount = createAsyncThunk(
    "notification/count",
    async (data: any, thunkAPI: any) => {
      try {

        const response = await notificationService.requestCount(data);
        console.log(response,'this is the req count');
        return response

      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  )
export const readRequest = createAsyncThunk(
    "notification/read",
    async (data: any, thunkAPI: any) => {
      try {

        const response = await notificationService.readRequest(data);
        console.log(response,'this is the read req');
        return response

      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  )

  const initialState = {
    data: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  };

  export const notificationSlice = createSlice({
    name: "preference",
    initialState: initialState,
    reducers: {
      resetState: (state) => initialState,
    },
    extraReducers: (builder) => {
      builder
        .addCase(notification.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(notification.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.data = action.payload.data;
          console.log(state.data, ' this is notification slice')
        })
        .addCase(notification.rejected, (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = action.error.message || "";
        })
        .addCase(changeStatus.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(changeStatus.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.data = action.payload.data;
          console.log(state.data, ' this is notification slice')
        })
        .addCase(changeStatus.rejected, (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = action.error.message || "";
        })
        .addCase(getNotificationById.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getNotificationById.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.data = action.payload.data;
          console.log(state.data, ' this is notification slice')
        })
        .addCase(getNotificationById.rejected, (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = action.error.message || "";
        })
        .addCase(requestCount.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(requestCount.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.data = action.payload.data;
          console.log(state.data, ' this is notification slice')
        })
        .addCase(requestCount.rejected, (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = action.error.message || "";
        })
        .addCase(readRequest.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(readRequest.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.data = action.payload.data;
          console.log(state.data, ' this is notification slice')
        })
        .addCase(readRequest.rejected, (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = action.error.message || "";
        })
    },
  });
  
  export const { resetState } = notificationSlice.actions;
  export default notificationSlice.reducer;