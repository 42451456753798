import personal from "../../assets/icons/person.png";
import person from "../../assets/icons/person-white.png";
import updateicon from "../../assets/icons/reset-password.png";
import logoutIcon from "../../assets/icons/logout.svg";
import { Box, Button, Container, Tab, Tabs, Typography } from "@mui/material";
import React, { useState ,useEffect } from "react";
import getInitials from "../../utils/getInitailasIMGUtils";
import GeneralDetails from "../../components/Profile/GeneralDetails";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import authService from "../../redux/auth/authService";
import { colors } from "../../constants/colors";
import UpdatePassword from "../../components/Profile/UpdatePassword";
import api from "../../config/apiConfig";
import Header from "../../components/Header/Header";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface user {
  id:string;
  firstName: string;
  lastName: string;
  email:string;
  mobile:string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const imageMap: any = {
  "General Details": personal,
  // 'Logout': login,
  "Update Password": updateicon,
};

const imageMapSelected: any = {
  "General Details": person, // New icon when clicked
  "Update Password": updateicon, // Can be the same or change
};

function Profile() {
  const [value, setValue] = useState(0);
  const [user, setUser] = useState<user>();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const fetchDetails = async () => {
    try {
      const accessToken = localStorage?.getItem("accessToken");
      const response = await api.post(
        "user/getById",
        {},
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
      setUser(response?.data?.data[0]);
      console.log("name", response?.data?.data[0]);
    } catch (error) {
      console.error("Failed to fetch user Details", error);
    }
  };
  useEffect(() => {
    fetchDetails();
  }, [])
  

  const logout = () => {
    authService.logout();
    navigate("/");
  };

  return (
    <>
    <Header/>
    <div className="">
    <ArrowBackIcon  sx={{marginBottom:"5px",fontSize:"40px",marginLeft:"3rem",cursor:"pointer"}} onClick={()=>navigate(-1)}/>
      <div className="px-8 mt-[-30px] md:p-[60px] md:max-w-[1640px] w-full mx-auto flex flex-col md:flex-col gap-x-6">
        <div className="flex flex-row">
          <div className="w-1/4 min-h-screen">
            <div className="border border-lightGrey rounded-lg mr-4 h-full flex flex-col justify-between">
              <div>
                <div className="p-8  bg-lightBg rounded-lg">
                  <div className="flex flex-row items-center justify-start gap-x-4">
                    <div className="w-14 h-14 mb-3 md:mb-0 md:w-20 md:h-20 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-4xl font-bold">
                      {getInitials(user?.firstName || "")}
                    </div>
                    <div className="flex flex-col">
                      <span className="text-2xl text-darkGrey font-bold">
                        {`${
                          user?.firstName?.toUpperCase()
                        } ${
                          user?.lastName?.toUpperCase()
                        }`}
                      </span>
                    </div>
                  </div>
                </div>

                {/* <div className='flex  flex-col items-center justify-around'>
                                {['General Details', 'Bank Details', 'Demat Details', 'Nominees', 'Compliance', 'Access Control', 'Preferences', 'Reach out', 'Logout'].map((label, index) => (
                                    <Button key={index} onClick={() => setValue(index)} {...a11yProps(index)}
                                        sx={{
                                            width: '85%',
                                            height: '50px',  // Set a consistent height for each button
                                            borderBottom: '1px solid #ccc',  // Apply a bottom border for separation
                                            justifyContent: 'flex-start',  // Align content to the left
                                            textTransform: 'none',  // Keep the text case as is
                                            padding: '0 16px',
                                            margin: '10px 0px',  // Add padding for better spacing
                                        }}
                                    >
                                        <Box display="flex" alignItems="center" width="100%">
                                            <img src={imageMap[label]} alt={label} style={{ marginRight: '8px' }} />
                                            <span>{label}</span>
                                        </Box>
                                    </Button>
                                ))}
                            </div> */}

                <div className="flex flex-col justify-between">
                  <div className="flex flex-col w-full items-center justify-around ">
                    {[
                      "General Details",
                      "Update Password",
                    ].map((label, index) => (
                      <Button
                        key={index}
                        onClick={() => setValue(index)}
                        {...a11yProps(index)}
                        sx={{
                          width: "85%",
                          height: "50px",
                          justifyContent: "flex-start",
                          textTransform: "none",
                          padding: "0 16px",
                          margin: "10px 0px",
                          backgroundColor:
                            value === index ? colors.primary : "transparent",
                          color: value === index ? "#fff" : "initial", // change to white text if selected
                          "&:hover": {
                            backgroundColor:
                              value === index
                                ? colors.primary
                                : "rgba(0, 0, 0, 0.04)", // adjust hover effect
                          },
                        }}
                      >
                        <Box display="flex" alignItems="center" width="100%">
                          <img
                            src={
                              value === index
                                ? imageMapSelected[label]
                                : imageMap[label]
                            }
                            alt={label}
                            style={{ marginRight: "8px", maxWidth: "30px" }}
                          />
                          <span>{label}</span>
                        </Box>
                      </Button>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex flex-col mb-[40%] mx-6 gap-y-4">
                <div
                  className="flex items-center gap-x-2 cursor-pointer"
                  style={{ padding: "0 16px", margin: "10px 0px" }}
                >
                  <span>
                    <img src={logoutIcon} alt="Logout" />
                  </span>
                  <span className="text-secondary text-xl" onClick={logout}>
                    Logout
                  </span>
                </div>
                {/* <Typography
                                    variant="subtitle2"
                                    color={colors.lightGrey}
                                    style={{ fontStyle: 'italic' }}
                                >
                                    Last logged out on 10 May 2024 at 12:24 PM
                                </Typography> */}
              </div>
            </div>
          </div>
          <div className="w-3/4 ml-4 max-h-screen overflow-y-auto">
          {value === 0 && user && <GeneralDetails user={user} />}
            {value === 1 && <UpdatePassword />}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Profile;
