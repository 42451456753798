import api from "../../config/apiConfig";
import { UserId } from "../../models/userId.model";


const getHolding = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("user/getUserHoldings", data, {
        headers: {
            Authorization: accessToken, // Ensure the token is formatted correctly
        },
    });
    return response.data;  
}
const editHolding = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("user/editHoldings", data, {
        headers: {
            Authorization: accessToken, // Ensure the token is formatted correctly
        },
    });
    return response.data;  
}
const getSchemes = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("admin/getSchemeName", data, {
        headers: {
            Authorization: accessToken, // Ensure the token is formatted correctly
        },
    });
    return response.data;  
}
const addHolding = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("user/uploadHoldings", data, {
        headers: {
            Authorization: accessToken, // Ensure the token is formatted correctly
        },
    });
    return response.data;  
}
const WithdrawRequest = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("admin/invest/withdraw", data, {
        headers: {
            Authorization: accessToken, // Ensure the token is formatted correctly
        },
    });
    return response.data;  
}
const holdingService = { getHolding,editHolding,getSchemes,addHolding,WithdrawRequest };
export default holdingService;
