import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  FormControl,
  FormLabel,
  Select,
  InputLabel,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  addHolding,
  editUserHolding,
  getAIFSchemes,
  withdrawRequest,
} from "../../redux/holdings/holdingSlice";
import toast from "react-hot-toast";
import { SelectChangeEvent } from "@mui/material/Select";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { changeStatus } from "../../redux/portfolio/changeStatus/statusSlice";
import AppLoader from "../AppLoader/AppLoader";
import { changeFdStatus, changeFdWithdrawStatus, getFdScheme, getFdSchemeByID } from "../../redux/fd/fdSlice";
import { colors } from "../../constants/colors";
import convertMoneyStringToNumber from "../../utils/convertMoneyStringToNumber";
import TimeStamptoExp from "../../utils/timestampConverter";

interface InterestRates {
  monthly: string;
  quaterly: string;
  halfYearly: string;
  annual: string;
  cumulativeDeposit: string;
  annualisedYield: string;
}

interface SpecialInterestRates {
  monthly: string;
  quaterly: string;
  halfYearly: string;
  annual: string;
  cumulativeDeposit: string;
  annualisedYield: string;
}

interface Calculation {
  schemeId: number;
  investTenure: any;
  investAmount: number;
  interestFrequency: string;
  investmentPayoutTerm: string;
  investmentSpecialBenefits: string;
  maturityAmount?: number; // Change any to number
  return?: string;
  interestRate?: any; // Keep it optional
  grossInterest: any; // Consider specifying the type
  maturityDate: number;
}

interface FD {
  fdId: number;
  id: number;
  noteficationId: number;
  schemeName: string;
  providerName: string;
  category: string;
  amount: number;
  fdStatus: number;
  transactionDate: string;
  investmentFrequency: string;
  interesetRate: number;
  grossInterest: number;
  transactions: [];
  endDate: number;
  investmentTenure: number;
  returnAmount: number;
  createdAt: string;
  type: string;
  daysLeft: number;
  userId: number;
  startDate: number;
  name: string;
  firstName: string;
  folioNo: string;
}

interface TenureState {
  minTenure: number | null;
  maxTenure: number | null;
  id: number;
}

interface EditModalForHoldingsProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: FD) => void;
  rowData: FD;
  filteredData: FD[];
  mode: "edit" | "add" | "plus";
  fetchData: () => void;
}

const formatDateString = (dateString: string) => {
  if (dateString.includes("/")) {
    const [day, month, year] = dateString.split("/"); // Convert MM/DD/YYYY format to DD-MM-YYYY
    return `${year}-${month}-${day}`;
  }

  // Check if the date is already in DD-MM-YYYY format
  if (dateString.includes("-")) {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`; // Keep as DD-MM-YYYY
  }
  return dateString;
};

const formatTransactionDate = (date:string) => {
  if (date) {
    // Split the date by '/' (assuming format is DD/MM/YYYY)
    const [day, month, year] = date.split('/');

    // Rearrange to 'YYYY-MM-DD' and return
    return `${year}-${month}-${day}`;
  }
  return '';
};

const EditModalForHoldings: React.FC<EditModalForHoldingsProps> = ({
  open,
  onClose,
  onSubmit,
  filteredData,
  rowData,
  mode,
  fetchData,
}) => {
  const [formData, setFormData] = useState<FD>(rowData);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [schemes, setSchemes] = useState<{ id: number; schemeName: string }[]>(
    []
  );
  const { id } = useParams();
  const [selectedSchemeId, setSelectedSchemeId] = useState<number | null>(null);
  const [selectedSchemeName, setSelectedSchemeName] = useState<string>("");
  const dispatch = useDispatch<any>();
  const todayDate = dayjs().format("YYYY-MM-DD");
  const [Error, setError] = useState<string>("");
  const [MaturityDate, setMaturityDate] = useState<string>("");
  const [Return, setReturn] = useState<string>("");
  const [fdData, setFDData] = useState<any>(null);
  const [minInvestment, setMinInvestment] = useState(0);
  const [tenureState, setTenureState] = useState<TenureState>({
    minTenure: null,
    maxTenure: null,
    id: 0,
  });

  const [calculation, setCalculation] = useState<Calculation>({
    schemeId: 0,
    investAmount: Number.isNaN(minInvestment) ? 0 : minInvestment,
    investTenure: rowData.investmentTenure,
    interestRate: "", // Initialize as undefined to match the interface
    interestFrequency: rowData?.investmentFrequency?.toUpperCase(),
    grossInterest: 0,
    maturityAmount: 0,
    maturityDate: 0,
    return: "",
    investmentPayoutTerm: rowData?.investmentFrequency?.toLowerCase(),
    investmentSpecialBenefits: "No",
  });
  const [finalRes, setFinalRes] = useState<any>({});

  const [loading, setLoading] = useState(false);

  const [MatchTenure, setMatchTenure] = useState<any>();

  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to manage button disable

  const [availableOptions, setAvailableOptions] = useState<any>([]);

  const [activeSpan, setActiveSpan] = useState<number | null>(null);

  let updatedVal:any;

  useEffect(() => {
    setSelectedSchemeName(rowData?.schemeName);
    if(!selectedSchemeName){
      setFDData(null);
    }
    // Check if formData.investmentTenure matches any card's tenure
    console.log(formData?.investmentTenure,formData?.investmentFrequency,rowData,"Tenure");
    setCalculation({
    schemeId: 0,
    investAmount: Number.isNaN(minInvestment) ? 0 : minInvestment,
    investTenure: rowData.investmentTenure,
    interestRate: "", // Initialize as undefined to match the interface
    interestFrequency: rowData?.investmentFrequency?.toUpperCase(),
    grossInterest: 0,
    maturityAmount: 0,
    maturityDate: 0,
    return: "",
    investmentPayoutTerm: rowData?.investmentFrequency?.toLowerCase(),
    investmentSpecialBenefits: "No",
    });
    if (fdData?.tenures?.length>0 && formData?.investmentTenure) {
      fdData?.tenures.forEach((item: any) => {
        const minTenureDisplay = item.minTenure;
        const maxTenureDisplay = item.maxTenure;

        // If formData.investmentTenure matches either minTenure or maxTenure, select the card
        if (
          formData.investmentTenure >= minTenureDisplay ||
          formData.investmentTenure >= maxTenureDisplay 
        ) {
          console.log(fdData,item.id,"Item ID");
          setActiveSpan(item.id);

          let interestRateBYFDData;

          const getInterestRateById = (id:any) => {
            const foundItem = fdData?.tenures?.find((item:any )=> item.id === id);
            if (foundItem) {
               interestRateBYFDData = {
                interestRate: foundItem.interestRate,
                seniorCitizen: foundItem.seniorInterestRate,
              };
              return interestRateBYFDData;
            } else {
              console.error(`Item with id ${id} not found`);
              return null;
            }
          };
          getInterestRateById(item.id);
          tenureIDFinder(
            item.id,
            minTenureDisplay,
            maxTenureDisplay,
            interestRateBYFDData
          );
          updatedVal= CalculateFn();
        }
      });
    }
  }, [fdData,formData.investmentTenure]);

  useEffect(() => {
    setFDData(null);
    const fetchData = async () => {
        try {
          const responseById = await dispatch(
            getFdSchemeByID({
              schemeId: rowData?.fdId, // Use the selected schemeId or default
            })
          );
          
          // Set FD data
          if(responseById?.payload?.data?.scheme){
            setFDData(responseById?.payload?.data?.scheme);
            // console.log(
            //   responseById?.payload?.data?.scheme,
            //   "responseById?.payload?.data?.scheme"
            // );
          }
        } catch (error) {
          console.error("Error fetching scheme by ID:", error);
        }
      }   
    fetchData();
  }, [rowData?.fdId,mode, dispatch]);

  useEffect(() => {
    // Enable the "Add" button only if all fields are filled
    const isAllFieldsFilled =
      formData.folioNo;

    setIsButtonDisabled(!isAllFieldsFilled); // Disable button if any field is missing
  }, [formData.folioNo]);

  useEffect(() => {
    if (
      mode === "plus" ||
      rowData?.type === "Buy" ||
      rowData?.type === "Sell"
    ) {
      handleSelectChange(rowData?.schemeName);
    }
    setFormData(rowData);
  }, [rowData]);

  const CalculateFn = () => {
    // if (
    //   !formData.investTenure ||
    //   !formData.investmentPayoutTerm ||
    //   !finalRes.interestRates
    // ) {
    //   toast.error("Please fill all fields");
    // }

    if (minInvestment && calculation.investAmount < minInvestment) {
      toast.error(
        `Invested amount should be greater than or equal ${minInvestment} to the minimum investment amount.`
      );
      return;
    }

    const { interestRates } = finalRes;
    const { investmentPayoutTerm, investmentSpecialBenefits } = calculation;
    console.log('interestRates', interestRates,investmentPayoutTerm, investmentSpecialBenefits,calculation);

    if (calculation.investmentPayoutTerm) {
      if (calculation.investmentSpecialBenefits) {
        const getRate = () => {
          if (!investmentPayoutTerm || !interestRates) return undefined;

          switch (investmentSpecialBenefits) {
            case "seniorCitizen":
              return interestRates.seniorCitizen[investmentPayoutTerm];
            case "womenInterestRates":
              return interestRates.womenInterestRates[investmentPayoutTerm];
            default:
              return interestRates.interestRate[investmentPayoutTerm];
          }
        };

        const rate = getRate();
        if (rate !== undefined) {
          const parsedRate = parseFloat(rate);
          // calculateReturn(parsedRate);
          let updatedVal=calculateReturn(parsedRate);
          // setCalculation((prevCalculation) => ({
          //   ...prevCalculation,
          //   interestRate: parsedRate,
          //   maturityDate:
          // }));
          return updatedVal;

          console.log("result", calculation);
        } else {
          // console.log('Interest rate not found for the selected term.');
        }
      }
    }
  };

  const calculateReturn = (rate: any) => {
    const P = formData?.amount;
    const R = rate / 100;
    const T =rowData.investmentTenure?rowData.investmentTenure:calculation.investTenure; // Convert investment period from months to years

    console.log(P,R,T,"CalculationFN()");

    let SI: any;
    let maturityAmount: any;
    let earnings: any;
    let tenure = 12;
    if (fdData.schemeName) {
      tenure = fdData.schemeName.includes("HDFC") ? 365 : 12;
      console.log(`now tenure will be ${tenure}`);
    }
    const tenure_compound = rowData.investmentTenure?rowData.investmentTenure: calculation.investTenure / tenure;

    switch (calculation.investmentPayoutTerm) {
      case "monthly":
        SI = (P * R * 1) / tenure;
        maturityAmount = P + SI * T;
        earnings = SI * T;
        break;
      case "quaterly":
        SI = (P * R * 1) / tenure;
        maturityAmount = P + SI * T;
        earnings = SI * T;
        break;
      case "halfyearly":
        SI = (P * R * 1) / tenure;
        maturityAmount = P + SI * T;
        earnings = SI * T;
        break;
      case "yearly":
        SI = (P * R * 1) / tenure;
        maturityAmount = P + SI * T;
        earnings = SI * T;
        break;
      case "cumulativeDeposit":
        maturityAmount = P * Math.pow(1 + R, tenure_compound);
        SI = maturityAmount - P;
        earnings = maturityAmount - P;
        break;
      default:
        SI = 0;
        maturityAmount = P;
        break;
    }

// Function to convert "DD-MM-YYYY" string into a Date object
const parseDate = (dateString: string) => {
  const [day, month, year] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day); // month is 0-indexed in JavaScript
};

// Example start date as "20-11-2024"
console.log(rowData.transactionDate,"transactionDate");
const startDateString =rowData.transactionDate?rowData.transactionDate.replace(/\//g, "-"):formData.transactionDate;
const startDate = parseDate(startDateString);

let maturityDate: number;

if (fdData.schemeName && fdData.schemeName.includes("HDFC")) {
  // Add days to the start date for HDFC schemes
  if(rowData.investmentTenure){
    console.log(rowData.investmentTenure,"investmentTenure");
    maturityDate = new Date(
      startDate.setDate(startDate.getDate() + rowData.investmentTenure)
    ).getTime();
  }else{
  maturityDate = new Date(
    startDate.setDate(startDate.getDate() +calculation.investTenure)
  ).getTime();}
} else {
  // Add months to the start date for other schemes
  if(rowData.investmentTenure){
    console.log(rowData.investmentTenure,"investmentTenure");
    maturityDate = new Date(
      startDate.setMonth(startDate.getMonth() + rowData.investmentTenure)
    ).getTime();
  }
  else{
  maturityDate = new Date(
    startDate.setMonth(startDate.getMonth() + calculation.investTenure)
  ).getTime();}
}

    console.log("maturityDate", maturityDate);
    console.log("earnings", earnings.toString());
    // setMaturityDate(maturityDate.toString());
    // setReturn(earnings.toString());
    // setCalculation((prevCalculation) => ({
    //   ...prevCalculation,
    //   grossInterest: SI.toFixed(2),
    //   maturityDate: maturityDate, // Store timestamp
    //   return: earnings?.toString(),
    // }));
    return {maturityDate,earnings};
  };
  

  const handleInvestmentChange = (amount: number) => {
    setCalculation({ ...calculation, investAmount: amount });
    // debouncedHandleInvestmentChange(amount);
  };

  const getCurrentAmountByschemeName = (schemeName: string) => {
    if (!filteredData || filteredData.length === 0) {
      return 0; // Return a default value if filteredData is undefined or empty
    }
    const foundHolding = filteredData.find(
      (data) => data.schemeName === schemeName
    );
    return foundHolding ? foundHolding.amount : 0; // Return 0 or any default value if not found
  };

  const getTransactionIDByschemeName = (schemeName: string) => {
    if (!filteredData || filteredData.length === 0) {
      return ""; // Return an empty string if filteredData is undefined or empty
    }
    const transactionIds = filteredData
      .find((data) => data.schemeName === schemeName)
      ?.transactions?.map((trans: any) => trans.id)
      .join(",");
    return transactionIds ? transactionIds : ""; // Return 0 or any default value if not found
  };

  // Usage example
  const currentAmount = getCurrentAmountByschemeName(formData.schemeName);
  const TransID = getTransactionIDByschemeName(formData.schemeName);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = e.target;
    // If the field is for the scheme dropdown, call handleSelectChange
    if (name === "schemeName") {
      setSearchTerm(value);
      handleSelectChange(value); // Call the scheme-specific function
    }

    if (name === "transactionDate") {
      validateDate(value);
    }

    // Type check for HTMLSelectElement
    if (e.target instanceof HTMLSelectElement) {
      handleSelectChange(value);
    } else {
      // Handle HTMLInputElement
      setFormData((prevData) => ({
        ...prevData,
        [name]: name === "folioNo" ? value : "",
        [name]: name === "amount" ? Number(value) : value,
        [name]: name === "transactionDate" ? formatDateString(value) : value,
      }));
    }
  };

  // Date validation function
  const validateDate = (inputDate: string) => {
    const today = new Date();
    const input = new Date(inputDate);
    const regex = /^\d{4}-\d{2}-\d{2}$/; // Date in YYYY-MM-DD format

    if (!regex.test(inputDate)) {
      setError("Invalid date format. Use DD-MM-YYYY.");
    } else if (input > today) {
      setError("Date cannot be in the future.");
    } else {
      setError("");
    }
  };

  const readOnlyStyle = {
    backgroundColor: "#f5f5f5", // Light gray background
    color: "gray", // Gray text color
  };
  // Fetch schemes based on user input
  const handleSelectChange = async (value: string) => {
    setSearchTerm(value); // Update the search input value

    try {
      const response = await dispatch(
        getFdScheme({
          search: value, // Use search term for real-time filtering
        })
      );
      // Filter out schemes where schemesName is "-" or ""
      const filteredSchemes = response?.payload?.data?.filter(
        (scheme: { schemeName: string }) =>
          scheme.schemeName !== "-" && scheme.schemeName !== ""
      );

      // Set the schemes from the filtered response payload
      if (mode === "plus") {
        setSelectedSchemeId(filteredSchemes?.[0]?.id);
      } else {
        setSchemes(filteredSchemes || []);
      }
      // console.log(filteredSchemes, "filteredSchemes");
    } catch (error) {
      console.error("Error fetching schemes:", error);
      toast.error("An error occurred while fetching schemes.");
    }
  };  

  const handleSchemeSelect = async (schemeId: number, schemeName: string) => {
    try {
      const response = await dispatch(
        // Assuming your API action is here
        getFdSchemeByID({
          schemeId: schemeId,
        })
      );
      setFDData(response?.payload?.data?.scheme);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
    // Set the selected scheme ID and update searchTerm with the selected name
    setSelectedSchemeId(schemeId);
    setSearchTerm(schemeName);
    setSelectedSchemeName(schemeName);
    setSchemes([]);
  };

  const handleClose = () => {
    setFDData(null);
    setSearchTerm("");
    setSchemes([]);
    setSelectedSchemeId(null);
    setSelectedSchemeName("");
    onClose();
  };
  
  const handleRedeem = async () => {
    try {
await dispatch(changeFdWithdrawStatus({ id: rowData?.noteficationId, status: 1 })).then(() => {
        setLoading(true);
      toast.success("Requested Amount redeemed successfully.");
      setTimeout(() => {
        setLoading(false);
        fetchData();
      }, 5000);
      })
      .catch((error: any) => {
        console.error(`Error changing status for ID: ${id}`, error);
        toast.success("Requested for redeem amount failed.Please try after sometime.");
      });
      handleClose();
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("An error occurred while modifying the AIF.");
    }
  };
  const getAvailableInterestRateOptions = (
    finalRes: any,
    specialBenefit: keyof any
  ) => {
    if (!finalRes || !finalRes.interestRates) {
      return [];
    }

    const interestRates = finalRes.interestRates;
    let selectedRates: Record<string, string> | undefined; // Define type based on your data structure

    if (specialBenefit === "No") {
      selectedRates = interestRates.interestRate;
    } else {
      selectedRates = interestRates[specialBenefit];
    }

    if (!selectedRates || Object.keys(selectedRates).length === 0) {
      selectedRates = interestRates.interestRates;
    }

    if (!selectedRates) {
      return [];
    }

    const options = [
      { value: "monthly", label: "Monthly" },
      { value: "quaterly", label: "Quarterly" },
      { value: "halfyearly", label: "Half Yearly" },
      { value: "yearly", label: "Annually" },
      { value: "cumulativeDeposit", label: "Cumulative" },
    ];

    // Filter options based on available interest rates in selectedRates
    return options.filter(
      (option) =>
        selectedRates &&
        selectedRates[option.value] !== undefined &&
        selectedRates[option.value] !== "" &&
        selectedRates[option.value] !== "-"
    );
  };

  const tenureIDFinder = async (
    key: number,
    minTenure: number,
    maxTenure: number,
    interestRate: any
  ) => {
    // console.log(key,minTenure,maxTenure,interestRate,"TenureFinder");
    setTenureState({
      ...tenureState,
      minTenure: minTenure,
      maxTenure: maxTenure,
      id: key,
    });
    if (key && key !== 0) {
      setMatchTenure({
        ...MatchTenure,
        id: key,
        minTenure: minTenure,
        maxTenure: maxTenure,
      });
      setCalculation({ ...calculation, schemeId: fdData?.schemeID });
      setFinalRes({ ...finalRes, interestRates: interestRate });

      // console.log('finalRes interest rate', finalRes);
      if (finalRes && interestRate) {
        const options = getAvailableInterestRateOptions(
          { ...finalRes, interestRates: interestRate },
          calculation.investmentSpecialBenefits
        );
        setAvailableOptions(options);
        console.log(options,"Options");
      }
    }
    setActiveSpan(key);
  };
  const handleSpecialOption = (e: any) => {
    const specialBenefit = e.target.value as keyof SpecialInterestRates;
    setCalculation({
      ...calculation,
      investmentSpecialBenefits: specialBenefit,
    });

    // Recalculate available options based on the selected special benefit
    const options = getAvailableInterestRateOptions(finalRes, specialBenefit);
    setAvailableOptions(options);
  };

  const handlePayoutTerm = (e: any) => {
    const investmentPayoutTerm = e.target.value as keyof InterestRates;

    setCalculation((prevCalculation) => ({
      ...prevCalculation,
      investmentPayoutTerm,
      interestFrequency: investmentPayoutTerm?.toUpperCase(),
    }));

    const newMinInvestment =
      fdData?.FDInfo?.minInvestmentAmount[investmentPayoutTerm];
    // console.log('current value', newMinInvestment);

    if (typeof newMinInvestment === "string") {
      try {
        const convertedValue = convertMoneyStringToNumber(newMinInvestment);
        // console.log('converted value', convertedValue);
        setMinInvestment(convertedValue);
      } catch (error) {
        console.error("Error converting money string:", error);
        setMinInvestment(0); // Default to 0 or handle the error case appropriately
      }
    } else {
      setMinInvestment(newMinInvestment || 0); // Ensure it defaults to 0 if undefined or null
    }
  };

  const max = Number(tenureState.maxTenure);
  const min = Number(tenureState.minTenure);

  const convertDaysToReadableFormat2 = (days: number): string => {
    if(days===0||days===null)return "0";
    if (fdData?.FDInfo?.schemeName && fdData?.FDInfo?.schemeName?.includes("HDFC")) {
      if (days <= 90) {
        return `${days} day${days > 1 ? "s" : ""}`;
      }
    } else {
      return days<=1?days +" month":days+" months" ;// Dynamic value for 12 months, approximated as 12 * 30 = 360 days
    }
    const today = new Date();
    let currentYear = today.getFullYear();
    let currentMonth = today.getMonth(); // Month is 0-based (0 = January, 11 = December)
    let currentDay = today.getDate();

    // Helper function to get the number of days in a given month and year
    const daysInMonth = (month: number, year: number): number => {
      return new Date(year, month + 1, 0).getDate();
    };

    // Determine the month length to use based on the current day
    let monthLength = daysInMonth(currentMonth, currentYear);
    if (currentDay > 20) {
      currentMonth += 1;
      if (currentMonth > 11) {
        currentMonth = 0;
        currentYear += 1;
      }
      monthLength = daysInMonth(currentMonth, currentYear);
    }

    let totalMonths = 0;
    let remainingDays = days;
    let futureDate = new Date(today);

    // Calculate total months and remaining days
    while (remainingDays >= monthLength) {
      totalMonths += 1;
      remainingDays -= monthLength;
      currentMonth += 1;
      if (currentMonth > 11) {
        currentMonth = 0;
        currentYear += 1;
      }
      monthLength = daysInMonth(currentMonth, currentYear);
    }

    // Calculate the exact future date
    futureDate.setMonth(today.getMonth() + totalMonths);
    futureDate.setDate(today.getDate() + remainingDays);

    // Format the readable string
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
    let readableFormat = "";
    if (years > 0) readableFormat += `${years} yr${years > 1 ? "s" : ""} `;
    if (months > 0) readableFormat += `${months} mon `;
    if (remainingDays > 0) readableFormat += `${remainingDays} day${remainingDays > 1 ? "s" : ""} `;

    return readableFormat.trim();
  };
  useEffect(() => {
    setCalculation({ ...calculation, investTenure: tenureState.minTenure });
  }, [tenureState]);


  const handleSubmit = async () => {
    updatedVal= CalculateFn();  
    if(!updatedVal){
      return console.log("calculation not done.");
    }  
    // let bodyData={
    //   notificationId:rowData.noteficationId?rowData.noteficationId:0,
    //   holdingType: 3,
    //     userId: id,
    //     schemeId:selectedSchemeId,
    //     folioNo: formData?.folioNo,
    //     scheme: fdData?.schemeName,
    //     purchaseDate: formData?.transactionDate?.replace(/-/g, "/"),
    //     amount: formData?.amount,
    //     tenure: rowData.investmentTenure?rowData.investmentTenure:calculation.investTenure,
    //     maturityDate:TimeStamptoExp.formatDateFromTimestamp(
    //       Number(updatedVal?.maturityDate)
    //     ),
    //     interest: updatedVal?.earnings,
    //     currentInterest: 0,
    //     option: calculation.investmentPayoutTerm,
    //     roi: Number(fdData.maxInterestRate)?.toFixed(2),
      
    // }
    // console.log(bodyData,"bodyData");
    if (!Error) {
      try {
        const response = await dispatch(
          // Assuming your API action is here
          addHolding({
            notificationId:rowData.noteficationId?rowData.noteficationId:0,
            holdingType: 3,
            userId: id,
            schemeId:selectedSchemeId,
            folioNo: formData?.folioNo,
            scheme: fdData?.schemeName.split(" - ")[0],
            purchaseDate: formData?.transactionDate?.replace(/-/g, "/"),
            amount: formData?.amount,
            tenure: rowData.investmentTenure?rowData.investmentTenure:calculation.investTenure,
            maturityDate:TimeStamptoExp.formatDateFromTimestamp(
              Number(updatedVal?.maturityDate)
            ),
            interest: updatedVal.earnings,
            currentInterest: 0,
            option: calculation.investmentPayoutTerm,
            roi: Number(fdData.maxInterestRate)?.toFixed(2),
          })
        );

        if (response?.payload) {
          setLoading(true);
          toast.success("New FD report added successfully.");
          if(rowData?.type==="Buy"){
            dispatch(changeFdStatus({ id: rowData?.noteficationId, status: 1,userId:id }))
                .then(() => {
                  console.log("Accepted.")
                })
                .catch((error: any) => {
                  console.error(`Error changing status for ID: ${id}`, error);
                });
          }
          setTimeout(() => {
            setLoading(false);
            fetchData();
          }, 5000);
          handleClose();
        } else {
          toast.error("Failed to Add FD.");
        }
      } catch (error) { 
        console.error("Error submitting data:", error);
        toast.error("An error occurred while adding the new FD request.");
      }
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800, // Adjust width as needed
            maxHeight: "80vh", // Limit the height of the modal
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            overflowY: "auto", // Make the content scrollable on the Y-axis
            borderRadius: 2,
          }}
        >
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="h6" component="h2">
              {mode === "edit"
                ? "Edit Transaction Record"
                : "Add Transaction Record"}
            </Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <Box component="form" noValidate autoComplete="off">
            {mode === "add" || (mode === "plus" && rowData?.type !== "Sell") ? (
              <FormControl fullWidth margin="normal">
                <TextField
                  label={
                    mode === "add" || mode === "plus"
                      ? "Search Schemes *"
                      : "Scheme Name *"
                  }
                  variant="outlined"
                  name="schemeName"
                  value={searchTerm || formData?.schemeName || ""}
                  onChange={(e) => handleSelectChange(e.target.value)}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    endAdornment: mode === "add" && (
                      <InputAdornment position="end">
                        <IconButton>
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* Display the filtered schemes */}
                {schemes?.map((scheme, index) => (
                  <MenuItem
                    key={index}
                    value={scheme?.schemeName}
                    onClick={() =>
                      handleSchemeSelect(scheme?.id, scheme?.schemeName)
                    } // Set ID and Name
                  >
                    {scheme?.schemeName}
                  </MenuItem>
                ))}
              </FormControl>
            ) : (
              <TextField
                label="Scheme Name *"
                variant="outlined"
                fullWidth
                margin="normal"
                name="schemeName"
                type="text"
                InputLabelProps={{ shrink: true }}
                value={formData?.schemeName || ""}
                InputProps={{
                  readOnly: rowData?.type === "Sell",
                  sx:
                     rowData?.type === "Sell"
                      ? readOnlyStyle
                      : undefined,
                }}
              />
            )}
            {rowData?.type !== "Sell" && (
              <TextField
                label="Transaction Date"
                variant="outlined"
                fullWidth
                margin="normal"
                name="transactionDate"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={formData?.transactionDate 
                  ? formatDateString(formData?.transactionDate)
                  : ""
                }
                onChange={(e) =>
                  handleChange(e as React.ChangeEvent<HTMLInputElement>)
                }
                inputProps={{ max: todayDate }} // Restrict future dates
                error={!!Error}
                helperText={Error} // Show error message
              />
            )}

           {rowData?.type!=="Sell" &&( <TextField
              label="Tenure *"
              className="bg-gray-200 rounded w-full"
              value={convertDaysToReadableFormat2(formData?.investmentTenure? formData.investmentTenure : calculation.investTenure )}
              variant="outlined"
              inputProps={{
                max: max,
                min: min,
                readOnly: true,
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  border: "none",
                  backgroundColor: "transparent",
                },
              }}
              sx={{
                marginTop: "15px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
              disabled={MatchTenure?.minTenure === MatchTenure?.maxTenure}
            />)}
             {rowData?.type!=="Sell" && ( 
            <div className="flex flex-wrap">
              {fdData?.tenures && fdData?.tenures?.length > 0 ? (
                fdData.tenures.map((item: any) => {
                  let minTenureDisplay: any,
                    maxTenureDisplay: any,
                    interestRateBYFDData: any;
                  minTenureDisplay = item.minTenure;
                  maxTenureDisplay = item.maxTenure;
                  interestRateBYFDData = {
                    interestRate: item.interestRate,
                    seniorCitizen: item.seniorInterestRate,
                  };
                  return (
                    <div
                      key={item.id}
                      onClick={(e) => {
                        if (
                          item &&
                          typeof item.minTenure !== "undefined" &&
                          typeof item.maxTenure !== "undefined"
                        ) {
                          tenureIDFinder(
                            item.id,
                            minTenureDisplay,
                            maxTenureDisplay,
                            interestRateBYFDData
                          );
                        } else {
                          console.error(
                            "Min or max tenure not defined for item:",
                            item
                          );
                        }
                      }}
                      className={`border border-primary flex items-center justify-center cursor-pointer p-2 m-1 rounded-2xl text-sm ${
                        activeSpan === item.id
                          ? "bg-blue-900 text-white"
                          : "bg-white text-primary"
                      }`}
                    >
                      {minTenureDisplay === maxTenureDisplay
                        ? convertDaysToReadableFormat2(minTenureDisplay)
                        : `${convertDaysToReadableFormat2(
                            minTenureDisplay
                          )} - ${convertDaysToReadableFormat2(
                            maxTenureDisplay
                          )}`}
                    </div>
                  );
                })
              ) : (
                <div className="w-full text-start text-gray-500">
                  No data.Please select a scheme.
                </div>
              )}
            </div>)}
         {rowData?.type!=="Sell" &&(<div className="flex flex-col gap-y-2 mb-3">
              <div className="flex gap-x-3 justify-between">
                <div className="w-1/2 max-w-[500px] mt-10">
                  <Typography
                    variant="subtitle2"
                    sx={{ color: colors.textGrey }}
                  >
                    Special Benefits
                  </Typography>
                  <select
                    className="border border-solid border-stone-300 rounded-md w-full py-4 px-2"
                    value={calculation.investmentSpecialBenefits}
                    onChange={(e) => handleSpecialOption(e)}
                  >
                    <option value="No">No Special Benefits</option>
                    <option value="seniorCitizen">Senior Citizen</option>
                    {/* <option value="womenInterestRates">Women</option> */}
                  </select>
                </div>

                <div className="w-1/2 max-w-[500px] mt-10">
                  <Typography
                    variant="subtitle2"
                    sx={{ color: colors.textGrey }}
                  >
                    Interest payout term
                  </Typography>
                  <select
                    className="border border-solid border-stone-300 rounded-md w-full py-4 px-2"
                    value={calculation.investmentPayoutTerm?calculation.investmentPayoutTerm:formData.investmentFrequency?.toLowerCase()}
                    onChange={(e) => handlePayoutTerm(e)}
                  >
                    <option value="">Select Payout</option>
                    {availableOptions.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>)}
            <TextField
              label="Amount *"
              variant="outlined"
              fullWidth
              margin="normal"
              name="amount"
              type="text"
              value={formData?.amount || ""}
              onChange={(e) =>
                handleChange(e as React.ChangeEvent<HTMLInputElement>)
              }
              InputProps={{
                readOnly: rowData?.type === "Sell",
                sx:
                   rowData?.type === "Sell"
                    ? readOnlyStyle
                    : undefined,
              }}
            />
            {rowData.type!=="Sell"&& (<TextField
              label="Folio Number *"
              variant="outlined"
              fullWidth
              margin="normal"
              name="folioNo"
              type="text"
              value={formData?.folioNo || ""}
              onChange={(e) =>
                handleChange(e as React.ChangeEvent<HTMLInputElement>)
              }
            />)}
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                onClick={onClose}
                color="secondary"
                variant="outlined"
                sx={{ mr: 1 }}
              >
                Cancel
              </Button>
              {rowData?.type === "Sell" ? (
                mode === "add" ||
                (mode === "plus" && (
                  <Button
                    onClick={handleRedeem}
                    color="primary"
                    variant="contained"
                    sx={{
                      mr: 1,
                    }}
                  >
                    Redeem
                  </Button>
                ))
              ):(
                <>
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    sx={{
                      mr: 1,
                    }}
                    disabled={isButtonDisabled}
                  >
                    Add
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
      <AppLoader loading={loading} />
    </>
  );
};

export default EditModalForHoldings;
