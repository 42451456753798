import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
} from '@mui/material';
import { changeMFStatus } from '../../redux/portfolio/changeStatus/statusSlice';
import { useDispatch } from 'react-redux';
import { colors } from '../../constants/colors';
import { getNotificationById } from '../../redux/notification/notificationSlice';

interface Scheme {
    id: number;
    userId: number; // Add this field
    notifyTitle: string;
    notifyMessage: string;
    isRead: number; // Add this field
    requestStatus: number;
    createdAt: string; // Add this field
}


interface StatusUpdateModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: {
        requestId: number;
        status: number;
        orderType: number;
        orderId: string;
        units?: number; // Optional field
        currentNav?: number; // Optional field
        currentValue: number;
        folioNumber: string;
    }) => void;
    scheme: Scheme | null;
}

const StatusUpdateModal: React.FC<StatusUpdateModalProps> = ({
    open,
    onClose,
    onSubmit,
    scheme,
}) => {
    const dispatch = useDispatch<any>();

    // State variables
    const [status, setStatus] = useState<number>(scheme?.requestStatus || 1);
    const [orderType, setOrderType] = useState<number>(1);
    const [orderId, setOrderId] = useState<string>('');
    const [units, setUnits] = useState<number>(0);
    const [currentValue, setCurrentValue] = useState<number>(0);
    const [currentNav, setCurrentNav] = useState<number>(0);
    const [folioNumber, setFolioNumber] = useState<string>('');
    const [selectedInvestmentData, setSelectedInvestmentData] = useState<any>(null);

    // Request ID from scheme
    const requestId = scheme?.id || 0;

    // Handle form submission
    const handleSubmit = async () => {
        const formData: any = {
            requestId,
            status,
            orderType,
            orderId,
        };

        // Include specific fields only for non-redeem order types
        if (orderType !== 5) {
            formData.currentValue = currentValue;
            formData.folioNumber = folioNumber;
            formData.units = units;
            formData.currentNav = currentNav;
        }

        try {
            const result = await dispatch(changeMFStatus(formData)).unwrap();
            console.log('API call successful. Result:', result);
            if (onSubmit) onSubmit(formData);
            onClose();
        } catch (error) {
            console.error('Failed to change status:', error);
        }
    };

    // Fetch scheme details when modal opens
    const fetchNotificationById = (id: number) => {
        dispatch(getNotificationById({ id }))
            .then((response: any) => {
                setSelectedInvestmentData(response.payload.data);
                console.log('Notification details:', response.payload.data);
            })
            .catch((error: any) => {
                console.error(`Error fetching notification for ID: ${id}`, error);
            });
    };

    useEffect(() => {
        if (open && scheme) {
            fetchNotificationById(scheme.id); // Fetch scheme details when modal opens
        }
    }, [open, scheme]);

    useEffect(() => {
        if (selectedInvestmentData) {
            setStatus(selectedInvestmentData.reqStatus || 1);
            setUnits(selectedInvestmentData.units || 0);
            setCurrentValue(selectedInvestmentData.amount || 0);
            setCurrentNav(selectedInvestmentData.currentNav || 0);
            setFolioNumber(selectedInvestmentData.folioNumber || '');
            setOrderType(selectedInvestmentData.orderType || 1);
        }
    }, [selectedInvestmentData]);

    // Auto-update units when currentValue or currentNav changes
    useEffect(() => {
        if (currentValue > 0 && currentNav > 0) {
            setUnits(currentValue / currentNav);
        }
    }, [currentValue, currentNav]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Update Status</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Order Type</InputLabel>
                    <Select
                        value={orderType}
                        onChange={(e) => setOrderType(Number(e.target.value))}
                        label="Order Type"
                    >
                        <MenuItem value={1}>Lumpsum</MenuItem>
                        <MenuItem value={2}>SIP</MenuItem>
                        <MenuItem value={3}>SWP</MenuItem>
                        <MenuItem value={4}>STP</MenuItem>
                        <MenuItem value={5}>Redeem</MenuItem>
                        <MenuItem value={6}>Switch</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Status</InputLabel>
                    <Select
                        value={status}
                        onChange={(e) => setStatus(Number(e.target.value))}
                        label="Status"
                    >
                        <MenuItem value={1}>Accept</MenuItem>
                        <MenuItem value={2}>Reject</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label="Order ID"
                    fullWidth
                    margin="normal"
                    value={orderId}
                    onChange={(e) => setOrderId(e.target.value)}
                />
                {orderType !== 5 && (
                    <>
                        <TextField
                            label="Folio Number"
                            fullWidth
                            margin="normal"
                            value={folioNumber}
                            onChange={(e) => setFolioNumber(e.target.value)}
                        />
                        <TextField
                            label="Current Value"
                            fullWidth
                            margin="normal"
                            value={currentValue}
                            onChange={(e) => setCurrentValue(Number(e.target.value))}
                        />
                        <TextField
                            label="Current NAV"
                            fullWidth
                            margin="normal"
                            value={currentNav}
                            onChange={(e) => setCurrentNav(Number(e.target.value))}
                        />
                        <TextField
                            label="Units"
                            fullWidth
                            margin="normal"
                            value={units}
                            onChange={(e) => setUnits(Number(e.target.value))}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit} variant="contained" sx={{ backgroundColor: colors.primary }}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default StatusUpdateModal;
