import axios from "axios";
import authService from "../redux/auth/authService";
// import authService from "../redux/auth/authService";
// import { auth } from "./firebaseConfig";

const api = axios.create({
  // baseURL: "http://3.7.163.191:3000/api/v1/",
   baseURL: "https://mmk7r2qidh.ap-south-1.awsapprunner.com/api/v1/"
});

let isRefreshing = false;
let failedQueue: Array<{
  resolve: (token: string) => void;
  reject: (error: any) => void;
}> = [];


// Function to process the queue of failed requests
const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else if (token) {
      prom.resolve(token);
    } else {
      prom.reject('No token available');
    }
  });
  failedQueue = [];
};

// Request interceptor to handle token expiration and refresh logic

api.interceptors.request.use(async (req) => {
  try {
    const { refreshToken, accessToken } = authService.getToken();
    if (!refreshToken) {
      if (accessToken && authService.isTokenExpired()) {
        authService.logout();
        return req;
      }
      return req;
    };
    if (req.url === "/user/refreshToken") return req;
    if (authService.isTokenExpired()) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          await authService.refreshToken();
          processQueue(null, authService.getToken().accessToken);
        } catch (error) {
          processQueue(error, null);
          authService.logout();
        } finally {
          isRefreshing = false;
        }
      }
    return new Promise((resolve, reject) => {
      failedQueue.push({
        resolve: (token: string) => {
          req.headers['Authorization'] = 'Bearer ' + token;
          resolve(req);
        },
        reject: (err: any) => {
          reject(err);
        }
      });
    });
  }
    return req;
  } catch (error) {
    authService.logout();
    return req;
  }
});

api.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  const { config, response: { status, data } } = error;
  const originalRequest = config;

  if (status === 401 && data.message === "You are forcefully logged out, Your token is not valid.") {
    console.log('====================================');
    console.log('failedQueue by reacted', failedQueue);
    console.log('====================================');
    authService.logout();
    return Promise.reject(error);
  }

  if (status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    try {
      await authService.refreshToken();
      const { accessToken } = authService.getToken();
      originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;

      // Add delay before retrying the original request
      await new Promise((resolve) => setTimeout(resolve, 2000));

      return api(originalRequest);
    } catch (err) {
      authService.logout();
      return Promise.reject(err);
    }
  }

  return Promise.reject(error);
});

export default api;
