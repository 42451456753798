import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import holdingService from "./holdingService";
import { UserId } from "../../models/userId.model";


export const getUserHolding = createAsyncThunk(
  "user/getUserHoldings",
  async (data: any, thunkAPI) => {
    try {
       const response = await holdingService.getHolding(data);
       return response.data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const editUserHolding = createAsyncThunk(
  "user/editHoldings",
  async (data: any, thunkAPI) => {
    try {
       const response = await holdingService.editHolding(data);
       return response.data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAIFSchemes = createAsyncThunk(
  "admin/getSchemeName",
  async (data: any, thunkAPI) => {
    try {
       const response = await holdingService.getSchemes(data);
       return response.data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const addHolding = createAsyncThunk(
  "user/uploadHoldings",
  async (data: any, thunkAPI) => {
    try {
       const response = await holdingService.addHolding(data);
       return response.data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const withdrawRequest = createAsyncThunk(
  "admin/invest/withdraw",
  async (data: any, thunkAPI) => {
    try {
       const response = await holdingService.WithdrawRequest(data);
       return response.data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  users: [],
  data: {},
  details:{},
  totalPages: 0,
  pageIndex: 1,
  isError: false,
  isSuccess: false,
  isLoading: false,
  isDownloading: false, // New state to manage download operation
  message: "",
};


export const holdingSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  
  extraReducers: (builder) => {
    builder
      .addCase(getUserHolding.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserHolding.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.users = action.payload.fdList; // Extract users from fdList
        state.totalPages = action.payload.totalPages;
        state.pageIndex = action.payload.pageIndex;
      })
      .addCase(getUserHolding.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(editUserHolding.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editUserHolding.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.users = action.payload.fdList; // Extract users from fdList
        state.totalPages = action.payload.totalPages;
        state.pageIndex = action.payload.pageIndex;
      })
      .addCase(editUserHolding.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(getAIFSchemes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAIFSchemes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.users = action.payload.fdList; // Extract users from fdList
        state.totalPages = action.payload.totalPages;
        state.pageIndex = action.payload.pageIndex;
      })
      .addCase(getAIFSchemes.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(addHolding.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addHolding.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.users = action.payload.fdList; // Extract users from fdList
        state.totalPages = action.payload.totalPages;
        state.pageIndex = action.payload.pageIndex;
      })
      .addCase(addHolding.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(withdrawRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(withdrawRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.users = action.payload.fdList; // Extract users from fdList
        state.totalPages = action.payload.totalPages;
        state.pageIndex = action.payload.pageIndex;
      })
      .addCase(withdrawRequest.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
    }
});

export const { resetState } = holdingSlice.actions;
export default holdingSlice.reducer;
