import React, { useState } from 'react';
import Header from '../../components/Header/Header';
import DematDetailsTable from '../../components/DematDetails.tsx/DematDetailsTable';

interface UserDetailsProps {
    id: number;
    name: string;
    email: string;
    mobile: string;
}

interface DematDetailsProps {
    id: number;
    userId: number;
    dematAccountNumber: string;
    dematBalance: number;
    dematType: string;
    dematStatus: string;
}

const DematDetails: React.FC = () => {
    const initialUserDetails: UserDetailsProps[] = [
        {
            id: 1,
            name: 'John Doe',
            email: 'john.doe@example.com',
            mobile: '123-456-7890'
        },
        {
            id: 2,
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
            mobile: '987-654-3210'
        },
        // Add more user details as needed
    ];

    const initialDematDetails: DematDetailsProps[] = [
        {
            id: 1,
            userId: 1,
            dematAccountNumber: 'DEM123456',
            dematBalance: 10000,
            dematType: 'Regular',
            dematStatus: 'Active',
        },
        {
            id: 2,
            userId: 2,
            dematAccountNumber: 'DEM987654',
            dematBalance: 5000,
            dematType: 'NRI',
            dematStatus: 'Inactive',
        },
    ];

    const [userData, setUserData] = useState<UserDetailsProps[]>(initialUserDetails);
    const [dematDetails, setDematDetails] = useState<DematDetailsProps[]>(initialDematDetails);

    const handleDematEdit = (demat: DematDetailsProps) => {
        // Logic to handle editing demat details
    };

    const mergedDematDetails = dematDetails.map(demat => ({
        ...demat,
        user: userData.find(user => user.id === demat.userId)
    }));

    return (
        <div>
            <Header />
            <DematDetailsTable
                dematDetails={mergedDematDetails}
                onEdit={handleDematEdit}
            />
        </div>
    );
};

export default DematDetails;
