import React from 'react';
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RegisterForm from '../../components/AddUserTable/AddUser';
import AddUserTable from '../../components/AddUserTable/AddUserTable';
import Header from '../../components/Header/Header';


interface User {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    dateOfBirth: number;
    gender: number;
    bseStarClientCode: string;
    address1: string;
    address2: string;
    address3: string;
    addressType: number;
    pincode: string;
    city: string;
    state: string;
    lastTransactionNo: string;
    onboardStatus: number;
    occupation: string;
    occupationType: string;
    wealthSource: string;
    incomeSlab: string;
    politicalExposed: number;
    isNRI: number;
    isKycDone: number;
    isNomineeAdd: number;
    isBankAdd: number;
    isDematAdd: number;
    isSignatureAdd: number;
    isPasswordAdd: number;
    kyc: {
        id: number;
        aadharNumber: string;
        aadharMobileNumber: string;
        aadharCard: string;
        isAadharVerified: number;
        pancardNumber: string;
        pancardMobileNumber: string;
        pancard: string;
        isPanVerified: number;
    };
    nominee: {
        id: number;
        name: string;
        mobileNumber: string;
        email: string;
        relation: number;
        dateOfBirth: number;
        percentage: string;
        nomineeAddress: string;
        pincode: string;
        isMinor: number;
        guardianDetails: string;
        aadharNumber: string;
        aadharCard: string;
        pancardNumber: string;
        pancard: string;
    }[];
    bank: {
        id: number;
        accountType: string;
        bankName: string;
        accountNumber: string;
        ifscCode: string;
        bankBranch: string;
        bankPinCode: string;
        bankMICR: string;
        bankAddress: string;
        cancelCheque: string;
    };
    demat: {
        id: number;
        dpId: string;
        clientId: string;
        depositoryName: string;
        cmlFile: string;
    };
    preferences: {
        id: number;
        mutualFunds: number;
        equity: number;
        corporateFD: number;
        bonds: number;
        aif: number;
        pms: number;
        direct: number;
        regular: number;
    };
}


  

  const AddUser = () => {
    const [user, setUser] = useState<User | null>(null); // Specify User type here
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch<any>()


    
    
    
    

    const handleSaveUser = () => {
        // Save logic here
    };

    const handleCancelEdit = () => {
        // Cancel logic here
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Handle input change
    };

    return (
      <>
          <Header/>
          <div style={{padding:'50px 100px'}}>
    
              <RegisterForm/>
          </div>
      </>
  
    );
};

export default AddUser;
