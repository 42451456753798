import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 
import Header from '../../components/Header/Header';
import { colors } from '../../constants/colors';
import { getAllUserForRM } from '../../redux/user/userSlice';
import { Typography } from '@mui/material'; 

const UploadHoldings = () => {
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState<number>(1);
  const [users, setUsers] = useState<any[]>([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await dispatch(getAllUserForRM({ pageIndex, pageSize }));

        if (response && response.payload) {
          const totalPages = response.payload.totalPages;
          const data = response.payload.data;
          if (Array.isArray(data)) {
            setUsers(data);
          } else {
            setUsers([]);
          }
          setTotalPages(totalPages);
        } else {
          setUsers([]);
        }
      } catch (error) {
        setUsers([]);
      }
    };

    fetchUsers();
  }, [dispatch, pageIndex, pageSize]);

  const handlePageChange = (newPageIndex: number) => {
    if (newPageIndex > 0 && newPageIndex <= totalPages) {
      setPageIndex(newPageIndex);
    }
  };

  const handleOpenClick = (userId: number) => {
    navigate(`/upload/preferences?userId=${userId}`);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPageIndex(1); 
  };

  return (
    <>
      <Header />
      <div className="p-4 mb-48 mx-auto" style={{ width: '90%' }}>
        <div className="flex justify-between items-center mb-20">
          <h1 className="text-2xl font-bold" style={{ color: colors.primary }}>User List</h1>
          {users.length > 0 && (
            <div className="flex items-center">
              <label className="mr-2">Page Size:</label>
              <select 
                value={pageSize} 
                onChange={(e) => handlePageSizeChange(Number(e.target.value))} 
                className="p-2 border" 
                style={{ borderColor: colors.darkGrey }}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
              </select>
            </div>
          )}
        </div>

        {/* Conditional Rendering for Users */}
        {users.length > 0 ? (
          <div className="flex flex-wrap gap-10 justify-start mb-10">
            {users.map((user: any) => (
              <div key={user.userId} className="flex flex-row items-center p-4 border border-gray-300 rounded-lg w-64">
                <div className='bg-primary rounded-full w-12 h-12 flex items-center justify-center text-white text-xl mr-4' style={{ backgroundColor: colors.primary }}>
                  {user.firstName.charAt(0)}
                </div>
                <div className="flex flex-col">
                  <span className="font-bold text-sm" style={{ color: colors.primary }}>{user.firstName}</span>
                  <span className="text-xs" style={{ color: colors.textGrey }}>{user.email}</span>
                  <span className="text-xs" style={{ color: colors.textGrey }}>Onboarding Status: {user.onboardStatus}</span>
                  <span className="text-xs" style={{ color: colors.textGrey }}>Created At: {new Date(user.createdAt).toLocaleDateString()}</span>
                  <button
                    onClick={() => handleOpenClick(user.userId)}
                    className="mt-2 w-32 px-4 py-2 bg-primary text-white rounded"
                    style={{ backgroundColor: colors.primary, color: colors.white }}
                  >
                    Open
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center mb-10" style={{ textAlign: 'center', padding: '2rem' }}>
            {/* Heading */}
            <Typography variant="h4" style={{ color: colors.primary, marginBottom: '1rem' }}>
              No Users Assigned
            </Typography>
            {/* Subheading */}
            <Typography variant="body1" style={{ color: colors.textGrey }}>
              There are currently no users assigned to this Relationship Manager.
            </Typography>
          </div>
        )}

        {/* Pagination */}
        {totalPages > 1 && (
          <div className="flex justify-center mt-24">
            <button 
              onClick={() => handlePageChange(pageIndex - 1)}
              disabled={pageIndex <= 1}
              style={{
                padding: '0.5rem 1rem',
                borderRadius: '0.375rem 0 0 0.375rem',
                backgroundColor: pageIndex <= 1 ? '#D1D5DB' : colors.primary,
                color: pageIndex <= 1 ? '#9CA3AF' : '#FFFFFF',
                cursor: pageIndex <= 1 ? 'not-allowed' : 'pointer',
                opacity: pageIndex <= 1 ? 0.5 : 1 
              }}
            >
              Previous
            </button>
            
            <span className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md">
              Page {pageIndex} of {totalPages}
            </span>
            
            <button 
              onClick={() => handlePageChange(pageIndex + 1)}
              disabled={pageIndex >= totalPages} 
              style={{
                padding: '0.5rem 1rem',
                borderRadius: '0 0.375rem 0.375rem 0',
                backgroundColor: pageIndex >= totalPages ? '#D1D5DB' : colors.primary,
                color: pageIndex >= totalPages ? '#9CA3AF' : '#FFFFFF',
                cursor: pageIndex >= totalPages ? 'not-allowed' : 'pointer',
                opacity: pageIndex >= totalPages ? 0.5 : 1 
              }}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default UploadHoldings;
