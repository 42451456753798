import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Box,
    Typography,
    InputAdornment,
    TextField,
    TablePagination,
    TableFooter,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Search } from '@mui/icons-material';

interface UserDetailsProps {
    id: number;
    name: string;
    email: string;
    mobile: string;
    aadharNumber: string;
    panDetails: string;
    dob: string;
    address: string;
}

interface FDRequestProps {
    id: number;
    userId: number;
    investorAadhar: string;
    investorPan: string;
    amountInvested: number;
    schemeName: string;
    units: number;
    AUM: number;
    rank: number;
    currentNAV: number;
    risk: string;
    user?: UserDetailsProps;
}

interface FDRequestTableProps {
    data: FDRequestProps[];
    onEdit: (fdRequest: FDRequestProps) => void;
}

const FDRequestTable: React.FC<FDRequestTableProps> = ({ data, onEdit }) => {
    const [editingFDRequest, setEditingFDRequest] = useState<FDRequestProps | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filteredData, setFilteredData] = useState<FDRequestProps[]>(data);

    useEffect(() => {
        const filtered = data.filter(
            (row) =>
                row.user?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.investorAadhar.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.investorPan.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.amountInvested.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.schemeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.units.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.AUM.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.rank.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.currentNAV.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.risk.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredData(filtered);
        setPage(0);
    }, [searchTerm, data]);

    const handleEditClick = (fdRequest: FDRequestProps) => {
        setEditingFDRequest(fdRequest);
    };

    const handleSave = (editedFDRequest: FDRequestProps) => {
        onEdit(editedFDRequest);
        setEditingFDRequest(null);
    };

    const handleCancel = () => {
        setEditingFDRequest(null);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box width="100%" display="flex" flexDirection="column" alignItems="center" marginBottom={2}>
            <Box width="90%" mb={2} display="flex" justifyContent="space-between">
                <Typography variant="h5">FD Request</Typography>
                <TextField
                    size="small"
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <div style={{ display: 'flex', justifyContent: 'center', width: '90%' }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Investor Name</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Investor Aadhar</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Investor PAN</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Amount Invested</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Scheme Name</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Units</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>AUM</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Rank</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Current NAV</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Risk</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.user?.name}</TableCell>
                                    <TableCell>{row.investorAadhar}</TableCell>
                                    <TableCell>{row.investorPan}</TableCell>
                                    <TableCell>{row.amountInvested}</TableCell>
                                    <TableCell>{row.schemeName}</TableCell>
                                    <TableCell>{row.units}</TableCell>
                                    <TableCell>{row.AUM}</TableCell>
                                    <TableCell>{row.rank}</TableCell>
                                    <TableCell>{row.currentNAV}</TableCell>
                                    <TableCell>{row.risk}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEditClick(row)}>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    count={filteredData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </Box>
    );
};

export default FDRequestTable;
