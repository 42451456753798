import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  FormControl,
  FormLabel,
  Select,
  InputLabel,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  addHolding,
  editUserHolding,
  getAIFSchemes,
  withdrawRequest,
} from "../../redux/holdings/holdingSlice";
import toast from "react-hot-toast";
import { SelectChangeEvent } from "@mui/material/Select";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { changeStatus } from "../../redux/portfolio/changeStatus/statusSlice";
import AppLoader from "../AppLoader/AppLoader";


interface AIF {
  requestId:number;
  id: number;
  noteficationId: number;
  holdingName: string;
  category: string;
  investedAmount: number;
  currentAmount: number;
  transactionDate: string;
  currentNavDate: string;
  currentNAV: number;
  realisedPnL: number;
  transactions: [];
  xirr: string;
  days: number;
  unrealisedPnL: number;
  absolutePnL: number;
  type:string;
}

interface EditModalForHoldingsProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: AIF) => void;
  rowData: AIF;
  filteredData: AIF[];
  mode: "edit" | "add" | "plus";
  fetchData: () => void;
}

const formatDateString = (dateString: string) => {
  if (dateString.includes("/")) {
    const [day, month, year] = dateString.split("/"); // Convert MM/DD/YYYY format to DD-MM-YYYY
    return `${year}-${month}-${day}`;
  }

  // Check if the date is already in DD-MM-YYYY format
  if (dateString.includes("-")) {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`; // Keep as DD-MM-YYYY
  }
  return dateString;
};

const EditModalForHoldings: React.FC<EditModalForHoldingsProps> = ({
  open,
  onClose,
  onSubmit,
  filteredData,
  rowData,
  mode,
  fetchData
}) => {
  const [formData, setFormData] = useState<AIF>(rowData);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [schemes, setSchemes] = useState<{ id: number; assetName: string }[]>(
    []
  );
  const { id } = useParams();
  const [selectedSchemeId, setSelectedSchemeId] = useState<number | null>(null);
  const [selectedSchemeName, setSelectedSchemeName] = useState<string>("");
  const dispatch = useDispatch<any>();
  const todayDate = dayjs().format("YYYY-MM-DD");
  const [Error, setError] = useState<string>("");

  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to manage button disable

  const getCurrentAmountByHoldingName = (holdingName: string) => {
    if (!filteredData || filteredData.length === 0) {
      return 0; // Return a default value if filteredData is undefined or empty
    }
    const foundHolding = filteredData.find(
      (data) => data.holdingName === holdingName
    );
    return foundHolding ? foundHolding.currentAmount : 0; // Return 0 or any default value if not found
  };
  
  const getTransactionIDByHoldingName = (holdingName: string) => {
    if (!filteredData || filteredData.length === 0) {
      return ""; // Return an empty string if filteredData is undefined or empty
    }
    const transactionIds = filteredData.find(
      (data) => data.holdingName === holdingName
    )?.transactions?.map((trans: any) => trans.id).join(",");
    return transactionIds ? transactionIds : ""; // Return 0 or any default value if not found
  };
  
  // Usage example
  const currentAmount = getCurrentAmountByHoldingName(formData.holdingName);
  const TransID = getTransactionIDByHoldingName(formData.holdingName);
  console.log('Current Amount:',TransID, currentAmount);


  useEffect(() => {
    // Enable the "Add" button only if all fields are filled
    const isAllFieldsFilled =
      selectedSchemeId !== null &&
      formData.transactionDate &&
      formData.investedAmount > 0 &&
      formData.currentNAV > 0;
    
    setIsButtonDisabled(!isAllFieldsFilled); // Disable button if any field is missing
  }, [selectedSchemeId, formData.transactionDate, formData.investedAmount, formData.currentNAV]);

  useEffect(() => {
    if (mode==="plus" || rowData?.type==="Buy"|| rowData?.type==="Sell") {
      handleSelectChange(rowData?.holdingName);
    }
    setFormData(rowData);
  }, [rowData]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = e.target;
    // If the field is for the scheme dropdown, call handleSelectChange
    if (name === "holdingName") {
      setSearchTerm(value);
      handleSelectChange(value); // Call the scheme-specific function
    }

    if (name === "transactionDate") {
      validateDate(value);
    }

    // Type check for HTMLSelectElement
    if (e.target instanceof HTMLSelectElement) {
      handleSelectChange(value);
    } else {
      // Handle HTMLInputElement
      setFormData((prevData) => ({
        ...prevData,
        [name]: name === "currentNAV" ? Number(value) : value,
        [name]: name === "investedAmount" ? Number(value) : value,
        [name]: name === "transactionDate" ? formatDateString(value) : value,
      }));
    }
  };

  // Date validation function
  const validateDate = (inputDate: string) => {
    const today = new Date();
    const input = new Date(inputDate);
    const regex = /^\d{4}-\d{2}-\d{2}$/; // Date in YYYY-MM-DD format

    if (!regex.test(inputDate)) {
      setError("Invalid date format. Use DD-MM-YYYY.");
    } else if (input > today) {
      setError("Date cannot be in the future.");
    } else {
      setError("");
    }
  };

  const handleSubmit = async () => {
    if (mode === "edit") {
      try {
        // Convert formData's currentValue to the correct type if necessary
        const currentValue = Number(formData?.currentNAV) || 0;
        const response = await dispatch(
          editUserHolding({
            id: TransID,
            holdingType: 5,
            currentNAV: currentValue,
          })
        );
        if (response?.payload) {
          setLoading(true);
          toast.success("AIF modification is successfully done.");
          setTimeout(() => {
            fetchData();
            setLoading(false);
          }, 5000);
          handleClose();
        } else {
          toast.error("Failed to modify AIF.");
        }
      } catch (error) {
        console.error("Error submitting data:", error);
        toast.error("An error occurred while modifying the AIF.");
        handleClose();
      }
    } else {
      if (!Error) {
        try {
          const response = await dispatch(
            // Assuming your API action is here
            addHolding({
              userId: id,
              holdingType: 5,
              schemeId: selectedSchemeId,
              purchaseDate: formData.transactionDate,
              amount:`${formData.investedAmount}`,
              currentNAV: formData.currentNAV,
            })
          );

          if (response?.payload) {
            setLoading(true);
            toast.success("New AIF report added successfully.");
            if (mode === "plus" && rowData?.noteficationId > 0) {
              dispatch(changeStatus({ id: rowData?.noteficationId, status: 1 }))
                .then(() => {
                  console.log("Accepted.")
                })
                .catch((error: any) => {
                  console.error(`Error changing status for ID: ${id}`, error);
                });
            }
            setTimeout(() => {
              setLoading(false);
              fetchData();
            }, 5000);
            handleClose();
          } else {
            toast.error("Failed to modify AIF.");
          }
        } catch (error) {
          console.error("Error submitting data:", error);
          toast.error("An error occurred while modifying the AIF.");
        }
      }
    }
  };

  const readOnlyStyle = {
    backgroundColor: "#f5f5f5", // Light gray background
    color: "gray", // Gray text color
  };
  // Fetch schemes based on user input
  const handleSelectChange = async (value: string) => {
    setSearchTerm(value); // Update the search input value

    try {
      const response = await dispatch(
        getAIFSchemes({
          pageIndex: 1,
          pageSize: 100,
          search: value, // Use search term for real-time filtering
          holdingType: 5,
        })
      );
      // Filter out schemes where schemesName is "-" or ""
      const filteredSchemes = response?.payload?.schemeNames?.filter(
        (scheme: { assetName: string }) =>
          scheme.assetName !== "-" && scheme.assetName !== ""
      );

      // Set the schemes from the filtered response payload
      if (mode === "plus") {
        setSelectedSchemeId(filteredSchemes?.[0]?.id);
      } else {
        setSchemes(filteredSchemes || []);
      }
      console.log(selectedSchemeId, "selectedSchemeId");
    } catch (error) {
      console.error("Error fetching schemes:", error);
      toast.error("An error occurred while fetching schemes.");
    }
  };

  const handleSchemeSelect = (schemeId: number, schemeName: string) => {
    // Set the selected scheme ID and update searchTerm with the selected name
    setSelectedSchemeId(schemeId);
    setSearchTerm(schemeName);
    setSelectedSchemeName(schemeName);
    setSchemes([]);
  };

  const handleClose = () => {
    // Clear search term and schemes
    setSearchTerm("");
    setSchemes([]);
    setSelectedSchemeId(null);
    setSelectedSchemeName("");
    onClose();
  };
  const handleRedeem = async() => {
    try {
      const response = await dispatch(
        // Assuming your API action is here
        withdrawRequest({
          id:rowData?.requestId,
          status:1,
          amount:Number(formData.investedAmount)
        })
      );
        setLoading(true);
        toast.success("Requested Amount redeemed successfully.");
        setTimeout(() => {
          setLoading(false);
          fetchData();
        }, 5000);
        handleClose();
      
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("An error occurred while modifying the AIF.");
    }
  };

  return (
    <>
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800, // Adjust width as needed
          maxHeight: "80vh", // Limit the height of the modal
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          overflowY: "auto", // Make the content scrollable on the Y-axis
          borderRadius: 2,
        }}
      >
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="h6" component="h2">
            {mode === "edit"
              ? "Edit Transaction Record"
              : "Add Transaction Record"}
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Box component="form" noValidate autoComplete="off">
          {mode === "add" || mode === "plus" && rowData?.type!=="Sell"  ? (
            <FormControl fullWidth margin="normal">
              <TextField
                label={mode === "add"||mode==="plus" ? "Search Schemes" : "Scheme Name"}
                variant="outlined"
                name="holdingName"
                value={
                  searchTerm ||formData?.holdingName || ""
                }
                onChange={(e) => handleSelectChange(e.target.value)}
                fullWidth
                margin="normal"
                InputProps={{
                  endAdornment: (
                    mode==="add" && (
                    <InputAdornment position="end">
                      <IconButton>
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ))
                }}
              />
              {/* Display the filtered schemes */}
              {schemes?.map((scheme, index) => (
                <MenuItem
                  key={index}
                  value={scheme.assetName}
                  onClick={() =>
                    handleSchemeSelect(scheme.id, scheme.assetName)
                  } // Set ID and Name
                >
                  {scheme.assetName}
                </MenuItem>
              ))}
            </FormControl>
          ) : (
            <TextField
              label="Scheme Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="holdingName"
              type="text"
              InputLabelProps={{ shrink: true }}
              value={formData?.holdingName || ""}
              InputProps={{
                readOnly: mode === "edit" ||  rowData?.type==="Sell",
                  sx: mode === "edit" || rowData?.type==="Sell"? readOnlyStyle : undefined,
              }}
            />
          )}
          {rowData?.type!=="Sell" &&
          (mode !== "edit" && (
            <TextField
              label="Transaction Date"
              variant="outlined"
              fullWidth
              margin="normal"
              name="transactionDate"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formatDateString(formData?.transactionDate || "")}
              onChange={(e) =>
                handleChange(e as React.ChangeEvent<HTMLInputElement>)
              }
              inputProps={{ max: todayDate }} // Restrict future dates
              error={!!Error}
              helperText={Error} // Show error message
            />
          ))}
          <TextField
            label="Amount"
            variant="outlined"
            fullWidth
            margin="normal"
            name="investedAmount"
            type="text"
            value={formData?.investedAmount||""}
            onChange={(e) =>
              handleChange(e as React.ChangeEvent<HTMLInputElement>)
            }
            InputProps={{
              readOnly: mode === "edit",
              sx: mode === "edit" ? readOnlyStyle : undefined,
            }}
          />{rowData.type!=="Sell"&&(
            <TextField
            label="Current NAV"
            variant="outlined"
            fullWidth
            margin="normal"
            name="currentNAV"
            type="text"
            value={formData?.currentNAV||""}
            onChange={(e) =>
              handleChange(e as React.ChangeEvent<HTMLInputElement>)
            }
          />
          )}
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              onClick={onClose}
              color="secondary"
              variant="outlined"
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            {rowData?.type==="Sell" ?
            (mode === "add" ||
              (mode === "plus" && (
                <Button
                  onClick={formData?.investedAmount<=0 || formData?.investedAmount>currentAmount?undefined:
                    handleRedeem
                  }
                  color="primary"
                  variant="contained"
                  sx={{
                    mr: 1,
                    opacity:formData?.investedAmount<=0|| formData?.investedAmount>currentAmount?0.5:1,
                    cursor: formData?.investedAmount<=0|| formData?.investedAmount>currentAmount?"not-allowed":"pointer",

                  }}
                >
                  Redeem
                </Button>
              ))):(
              mode === "edit" ? (<>
            <Button onClick={mode==='edit' && formData.currentNAV<=0 ?undefined:handleSubmit} color="primary" variant="contained" sx={{
                    mr: 1,
                    opacity:mode==='edit' && formData.currentNAV<=0  ?0.5:1,
                    cursor:mode==='edit' && formData.currentNAV<=0 ?"not-allowed":"pointer"
                  }}>
               Modify
            </Button></>):(<><Button onClick={handleSubmit} color="primary" variant="contained" sx={{
                    mr: 1,
                  }}
                  disabled={isButtonDisabled}>
               Add
            </Button></>))}
          </Box>
        </Box>
      </Box>
    </Modal>
    <AppLoader loading={loading} />
    </>
  );
};

export default EditModalForHoldings;
