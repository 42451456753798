import { Card, Grid, Typography, IconButton} from "@mui/material";
import React, { useState } from "react";
import { colors } from "../../constants/colors";
import { Button } from "../../components/Button";
import { Text } from "../../components/Text";
import { FormInput } from "../FormInput/FormInput";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Formik, Form, useFormikContext } from "formik";
import { updatePassword } from "../../redux/user/userSlice";
// import { hideLoading, showLoading } from "../../redux/loader/loaderSlice";
import AcknowledgementModel from "../Modal/AcknowledgementModel";
import authService from "../../redux/auth/authService";
import { useNavigate } from "react-router-dom";

const resetPasswordValues = {
  currentPassword: "",
  password: "",
  confirmPassword: "",
};

const UpdatePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [showModel, setShowModel] = useState<boolean>(false);

  const FormSchema = yup.object().shape({
    currentPassword: yup.string().required("Current password is required."),

    password: yup
      .string()
      .min(8, "New password must be at least 8 characters long.")
      .matches(/[0-9]/, "New password must contain at least one number.")
      .matches(
        /[a-z]/,
        "New password must contain at least one lowercase letter."
      )
      .matches(
        /[A-Z]/,
        "New password must contain at least one uppercase letter."
      )
      .matches(
        /[^\w]/,
        "New password must contain at least one special character."
      )
      .required("New password is required."),

    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref("password"), undefined],
        "Confirm password must match new password."
      )
      .required("Confirm password is required."),
  });

  const FormDebugger = () => {
    const formik = useFormikContext();
    console.log("Formik context:", formik);
    return null;
  };

  const onConfirm = () => {
    authService.logout();
    navigate("/");
  };
  const onCancel = () => {};

  return (
    <div>
      <Card
        style={{
          backgroundColor: colors.lightBg,
          minHeight: "60px",
          display: "flex",
          alignItems: "center",
          padding: "0",
        }}
        className="border border-lightGrey rounded-md h-8 w-full"
      >
        <Typography
          variant="h5"
          marginLeft={2}
          color={colors.darkGrey}
          fontWeight="bold"
        >
          Update Password
        </Typography>
      </Card>
      <div className="justify-center border border-lightGrey rounded-lg p-4">
        <Formik
          initialValues={resetPasswordValues}
          validationSchema={FormSchema}
          onSubmit={async (values, { setSubmitting }) => {
            console.log("Form values on submit", values);
            try {
              // dispatch(showLoading());
              const response = await dispatch(updatePassword(values));
              if (response?.payload?.statusCode === 400) {
                return;
              } else {
                // dispatch(hideLoading());
                setShowModel(true);
              }
            } catch (error: any) {
              console.error(error.message || "An error occurred");
              // dispatch(hideLoading());
            } finally {
              setSubmitting(false);
              // dispatch(hideLoading());
            }
          }}
        >
          {({ errors, touched, handleChange, handleBlur, validateForm }) => (
            <Form className="w-full flex flex-col gap-[31px] mt-5">
              <Grid
                container
                spacing={2}
                className="flex justify-around items-center"
              >
                {(
                  ["currentPassword", "password", "confirmPassword"] as const
                ).map((field, idx) => (
                  <Grid key={idx} item xs={12} sm={5} md={5} lg={5}>
                    <div className="flex flex-col items-start justify-start mx-auto gap-2">
                      <FormInput
                        label={field
                          .split(/(?=[A-Z])/)
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                        name={field}
                        type={
                          field === "currentPassword" || field === "password"
                            ? passwordVisible
                              ? "text"
                              : "password"
                            : "password"
                        }
                        placeholder={field.split(/(?=[A-Z])/).join(" ")}
                        className="mb-4"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        suffix={
                          (field === "currentPassword" ||
                            field === "password") && (
                            <IconButton
                              onClick={() =>
                                setPasswordVisible(!passwordVisible)
                              }
                            >
                              {passwordVisible ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          )
                        }
                        error={errors[field]}
                        touched={!!errors[field]}
                      />
                    </div>
                  </Grid>
                ))}
                <Grid item xs={12} sm={5} md={5} lg={5}>
                  <Text
                    as="xs"
                    className="text-darkGrey text-center text-sm leading-5"
                  >
                    A combination of uppercase letters, lowercase letters,
                    numbers, and symbols. At least 8 characters long but 12 or
                    more is better.
                  </Text>
                </Grid>
              </Grid>
              <Button
  className="w-full md:w-48 font-semibold mx-auto py-2"
  style={{ 
    whiteSpace: "nowrap", 
    minWidth: "150px", 
    backgroundColor: colors.darkGrey, // Add styles directly
    color: "white" 
  }}
  variant="contained"
  color="darkGrey"
>
  Update Password
</Button>


            </Form>
          )}
        </Formik>
      </div>

      <AcknowledgementModel
        isVisible={showModel}
        message="Password changed successfully. You will need to re-login "
        buttonSecond="OK"
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </div>
  );
};

export default UpdatePassword;
