import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { login } from "../../redux/auth/authSlice";
import { Formik } from "formik";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Input from '../../components/Input/CommonInput'; // Import the CommonInput component
import Header from '../../components/Header/Header';
import toast from "react-hot-toast";
import * as yup from "yup";
import { LoginModel } from '../../models/login.model';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';


const loginSchema = yup.object({
  email: yup.string().required("Email is required."),
  password: yup.string().required("Password is required."),
});

const loginValues = {
  email: "",
  password: "",
};



const Login = () => {
  
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const refreshToken = useSelector((state: any) => state?.auth?.refreshToken);
  const socialLoggedIn = useSelector(
    (state: any) => state?.auth?.socialLoggedIn
  );
  const accessToken = useSelector((state: any) => state?.auth?.accessToken);
  const isError = useSelector((state: any) => state?.auth?.isError);


  const [email, setEmail] = useState(''); // State variable for email
  const [password, setPassword] = useState(''); // State variable for password


  useEffect(() => {
    if (refreshToken && accessToken) {
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("accessToken", accessToken);
      toast.success("Logged In Successfully!");
      navigate("/analytics");
    }
  }, [refreshToken, accessToken]); 
  


  useEffect(() => {
    if (isError) {
      toast.error(isError.message || "Invalid user email or passsword.");
    }
  }, [isError]);

  const onLogin = (values: LoginModel) => {
    dispatch(login(values));
  };



  // const handleSubmit = (event: { preventDefault: () => void; currentTarget: HTMLFormElement | undefined; }) => {
  //   event.preventDefault();
  //   console.log({ email, password });
  //   // Handle form submission logic here
  // };

  return (
   <>
      <div className="flex justify-center items-center h-screen" > {/* Apply Flexbox styles */}
        <Container >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>

            <Formik
                initialValues={loginValues}
                validationSchema={loginSchema}
                onSubmit={(values, { setSubmitting }) => {
                  onLogin(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  /* and other goodies */
                }) => (
                  <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (!isValid) {
                      toast.error("Invalid user email or passsword.");
                      return;
                    }
                    handleSubmit();
                  }}
                  className="w-full flex flex-col gap-[31px]"
                >
                {/* Use the CommonInput component */}
                
               <Box sx={{ maxWidth: 400, margin: 'auto' }}>
                 <Box>
                    <Input label="Email Address" name="email" type='text' value={values.email} onChange={handleChange("email")} id={''} />
                    
                    <Input
                        type={passwordVisible ? "text" : "password"}
                        name="password"
                        label="Password"
                        onChange={handleChange("password")}
                        value={values.password}
                        isPassword={true} id={''}                      />
                 </Box>
                  
                  {/* <Grid container alignItems="center">
                    <Grid item>
                      <Checkbox value="remember" color="primary" />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">Remember me</Typography>
                    </Grid>
                  </Grid> */}
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, backgroundColor:"#255288", '&:hover':{backgroundColor: '#F17A21'} }}>
                    Login
                  </Button>
                  {/* <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2">
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href="#" variant="body2">
                        Don't have an account? Sign Up
                      </Link>
                    </Grid>
                  </Grid> */}
               </Box>
           </form>
            )}
            </Formik>
          </Box>
        </Container>
      </div>
   </>
  );
}

export default Login;
