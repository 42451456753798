import api from "../../config/apiConfig";
import { UserDetails,RMUserDetails} from "../../models/user.model";
import { UserId } from "../../models/userId.model";


const getUser = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/admin/getAllUsers", data, {
        headers: {
            Authorization: accessToken, // Ensure the token is formatted correctly
        },
    });
    return response.data;
};

const getRMUser = async (data: RMUserDetails) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("admin/getAllRm", data, {
      headers: {
          Authorization: accessToken, // Ensure the token is formatted correctly
      },
  });
  return response.data;
};

const getAllUserForRM = async (data: RMUserDetails) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("admin/rm/getAllUsers", data, {
      headers: {
          Authorization: accessToken, // Ensure the token is formatted correctly
      },
  });
  return response.data;
};

//get user detail by id
const getUserById = async (data:UserId) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post(
      "/user/getById", data, {
        headers: {
          Authorization: accessToken,
        },
      });
    return response.data;
  };

// download user data
const downloadUserData = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/admin/downloadUserData", data, {
        headers: {
            Authorization: accessToken, // Ensure the token is formatted correctly
        },
    });
    return response.data;
};

const updateUser = async (data: FormData) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/user/update", data, {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'multipart/form-data', // Ensure the header is set for form data
      },
    });
    return response.data;
  };

const registerUser = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/admin/registerUser", data, {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'multipart/form-data', // Ensure the header is set for form data
      },
    });
    return response.data;
  };

  const registerRMUser = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("admin/registerRM", data, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response.data;
  };
  const updatePassword = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post(
      "/user/changePassword",
      data,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );
    return response.data;
  };
  const updateProfile = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post(
      "user/update",
      data,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );
    return response.data;
  };


  

const userService = { getUser, downloadUserData, updateUser, getUserById, registerUser,getRMUser ,registerRMUser,getAllUserForRM,updatePassword,updateProfile };
export default userService;
