import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Box,
    Typography,
    TextField,
    InputAdornment,
    TablePagination,
    TableFooter,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DematDetailsEditForm from './DematEditingForm'; // Ensure this form returns a type matching DematDetailsProps
import { Search } from '@mui/icons-material';

interface UserDetailsProps {
    id: number;
    name: string;
    email: string;
    mobile: string;
}

interface DematDetailsProps {
    id: number;
    userId: number;
    dematAccountNumber: string;
    dematBalance: number;
    dematType: string;
    dematStatus: string;
    user?: UserDetailsProps; // Added user details to the interface
}

interface DematDetailsTableProps {
    dematDetails: DematDetailsProps[];
    onEdit: (dematDetail: DematDetailsProps) => void;
}

const DematDetailsTable: React.FC<DematDetailsTableProps> = ({ dematDetails, onEdit }) => {
    const [editingDetail, setEditingDetail] = useState<DematDetailsProps | null>(null);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filteredData, setFilteredData] = useState<DematDetailsProps[]>(dematDetails);

    useEffect(() => {
        const filtered = dematDetails.filter(detail =>
            detail.dematAccountNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
            detail.dematType.toLowerCase().includes(searchTerm.toLowerCase()) ||
            detail.dematStatus.toLowerCase().includes(searchTerm.toLowerCase()) ||
            detail.user?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            detail.user?.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            detail.user?.mobile.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredData(filtered);
        setPage(0);
    }, [searchTerm, dematDetails]);

    const handleEditClick = (detail: DematDetailsProps) => {
        setEditingDetail(detail);
        setIsEditFormOpen(true);
    };

    const handleCloseEditForm = () => {
        setIsEditFormOpen(false);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className='mt-10 mb-10' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box width="90%" mb={2} display="flex" justifyContent="space-between">
                <Typography variant="h5" style={{ textAlign: 'left' }}>Demat Details</Typography>
                <TextField
                    size="small"
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <div style={{ display: 'flex', justifyContent: 'center', width: '90%' }}>
                <TableContainer component={Paper} style={{ marginTop: '2rem' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Investor Name</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Investor Email</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Investor Mobile</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Account Number</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>Balance</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>Type</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>Status</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((detail) => (
                                <TableRow key={detail.id}>
                                    <TableCell>{detail.user?.name}</TableCell>
                                    <TableCell>{detail.user?.email}</TableCell>
                                    <TableCell>{detail.user?.mobile}</TableCell>
                                    <TableCell>{detail.dematAccountNumber}</TableCell>
                                    <TableCell>{detail.dematBalance}</TableCell>
                                    <TableCell>{detail.dematType}</TableCell>
                                    <TableCell>{detail.dematStatus}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEditClick(detail)}>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    count={filteredData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
            {isEditFormOpen && editingDetail && (
                <DematDetailsEditForm
                    open={isEditFormOpen}
                    dematDetail={editingDetail}
                    onSave={(editedDetail) => {
                        // Handle save logic here, then close the form
                        setIsEditFormOpen(false);
                    }}
                    onCancel={() => setIsEditFormOpen(false)}
                />
            )}
        </div>
    );
};

export default DematDetailsTable;
