import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import { getRMUser } from '../../redux/user/userSlice';
import { useDispatch } from 'react-redux';
import RMTable from '../../components/RMTable/RMDetailsTable';

interface User {
    id: number;
    firstName: string;
    email: string;
    mobile: string;
    createdAt: string;
}

const RMDetails: React.FC = () => {
    const [editUser, setEditUser] = useState<User | null>(null);
    const [loader, setLoader] = useState<boolean>(false);
    const [users, setUsers] = useState<User[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    
    const dispatch = useDispatch<any>();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (editUser) {
            setEditUser((prevUser) => ({
                ...prevUser!,
                [name]: value,
            }));
        }
    };

    const handleCancel = () => {
        setEditUser(null);
    };

    const fetchUsers = async (pageIndex: number, pageSize: number) => {
        setLoader(true);
        try {
            const response = await dispatch(getRMUser({ pageIndex, pageSize }));
            const data = response.payload.data; 
            const totalPages = response.payload.totalPages; 

            setUsers(data);
            setTotalPages(totalPages);
            setCurrentPage(Math.min(pageIndex, totalPages)); 

        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        fetchUsers(currentPage, pageSize);
    }, [dispatch, currentPage, pageSize]);

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage + 1); // Set current page (1-based index)
        fetchUsers(newPage + 1, pageSize); // Fetch data for the new page
    };    

    const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setCurrentPage(1); // Reset to first page whenever page size changes
    };

    return (
        <div>
            <Header />
            <RMTable
                users={users}
                onChange={handleChange}
                onCancel={handleCancel}
                totalPages={totalPages}
                currentPage={currentPage}
                pageSize={pageSize}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
        </div>
    );
};

export default RMDetails;
