import api from "../../config/apiConfig";
import { FDModel } from "../../models/fd.model";

const getFD = async (data: FDModel) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/admin/fd/getAll", data, {
        headers: {
            Authorization: accessToken, // Ensure the token is formatted correctly
        },
    });
    console.log('fd data',response.data)
    return response.data;
   
};

const getFDHoldings = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("fd/getHoldings", data, {
        headers: {
            Authorization: accessToken, // Ensure the token is formatted correctly
        },
    });
    console.log('fd data',response.data)
    return response.data;
   
};

const changeFdStatus = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/admin/fd/changeStatus", data, {
        headers: {
            Authorization: accessToken, // Ensure the token is formatted correctly
        },
    });
    console.log('fd status',response.data)
    return response.data;
   
};
const changeFdWithdrawStatus = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/admin/fd/withdraw", data, {
        headers: {
            Authorization: accessToken, // Ensure the token is formatted correctly
        },
    });
    console.log('fd status',response.data)
    return response.data;
   
};
const getFDScheme = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("fd/getFdNames", data, {
        headers: {
            Authorization: accessToken, // Ensure the token is formatted correctly
        },
    });
    console.log('fd status',response.data)
    return response.data;
   
};
const getFDSchemeByID = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("fd/getSchemeInfo", data, {
        headers: {
            Authorization: accessToken, // Ensure the token is formatted correctly
        },
    });
    console.log('fd status',response.data)
    return response.data;
   
};

const fdService = { getFD, changeFdStatus, getFDHoldings, getFDScheme, getFDSchemeByID,changeFdWithdrawStatus};
export default fdService;