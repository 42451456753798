// FDReporting.tsx
import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { useDispatch } from "react-redux";
import { FDModel } from "../../models/fd.model";
import { getFDHoldings } from "../../redux/fd/fdSlice";
import FDHoldingsTable from "../../components/HoldingsTable/FDHoldingsTable";
import { useParams } from "react-router-dom";

interface FD {
  fdId:number;
  id: number;
  noteficationId:number;
  schemeName: string;
  providerName:string;
  category: string;
  amount: number;
  fdStatus: number;
  transactionDate: string;
  investmentFrequency: string;
  interesetRate:number,
  grossInterest: number;
  transactions: [];
  endDate: number;
  investmentTenure: number;
  returnAmount: number;
  createdAt:string;
  type:string;
  daysLeft:number;
  userId: number;
  startDate: number;
  name: string;
  firstName: string;
  folioNo:string;
}

const FDHoldings: React.FC = () => {
  // Different dummy data
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const [FD, setFD] = useState<FD[]>([]);
  const [totalFd, setTotalFd] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const { id } = useParams();

  const fetchFD = async () => {
    setLoader(true);
    try {
      const response = await dispatch(
        getFDHoldings({
          userId: Number(id),
          startDate: 0,
          endDate: 0,
          forRm: 1
        })
      );
      const data = response.payload;
      setFD(data?.data?.holdings);
      setTotalPages(data.totalPages);
      setCurrentPage(data.pageIndex);
      console.log(totalPages, currentPage);
      console.log("reports page not the table", data);
    } catch (error) {
      console.error("Error fetching FD data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchFD();
  }, [dispatch, currentPage, pageSize]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage);
    fetchFD(); // Fetch data for the new page
  };

  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reset to first page whenever page size changes
  };

  return (
    <div>
      <Header />
      <div>
        <FDHoldingsTable
          data={FD}
          totalPages={totalPages}
          currentPage={currentPage}
          pageSize={pageSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          fetchData={fetchFD}
        />
      </div>
    </div>
  );
};

export default FDHoldings;
