import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import aifservice from "./aifService";



export const getAIF = createAsyncThunk(
  "aif/getAIF",
  async (data: any, thunkAPI) => {
    try {
       const response = await aifservice.getAIF(data);
       console.log('API call successful. Response:', response);
       return response;
    } catch (error: any) {
      console.error('Error in API call:', error);
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);


const initialState = {
    data: {},
    totalPages: 0,
    pageIndex: 1,
    isError: false,
    isSuccess: false,
    isLoading: false,
    isDownloading: false, // New state to manage download operation
    message: "",
};

export const aifSlice = createSlice({
    name: "aif",
    initialState,
    reducers: {
      resetState: (state) => initialState,
    },
    
    extraReducers: (builder) => {
      builder
        .addCase(getAIF.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getAIF.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.data = action.payload; 
          console.log('hey this is fd slice', state.data)
        })
        .addCase(getAIF.rejected, (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = action.error.message || "";
        })
    }
    
  
  });
  
  export const { resetState } = aifSlice.actions;
  export default aifSlice.reducer;
