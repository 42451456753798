import React, { useState } from 'react';
import UserTable from '../../components/UserDetailsTable/UserDetailsTable';
import Header from '../../components/Header/Header';
import DematDetailsTable from '../../components/DematDetails.tsx/DematDetailsTable';
import NomineeDetailsTable from '../../components/NomineeDetails.tsx/NomineeDetailsTable';

interface NomineeDetailsProps {
    id: number;
    userId: number;
    nomineeName: string;
    relation: string;
    aadharNumber: string;
    panDetails: string;
    dob: string;
    address: string;
}

interface UserDetailsProps {
    id: number;
    name: string;
    email: string;
    mobile: string;
}

const NomineeDetails: React.FC = () => {

    const initialUserDetails: UserDetailsProps[] = [
        {
            id: 1,
            name: 'John Doe',
            email: 'john.doe@example.com',
            mobile: '123-456-7890'
        },
        {
            id: 2,
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
            mobile: '987-654-3210'
        },
        // Add more user details as needed
    ];

    const initialNomineeDetails: NomineeDetailsProps[] = [
        {
            id: 1,
            userId: 1,
            nomineeName: 'Alice Smith',
            relation: 'Spouse',
            aadharNumber: '1234 5678 9012',
            panDetails: 'ABCDE1234F',
            dob: '1990-05-15',
            address: '456, Elm Street, Los Angeles, 90001',
        },
        {
            id: 2,
            userId: 2,
            nomineeName: 'Bob Johnson',
            relation: 'Parent',
            aadharNumber: '9876 5432 1098',
            panDetails: 'FGHIJ5678K',
            dob: '1985-09-20',
            address: '789, Maple Avenue, Chicago, 60601',
        },
        // Add more nominee details as needed
    ];

    const [userData, setUserData] = useState<UserDetailsProps[]>(initialUserDetails);
    const [nomineeData, setNomineeData] = useState<NomineeDetailsProps[]>(initialNomineeDetails);
    
    const handleNomineeEdit = (nominee: NomineeDetailsProps) => {
        // Logic to handle editing nominee details
    };

    const mergedNomineeDetails = nomineeData.map(nominee => ({
        ...nominee,
        user: userData.find(user => user.id === nominee.userId)
    }));

    return (
        <div>
            <Header/>
            <NomineeDetailsTable
                data={mergedNomineeDetails}
                onEdit={handleNomineeEdit} nomineeDetails={[]}/>

        </div>
    );

};

export default NomineeDetails;
