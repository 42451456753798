import api from "../../../config/apiConfig";


const changeStatus = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/admin/invest/changeStatus", data, {
        headers: {
            Authorization: accessToken, 
        },
    });
    return response.data;
};

const changeMFStatus = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/admin/mf/changeStatus", data, {
        headers: {
            Authorization: accessToken, 
        },
    });
    return response.data;
};



const statusChangeService = { changeStatus, changeMFStatus };
export default statusChangeService;