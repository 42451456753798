import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  InputAdornment,
  IconButton,
  TableFooter,
  Typography,
  Box,
  Button,
  Modal,
  FormControl,
  Input,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { Search, UnfoldMore, Edit, Close, Add } from "@mui/icons-material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import EditModal from "../Modal/EditModalForPMSHoldings";
import { GrTransaction } from "react-icons/gr";
import { useLocation } from "react-router-dom";

interface PMS {
  requestId: number;
  id: number;
  noteficationId: number;
  holdingName: string;
  category: string;
  investedAmount: number;
  currentAmount: number;
  transactionDate: string;
  currentNavDate: string;
  currentNAV: number;
  realisedPnL: number;
  transactions: [];
  xirr: string;
  days: number;
  unrealisedPnL: number;
  absolutePnL: string;
  type: string;
}

interface Props {
  data: PMS[];
  totalPages: number; // Total number of users
  pageSize: number; // Current page size
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void; // Page change handler
  onPageSizeChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void; // Page size change handler
  currentPage: number; // Current page number
  fetchData: () => void;
}

interface TransactionsModalProps {
  open: boolean;
  onClose: () => void;
  transactions: {
    transactionDate: string;
    investedAmount: number;
    days: number;
  }[];
}
const TransactionsModal: React.FC<TransactionsModalProps> = ({
  open,
  onClose,
  transactions,
}) => {
  const formatTransactionDate = (dateString: any) => {
    if (dateString.includes("/")) {
      const [day, month, year] = dateString.split("/"); // Convert MM/DD/YYYY format to DD-MM-YYYY
      return `${day}-${month}-${year}`;
    }

    // Check if the date is already in DD-MM-YYYY format
    if (dateString.includes("-")) {
      const [day, month, year] = dateString.split("-");
      return `${day}-${month}-${year}`; // Keep as DD-MM-YYYY
    }

    return dateString; // If the format is unexpected, return the original
  };

  const calculateDays = (dateString: any) => {
    // Convert formatted date string to Date object
    const [day, month, year] = dateString.split("-");
    const transactionDate = new Date(`${year}-${month}-${day}T00:00:00`);
    const today = new Date();

    // Reset the time portion to ignore time differences
    const todayWithoutTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    // Calculate the difference in milliseconds
    const timeDiff = todayWithoutTime.getTime() - transactionDate.getTime();
    // Convert milliseconds to days
    const diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));

    return isNaN(diffDays) ? 1 : diffDays + 1; // Return 0 days for the same day or NaN case
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800, // Adjust width as needed
          maxHeight: "80vh", // Limit the height of the modal
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          overflowX: "auto", // Make the content scrollable on the Y-axis
          borderRadius: 2,
        }}
      >
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="h6">Transaction Details</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "200px",
            borderTop: "1.5px solid rgba(224, 224, 224, 1)",
            padding: "0 20px",
            overflow: "auto", // Enable horizontal scrolling
            maxWidth: "100%", // Ensure the table doesn't exceed the container's width
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ whiteSpace: "nowrap", textAlign: "start" }}>
                  Purchase Date
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                  Invested Amount
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                  Withdrawal Amount
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap", textAlign: "end" }}>
                  Days
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions?.map((trans: any, index: any) => (
                <TableRow key={index}>
                  <TableCell sx={{ textAlign: "start" }}>
                    {formatTransactionDate(trans?.transactionDate)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {trans?.investedAmount}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {Number(trans?.redeemValue).toFixed(2)||0}
                  </TableCell>
                  <TableCell sx={{ textAlign: "end" }}>
                    {calculateDays(
                      formatTransactionDate(trans?.transactionDate)
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};
const PMSHoldingsTable: React.FC<Props> = ({
  data,
  totalPages,
  pageSize,
  currentPage,
  onPageChange,
  onPageSizeChange,
  fetchData,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState<PMS[]>([]);
  const dispatch = useDispatch<any>();
  const [loadingRows, setLoadingRows] = useState<number[]>([]);
  const [sortColumn, setSortColumn] = useState<keyof PMS | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [modalOpen, setModalOpen] = useState(false);
  const [transModalOpen, setTransModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<PMS | null>(null);
  const [modalMode, setModalMode] = useState<"edit" | "add" | "plus">("edit");
  const [selectedTransactions, setSelectedTransactions] = useState<[] | null>(
    null
  );

  console.log("this is current page", currentPage);
  console.log("this is page size", pageSize);

  const isEmptyObject = (obj: object): boolean => {
    return Object.keys(obj).length === 0;
  };

  useEffect(() => {
    // Retrieve selectedData from session storage if available
    const storedData = sessionStorage.getItem("selectedData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      if (!isEmptyObject(parsedData)) {
        setSelectedRow(parsedData); // Set selected data row
        setModalMode("plus"); // Set the mode to 'plus'
        setModalOpen(true); // Open the modal
      } else {
        setModalOpen(false);
        setSelectedRow(null);
      }
    }
  }, []);

  useEffect(() => {
    // Filter and sort data whenever the data or search term changes
    let updatedData = data;

    // Search functionality
    if (searchTerm) {
      updatedData = updatedData?.filter((row) =>
        Object.values(row).some(
          (value) =>
            value != null &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    // Sorting functionality
    if (sortColumn) {
      updatedData = [...updatedData].sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) return sortOrder === "asc" ? -1 : 1;
        if (a[sortColumn] > b[sortColumn]) return sortOrder === "asc" ? 1 : -1;
        return 0;
      });
    }

    setFilteredData(updatedData);
  }, [data, searchTerm, sortColumn, sortOrder]);

  const handleSort = (column: keyof PMS) => {
    setSortColumn(column);
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleEdit = (row: PMS) => {
    setSelectedRow(row);
    setModalMode("edit");
    setModalOpen(true);
  };
  const handlePlus = (row: PMS) => {
    setSelectedRow(row);
    setModalMode("plus");
    setModalOpen(true);
  };

  const handleModalClose = () => {
    sessionStorage.removeItem("selectedData");
    setSelectedRow(null);
    setModalOpen(false);
  };
  const handleCloseModal = () => {
    setTransModalOpen(false);
    setSelectedTransactions(null);
  };
  const handleModalSubmit = (data: PMS) => {
    handleModalClose();
  };

  const handleOpenModal = (transactions: any) => {
    setSelectedTransactions(transactions);
    setTransModalOpen(true);
  };

  const handleAddNew = () => {
    setSelectedRow(null);
    setModalMode("add");
    setModalOpen(true);
  };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginBottom={2}
    >
      <Box width="90%" mb={2} display="flex" justifyContent="space-between">
        <Typography variant="h5">PMS</Typography>
        <Box display="flex" justifyContent="space-between" width="30%">
          <TextField
            size="small"
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" color="primary" onClick={handleAddNew}>
            Add New
          </Button>
        </Box>
      </Box>
      <Box width="90%">
        <TableContainer
          component={Paper}
          sx={{
            borderTop: "1.5px solid rgba(224, 224, 224, 1)",
            padding: "0 20px",
            maxWidth: "100%", // Ensure the table doesn't exceed the container's width
            overflow: "auto",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("holdingName")}
                  >
                    Scheme
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("category")}
                  >
                    Category
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell>
                <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("currentNavDate")}
                  >
                    Current Date
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell>
                <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("investedAmount")}
                  >
                    Purchase Amount
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell>

                <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("currentAmount")}
                  >
                    Current Amount
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell>
                {/* <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("unrealisedPnL")}
                  >
                    Unrealised Gain/Loss
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell> */}
                {/* <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("realisedPnL")}
                  >
                    Total (P & L)
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell> */}
                <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("absolutePnL")}
                  >
                    Absolute Return
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    onClick={() => handleSort("xirr")}
                  >
                    XIRR
                    <UnfoldMoreIcon sx={{ fontSize: "small" }} />
                  </Typography>
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Transactions
                  </Typography>
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell sx={{ textAlign: "start" }}>
                      {row?.holdingName}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row?.category}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row?.currentNavDate}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {Number(row?.investedAmount).toFixed(2)}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {Number(row?.currentAmount).toFixed(2)}
                    </TableCell>
                    {/* <TableCell sx={{ textAlign: "center" }}>
                      {Number(row?.unrealisedPnL).toFixed(2)}
                    </TableCell> */}
                    {/* <TableCell sx={{ textAlign: "center" }}>
                      {Number(row?.realisedPnL).toFixed(2)}
                    </TableCell> */}
                    <TableCell sx={{ textAlign: "center" }}>
                      {row?.absolutePnL}
                     
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row?.xirr === "XIRR calculation failed" ||
                      row?.xirr === "-" ||
                      row?.xirr === "" ||
                      isNaN(Number(row?.xirr?.replace("%", "")))
                        ? "-"
                        : (Number(row?.xirr?.replace("%", "")) * 100).toFixed(2)+"%"}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row?.transactions?.length > 0 ? (
                        <GrTransaction
                          onClick={() => handleOpenModal(row?.transactions)}
                          className="text-gray-600 text-2xl font-bold hover:text-blue-600 mx-auto"
                        />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ height: "100%" }}
                      >
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleEdit(row)}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          aria-label="add"
                          onClick={() => handlePlus(row)}
                        >
                          <Add />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={filteredData?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onPageSizeChange}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
      <Box display="flex" justifyContent="right" width="90%" marginTop="10px">
        <Button variant="contained" color="primary" onClick={handleAddNew}>
          Add New
        </Button>
      </Box>
      <EditModal
        open={modalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
        rowData={
          selectedRow || {
            requestId: 0,
            id: 0,
            noteficationId: 0,
            holdingName: "",
            category: "",
            investedAmount: 0,
            currentAmount: 0,
            transactionDate: "",
            currentNavDate: "",
            currentNAV: 0,
            realisedPnL: 0,
            transactions: [],
            xirr: "",
            days: 0,
            unrealisedPnL: 0,
            absolutePnL: "",
            type: "",
          }
        }
        filteredData={filteredData}
        mode={modalMode}
        fetchData={fetchData}
      />
      {selectedTransactions && (
        <TransactionsModal
          open={transModalOpen}
          onClose={handleCloseModal}
          transactions={selectedTransactions}
        />
      )}
    </Box>
  );
};

export default PMSHoldingsTable;
