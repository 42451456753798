import React, { useRef } from 'react';
import {
    Button,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import { downloadUserData } from '../../redux/user/userSlice';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as yup from "yup";
import { updateUser } from '../../redux/user/userSlice';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CommonInput from '../Input/CommonInput';
import Typography from '@mui/material/Typography';
import { getUserById } from '../../redux/user/userSlice';
import { updateBank } from '../../redux/bank/bankSlice';
import { updateNominee } from '../../redux/nominee/nomineeSlice';
import { updateDemat } from '../../redux/demat/dematSlice';
import toast from 'react-hot-toast';
import { updatePreference } from '../../redux/preference/preferenceSlice';
import dayjs from 'dayjs';



interface User {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    dateOfBirth: string;
    gender: number;
    bseStarClientCode: string;
    address1: string;
    address2: string;
    address3: string;
    addressType: number;
    pincode: string;
    city: string;
    state: string;
    lastTransactionNo: string;
    onboardStatus: number;
    occupation: string;
    occupationType: string;
    wealthSource: string;
    incomeSlab: string;
    politicalExposed: number;
    isNRI: number;
    isKycDone: number;
    isNomineeAdd: number;
    isBankAdd: number;
    isDematAdd: number;
    isSignatureAdd: number;
    isPasswordAdd: number;
    kyc: {
        id: number;
        aadharNumber: string;
        aadharMobileNumber: string;
        aadharCard: string;
        isAadharVerified: number;
        pancardNumber: string;
        pancardMobileNumber: string;
        pancard: string;
        isPanVerified: number;
    };
    nominee: {
        id: number;
        name: string;
        mobileNumber: string;
        email: string;
        relation: number;
        dateOfBirth: string;
        percentage: string;
        nomineeAddress: string;
        pincode: string;
        isMinor: number;
        guardianDetails: string;
        aadharNumber: string;
        aadharCard: string;
        pancardNumber: string;
        pancard: string;
    }[];
    bank: {
        id: number;
        accountType: string;
        bankName: string;
        accountNumber: string;
        ifscCode: string;
        bankBranch: string;
        bankPinCode: string;
        bankMICR: string;
        bankAddress: string;
        cancelCheque: string;
    }[];
    demat: {
        id: number;
        dpId: string;
        clientId: string;
        depositoryName: string;
        cmlFile: string;
    };
    preferences: {
        id: number;
        mutualFunds: number;
        equity: number;
        corporateFD: number;
        bonds: number;
        aif: number;
        pms: number;
        direct: number;
        regular: number;
    };
}

interface EditFormProps {
   
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSave: () => void;
    onCancel: () => void;
}

const validationSchema = yup.object().shape({
    id: yup.number().required('User ID is required'),
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    mobile: yup.string().required('Mobile number is required'),
   
   
    bseStarClientCode: yup.string().required('BSE Star Client Code is required'),
    
    onboardStatus: yup.number().required('Onboarding Status is required'),
   
    isKycDone: yup.number().required('KYC status is required'),
    isNomineeAdd: yup.number().required('Nominee status is required'),
    isBankAdd: yup.number().required('Bank status is required'),

    isSignatureAdd: yup.number().required('Signature status is required'),
    isPasswordAdd: yup.number().required('Password status is required'),
    // kyc: yup.object().shape({
    //     id: yup.number().required('KYC ID is required'),
    //     aadharNumber: yup.string().required('Aadhar Number is required'),
    //     aadharMobileNumber: yup.string().required('Aadhar Mobile Number is required'),
    //     aadharCard: yup.string().required('Aadhar Card is required'),
    //     isAadharVerified: yup.number().required('Aadhar Verification status is required'),
    //     pancardNumber: yup.string().required('Pancard Number is required'),
    //     pancardMobileNumber: yup.string().required('Pancard Mobile Number is required'),
    //     pancard: yup.string().required('Pancard is required'),
    //     isPanVerified: yup.number().required('Pancard Verification status is required'),
    // }),
});




const UserDetailsEditForm: React.FC<EditFormProps> = ({ onSave, onCancel, onChange }) => {

    const dispatch = useDispatch<any>()
    const [users, setUsers] = useState<User | null>(null);
    const { id } = useParams();
    const userId = Number(id)
    const navigate = useNavigate();

    const [nomineeFiles, setNomineeFiles] = useState<{ [index: number]: { aadharCard?: File; pancard?: File } }>({});

    const [dateOfBirth, setDateOfBirth] = useState('');
    
    const [gender, setGender] = useState('');
    const [addressType, setAddressType] = useState('');
    const [nomineeDateOfBirth, setNomineeDateOfBirth] = useState('');
    const [formattedDate, setFormattedDate] = useState('');
    
    const [value, setValue] = useState({
        preferences: {
            mutualFunds: false,
            equity: false,
            corporateFD: false,
            bonds: false,
            aif: false,
            pms: false,
            direct: false,
            regular: false,
        },
    });
    
    const initialValues = {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        dateOfBirth: '',
        gender: 0,
        bseStarClientCode: '',
        address1: '',
        address2: '',
        address3: '',
        addressType: 0,
        pincode: '',
        city: '',
        state: '',
        lastTransactionNo: '',
        onboardStatus: 0,
        occupation: '',
        occupationType: '',
        wealthSource: '',
        incomeSlab: '',
        politicalExposed: 0,
        isNRI: 0,
        isKycDone: 0,
        isNomineeAdd: 0,
        isBankAdd: 0,
        isDematAdd: 0,
        isSignatureAdd: 0,
        isPasswordAdd: 0,
        kyc: {
            id: 0,
            aadharNumber: '',
            aadharMobileNumber: '',
            aadharCard: '',
            isAadharVerified: 0,
            pancardNumber: '',
            pancardMobileNumber: '',
            pancard: '',
            isPanVerified: 0,
        },
        nominee: [{
            id: 0,
            name: '',
            mobileNumber: '',
            email: '',
            relation: 0,
            dateOfBirth: '',
            percentage: '',
            nomineeAddress: '',
            pincode: '',
            isMinor: 0,
            guardianDetails: '',
            aadharNumber: '',
            aadharCard: '',
            pancardNumber: '',
            pancard: '',
        }],
        bank: 
         [{ id: 0,
            accountType: '',
            bankName: '',
            accountNumber: '',
            ifscCode: '',
            bankBranch: '',
            bankPinCode: '',
            bankMICR: '',
            bankAddress: '',
            cancelCheque: '',
        }]
        ,
        demat: {
            id: 0,
            dpId: '',
            clientId: '',
            depositoryName: '',
            cmlFile: '',
        },
        preferences: {
            id: 0,
            mutualFunds: 0,
            equity: 0,
            corporateFD: 0,
            bonds: 0,
            aif: 0,
            pms: 0,
            direct: 0,
            regular: 0,
        },
    };


    // const handleCancel = () => {
    //     // Handle cancel action here
    //     onCancel();
    // };

    const formikRef = useRef<any>();

     const formatDate = (milliseconds: any) => {
        if (!milliseconds || isNaN(milliseconds)) {
          return ""; // Handle invalid input gracefully
        }
      
        const date = new Date(milliseconds);
        if (isNaN(date.getTime())) {
          return ""; // Handle invalid date object
        }
      
        const formattedDate = new Intl.DateTimeFormat('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }).format(date);
      
        return formattedDate;
      };

    useEffect(() => {
        const fetchData = async () => {
          try {
            const userId = Number(id); // Convert id from URL params to a number
            const response = await dispatch(getUserById({ userId })); // Unwrap the response to handle payload
            const userData = response.payload.data[0]
            setUsers(userData)
            console.log('this is user data ', userData)
            setDateOfBirth(formatDate(userData.dateOfBirth))
            setNomineeDateOfBirth(formatDate(userData.nominee[0].dateOfBirth))

            setValue({
                preferences: {
                    mutualFunds: userData?.preferences.mutualFunds, // Convert to boolean
                    equity: userData?.preferences.equity,
                    corporateFD: userData?.preferences.corporateFD,
                    bonds: userData?.preferences.bonds,
                    aif: userData?.preferences.aif,
                    pms: userData?.preferences.pms,
                    direct: userData?.preferences.direct,
                    regular: userData?.preferences.regular,
                },
            });

          } catch (error) {
            console.error('Failed to load user', error);
            navigate('/not-found');
          }
        };
    
        if (id) {
          fetchData();
        }
    
        return () => {
          // Cleanup function if needed
        };
      }, [dispatch, id, navigate]);

 
      console.log('date of nominee',nomineeDateOfBirth)

      const occupationOptions = [
        { value: "01", label: "Business" },
        { value: "02", label: "Service" },
        { value: "03", label: "Professional" },
        { value: "04", label: "Agriculturist" },
        { value: "05", label: "Retired" },
        { value: "06", label: "Housewife" },
        { value: "07", label: "Student" },
        { value: "08", label: "Others" },
        // { value: "09", label: "Doctor" },
        // { value: "41", label: "Private Sector Service" },
        // { value: "42", label: "Public Sector Service" },
        // { value: "43", label: "Forex Dealer" },
    ];

    const occupationTypeOptions = [
        { value: "S", label: "Service" },
        { value: "B", label: "Business" },
        { value: "O", label: "Others" },
    ];

    const accountType = [
        { value: "SB", label: "Savings Bank" },
        { value: "CB", label: "Savings Bank" },
        { value: "NE", label: "Savings Bank" },
        { value: "SB", label: "Savings Bank" },
    ]

    const wealthSourceOptions = [
        { value: "01", label: "Salary" },
        { value: "02", label: "Business Income" },
        { value: "03", label: "Gift" },
        { value: "04", label: "Ancestral Property" },
        { value: "05", label: "Rental Income" },
        { value: "06", label: "Prize Money" },
        { value: "07", label: "Royalty" },
        { value: "08", label: "Others" },
    ];

    const incomeSlab = [
        { value: "01", label: "Below 1 Lakh" },
        { value: "02", label: "1 to 5 Lacs" },
        { value: "03", label: "5 to 10 Lacs" },
        { value: "04", label: "10 to 25 Lacs" },
        { value: "05", label: "25 Lacs to 1 Crore" },
        { value: "06", label: "Above 1 Crore" },
      ];

    const handleDownload = async () => {
        if (users) {
            try {
                const formData = new FormData();
                formData.append("userId", users.id.toString());
                const response = await dispatch(downloadUserData(formData));

                const blob = new Blob([response.data], { type: 'application/zip' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'userData.zip';

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading user data:', error);
            }
        }
    };


    const handleFileChange = (index: number, fieldName: 'aadharCard' | 'pancard') => (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setNomineeFiles((prev) => ({
                ...prev,
                [index]: {
                    ...prev[index],
                    [fieldName]: file,
                },
            }));
        }
    };
    


    const editUser = async (values: Partial<User>) => {
        console.log('date of investor',dateOfBirth)
        const formData = new FormData();
        formData.append('userId', String(values.id));
        formData.append('firstName', String(values.firstName));
        formData.append('lastName', String(values.lastName));
        // formData.append('email', String(values.email));
        formData.append('mobile', String(values.mobile));
        formData.append('dateOfBirth', dayjs(values.dateOfBirth).format('DD/MM/YYYY'));
        formData.append('gender', String(values.gender));
        // formData.append('bseStarClientCode', String(values.bseStarClientCode));
        formData.append('address1', String(values.address1));
        formData.append('address2', String(values.address2));
        formData.append('address3', String(values.address3));
        formData.append('city', String(values.city));
        formData.append('state',String(values.state));
        formData.append('pinCode', String(values.pincode));
        formData.append('occupation', String(values.occupation));
        formData.append('addressType', String(values.addressType));
       
        
        // formData.append('lastTransactionNo', String(values.lastTransactionNo));
        // formData.append('onboardStatus', String(values.onboardStatus));
        
        // formData.append('occupationType', String(values.occupationType));
        formData.append('wealthSource', String(values.wealthSource));
        formData.append('incomeSlab',String(values.incomeSlab));
        formData.append('politicalExposed', String(values.politicalExposed));
        formData.append('isNRI', String(values.isNRI));
        // formData.append('isKycDone', String(values.isKycDone));
        // formData.append('isNomineeAdd', String(values.isNomineeAdd));
        // formData.append('isBankAdd', String(values.isBankAdd));
        // formData.append('isDematAdd', String(values.isDematAdd));
        // formData.append('isSignatureAdd', String(values.isSignatureAdd));
        // formData.append('isPasswordAdd', String(values.isPasswordAdd));
    
        console.log('update user')

        try {
            console.log('update user')
            await dispatch(updateUser(formData));
            onSave();
        } catch (error:any) {
            toast.error('Error updating user:', error);
        } finally {
            console.log('false')
           
        }
    };
    

    const editKyc = async (values: Partial<User>)  => {
         // Append kyc object
        const formData = new FormData();
        formData.append('kyc.id', String(values.kyc?.id));
        formData.append('kyc.aadharNumber', String(values.kyc?.aadharNumber));
        formData.append('kyc.aadharMobileNumber', String(values.kyc?.aadharMobileNumber));
        formData.append('kyc.aadharCard', String(values.kyc?.aadharCard));
        formData.append('kyc.isAadharVerified', String(values.kyc?.isAadharVerified));
        formData.append('kyc.pancardNumber', String(values.kyc?.pancardNumber));
        formData.append('kyc.pancardMobileNumber', String(values.kyc?.pancardMobileNumber));
        formData.append('kyc.pancard', String(values.kyc?.pancard));
        formData.append('kyc.isPanVerified', String(values.kyc?.isPanVerified));
    }


    const editNominee = async (values: Partial<User>) => {
        if (!values.nominee) return;
    
        const editSingleNominee = async (nominee: any, nomineeFile: any) => {
            const formData = new FormData();
            formData.append('nomineeId', String(nominee.id));
            formData.append('name', nominee.name);
            formData.append('mobileNumber', nominee.mobileNumber);
            formData.append('email', nominee.email);
            formData.append('relation', String(nominee.relation));
            formData.append('dateOfBirth', dayjs(nominee.dateOfBirth).format('DD/MM/YYYY'));
            formData.append('percentage', nominee.percentage);
            formData.append('nomineeAddress', nominee.nomineeAddress);
            formData.append('pincode', nominee.pincode);
            formData.append('isMinor', String(nominee.isMinor));
            formData.append('guardianDetails', nominee.guardianDetails || '');
            formData.append('aadharNumber', nominee.aadharNumber);
    
            if (nomineeFile?.aadharCard) {
                formData.append('aadharCard', nomineeFile.aadharCard);
            }
            if (nomineeFile?.pancard) {
                formData.append('pancard', nomineeFile.pancard);
            }
            formData.append('pancardNumber', nominee.pancardNumber);
    
            try {
                console.log('update nominee');
                await dispatch(updateNominee(formData));
                onSave();
            } catch (error:any) {
                toast.error('Error updating user:', error);
            } finally {
                console.log('false');
            }
        };
    
        for (let index = 0; index < values.nominee.length; index++) {
            const nominee = values.nominee[index];
            const nomineeFile = nomineeFiles[index];
            await editSingleNominee(nominee, nomineeFile);
        }
    };
    
    
    

   const editAccount = async (values: Partial<User>) => {
    try {
        console.log('Updating bank accounts...');
        for (const bank of values.bank || []) {
            const formData = new FormData();
            formData.append(`bankId`, String(bank.id));
            formData.append(`accountType`, String(bank?.accountType));
            formData.append(`bankName`, String(bank?.bankName));
            formData.append(`accountNumber`, String(bank?.accountNumber));
            formData.append(`ifscCode`, String(bank?.ifscCode));
            formData.append(`bankBranch`, String(bank?.bankBranch));
            formData.append(`bankPinCode`, String(bank?.bankPinCode));
            formData.append(`bankMICR`, String(bank?.bankMICR));
            formData.append(`bankAddress`, String(bank?.bankAddress));
            formData.append(`cancelCheque`, String(bank?.cancelCheque));

            console.log(`Updating bank account: ${bank.id}`);
            await dispatch(updateBank(formData));
        }
        onSave(); // Call save function after all updates are completed successfully
    } catch (error:any) {
        toast.error('Error updating bank:', error);
    }
};
    


const editDemat = async (values: Partial<User>)  => {
    // Append kyc object
   const formData = new FormData();
       formData.append('dematId', String(values.demat?.id));
        formData.append('dpId', String(values.demat?.dpId));
        formData.append('clientId', String(values.demat?.clientId));
        formData.append('depositoryName', String(values.demat?.depositoryName));
        // formData.append('cmlFile', String(values.demat?.cmlFile));


        try {
            console.log('update demat')
            await dispatch(updateDemat(formData));
            onSave();
        } catch (error:any) {
            toast.error('Error updating demat:', error);
        } finally {
            console.log('false')
           
        }
}

const editPreference = async (values: Partial<User>)  => {
    // Append kyc object
   const formData = new FormData();
        formData.append('userId', String(values.id))
        formData.append('mutualFund', String(value.preferences.mutualFunds)); // Convert boolean to string
        formData.append('equity', String(value.preferences.equity)); // Convert boolean to string
        formData.append('corporateFD', String(value.preferences.corporateFD)); // Convert boolean to string
        formData.append('bond', String(value.preferences.bonds)); // Convert boolean to string
        formData.append('aif', String(value.preferences.aif)); // Convert boolean to string
        formData.append('pms', String(value.preferences.pms)); // Convert boolean to string
        // formData.append('direct', String(value.preferences.direct)); // Convert boolean to string
        formData.append('isRegular', String(value.preferences.regular));

        try {
            console.log('update demat')
            await dispatch(updatePreference(formData));
            onSave();
        } catch (error:any) {
            toast.error('Error updating preference:', error);
        } finally {
            console.log('false')
           
        }

}





    if (!users) return null;

    const handleSubmit = async (values: Partial<User>, { setSubmitting }: any) => {
        console.log('outside the try block');
        try {
            await editUser(values);
            await editAccount(values);
            await editNominee(values);
            await editDemat(values);
            await editPreference(values);
    
            toast.success('User data updated successfully');
            navigate(`/investor`); // Navigate to user profile page
        } catch (error: any) {
            console.error('Error updating user:', error);
            // Extract the error message from the error object
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            toast.error(`Error updating user data: ${errorMessage}`);
        } finally {
            setSubmitting(false);
        }
    };
    
    

         const handleDateChange = (dateValue:Date) => {
            console.log(dateValue, 'date of birth')
            const dob = dayjs(dateValue).format('DD/MM/YYYY')
            console.log(dob)
            return dob
        };

    // const setValues = (updater) => {
    //     // Assuming you are using useState for form values
    //     setFormValues((prevValues) => {
    //         const newValues = typeof updater === 'function' ? updater(prevValues) : updater;
    //         return { ...prevValues, ...newValues };
    //     });
    // };
    

    // const [values, setValues] = useState({
    //     preferences: {
    //         mutualFunds: 0,
    //         equity: 0,
    //         corporateFD: 0,
    //         bonds: 0,
    //         aif: 0,
    //         pms: 0,
    //         direct: 0,
    //         regular: 0,
    //     },
    // });
    
   
    const handleCheckboxChange = (event: any) => {
        const { name, checked } = event.target;
        const valueToSend = checked ? 1 : 0; // Convert boolean checked state to 1 or 0
        setValue(prevState => ({
            ...prevState,
            preferences: {
                ...prevState.preferences,
                [name]: valueToSend, // Update preference value based on checkbox state
            },
        }));
    };
    

    return (
       
        <Formik
        innerRef={formikRef}
        initialValues={users}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
            handleSubmit(values, actions);
          }}
        >
    
        {({ values, handleChange, handleSubmit, handleBlur, errors, isSubmitting, setFieldValue }) => (
                   <form
                   onSubmit={(e) => {
                     e.preventDefault();
                     console.log(values, 'values');
                     console.log(errors, 'errors');
                     handleSubmit();
                   }}
                 >
                         <>
                              <div style={{ display: 'flex', flexDirection: 'column', justifyContent:'space-between', justifyItems:'center', gap: '20px', width: '100%', maxWidth: '1400px' }}>
                              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <Typography variant="h4">User Details</Typography>
               <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap:'20px' }}>
                              <CommonInput
                                        type="text"
                                        label="ID"
                                        variant="outlined"
                                        name="id"
                                        value={values.id}
                                        onChange={(e: any) => console.log(e.target.value)} // Handle change
                                        id={'id'} disabled  />
    
                                <CommonInput
                                        type="text"
                                        label="First Name"
                                        variant="outlined"
                                         name="firstName"
                                        value={values.firstName}
                                        onChange={handleChange('firstName')} // Handle change
                                        id={'firstName'}  />
                                    </div>
                               <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap:'20px' }}>
                                    <CommonInput
                                        type="text"
                                        label="Last Name"
                                        variant="outlined"
                                         name="lastName"
                                        value={values.lastName}
                                        onChange={handleChange('lastName')} // Handle change
                                        id={'lastName'}                                    />
        
                                    <CommonInput
                                        type="text"
                                        label="Email"
                                        variant="outlined"
                                         name="email"
                                        value={values.email}
                                        onChange={handleChange('email')} // Handle change
                                        id={'email'}                                    />
                                </div>
                               <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap:'20px' }}>
                                <CommonInput
                                        type="number"
                                        label="Mobile"
                                        variant="outlined"
                                         name="mobile"
                                        value={values.mobile}
                                        onChange={handleChange('mobile')} // Handle change
                                        id={'mobile'}                                />
    
                                <CommonInput
                                    label="Date of Birth"
                                    variant="outlined"
                                    type="date"
                                     name="dateOfBirth"
                                    value={values.dateOfBirth}
                                    onChange={handleChange('dateOfBirth')} // Pass handleChange from Formik or your form library
                                    id="dateOfBirth"
                                />


    </div>
                                {/* Gender */}
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '20px' }}>

                                <FormControl style={{ width: '100%', justifyContent: 'center' }} variant="outlined">
                                    <InputLabel id="gender-label">Gender</InputLabel>
                                    <Select
                                        labelId="gender-status-label"
                                        id="gender"
                                        label="gender"
                                        name="gender"
                                        value={values.gender}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={0}>Female</MenuItem>
                                        <MenuItem value={1}>Male</MenuItem>
                                        <MenuItem value={2}>Others</MenuItem>
                                    </Select>
                                </FormControl>

                                <CommonInput
                                  
                                        type="text"
                                        label="BSE Star Client Code"
                                        variant="outlined"
                                         name="bseStarClientCode"
                                        value={values.bseStarClientCode}
                                        onChange={handleChange('bseStarClientCode')} // Handle change
                                        id={'bseStarClientCode'}                                />
                                </div>

    </div>
   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap:'20px' }}>
                                <CommonInput
                                    type="text"
                                    label="Address Line 1"
                                    variant="outlined"
                                     name="address1"
                                    value={values.address1}
                                    onChange={handleChange('address1')} // Handle change
                                    id={'address1'}                                />
    
                                <CommonInput
                                    type="text"
                                    label="Address Line 2"
                                    variant="outlined"
                                     name="address2"
                                    value={values.address2}
                                    onChange={handleChange('address2')} // Handle change
                                    id={'address2'}                                />
    </div>
   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap:'20px' }}>
                                <CommonInput
                                    type="text"
                                    label="Address Line 3"
                                    variant="outlined"
                                     name="address3"
                                    value={values.address3}
                                    onChange={handleChange('address3')} id={'address3'}                                />
    
                                {/* Address Type */}
                                <FormControl style={{ width: '100%', justifyContent: 'center' }} variant="outlined">
                                    <InputLabel id="address-type-label">Address Type</InputLabel>
                                    <Select
                                        labelId="address-type-label"
                                        id="addressType"
                                        label="Address Type"
                                        name="addressType"
                                        value={values.addressType}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={1}>Home</MenuItem>
                                        <MenuItem value={2}>Office</MenuItem>
                                        <MenuItem value={3}>Other</MenuItem>
                                    </Select>
                                </FormControl>
                                </div>
                               <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap:'20px' }}>
                                <CommonInput
                                    type="number"
                                    label="Pincode"
                                    variant="outlined"
                                    name="pincode"
                                    value={values.pincode}
                                    onChange={handleChange('pincode')} // Handle change
                                    id={'pincode'}                                />
    
                                <CommonInput
                                    type="text"
                                    label="City"
                                    variant="outlined"
                                    name="city"
                                    value={values.city}
                                    onChange={handleChange('city')} // Handle change
                                    id={'city'}                                />
    </div>
   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap:'20px' }}>
                                <CommonInput
                                    type="text"
                                    label="State"
                                    variant="outlined"
                                    name="state"
                                    value={values.state}
                                    onChange={handleChange('state')} // Handle change
                                    id={'state'}                                />
    
                                <CommonInput
                                    type="text"
                                    label="Last Transaction No"
                                    variant="outlined"
                                     name="lastTransactionNo"
                                    value={values.lastTransactionNo}
                                    onChange={handleChange('lastTransactionNo')} // Handle change
                                    id={'lastTransactionNo'}  disabled                              />
    </div>
   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap:'20px' }}>
                        <FormControl style={{ width: '100%', justifyContent: 'center' }} variant="outlined">
                                    <InputLabel id="onboard-status-label">Onboard Status</InputLabel>
                                    <Select
                                        labelId="onboard-status-label"
                                        id="onboardStatus"
                                        label="Onboard Status"
                                         name="onboardStatus"
                                        value={values.onboardStatus}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={0}>Onboarding</MenuItem>
                                        <MenuItem value={1}>Pending</MenuItem>
                                        <MenuItem value={2}>Review</MenuItem>
                                        <MenuItem value={3}>Verified</MenuItem>
                                    </Select>
                                </FormControl>
    
                                <FormControl style={{ width: '100%', justifyContent: 'center' }} variant="outlined">
                                    <InputLabel id="occupation-label">Occupation</InputLabel>
                                    <Select
                                        labelId="occupation-label"
                                        id="occupation"
                                        label="Occupation"
                                         name="occupation"
                                        value={values.occupation}
                                        onChange={handleChange}
                                    >
                                        {occupationOptions.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
    </div>
   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap:'20px' }}>
   <FormControl fullWidth variant="outlined" style={{ marginBottom: 16 }}>
                        <InputLabel id="occupation-type-label">Occupation Type</InputLabel>
                        <Select
                            labelId="occupation-type-label"
                            id="occupationType"
                            label="Occupation Type"
                            name="occupationType"
                            value={values.occupationType}
                            onChange={handleChange}
                        >
                            {occupationTypeOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                                
                                <FormControl style={{ width: '100%', justifyContent: 'center' }} variant="outlined">
                                        <InputLabel id="wealth-source-label">Wealth Source</InputLabel>
                                        <Select
                                            labelId="wealth-source-label"
                                            id="wealthSource"
                                            label="Wealth Source"
                                             name="wealthSource"
                                            value={values.wealthSource}
                                            onChange={handleChange}
                                        >
                                            {wealthSourceOptions.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
    </div>
   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap:'20px' }}>
                        <FormControl style={{ width: '100%', justifyContent: 'center' }} variant="outlined">
                                    <InputLabel id="income-slab-label">Income Slab</InputLabel>
                                    <Select
                                        labelId="income-slab-label"
                                        id="incomeSlab"
                                        label="Income Slab"
                                         name="incomeSlab"
                                        value={values.incomeSlab}
                                        onChange={handleChange}
                                    >
                                        {incomeSlab.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl style={{ width: '100%', justifyContent: 'center' }} variant="outlined">
                <InputLabel id="political-exposed-label">Political Exposed</InputLabel>
                <Select
                    labelId="political-exposed-label"
                    id="politicalExposed"
                    label="Political Exposed"
                     name="politicalExposed"
                    value={values.politicalExposed}
                    onChange={handleChange}
                >
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                </Select>
            </FormControl>
                                </div>
   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap:'20px' }}>
                    <FormControl style={{ width: '100%', justifyContent: 'center' }} variant="outlined">
                        <InputLabel id="is-nri-label">Is NRI?</InputLabel>
                        <Select
                            labelId="is-nri-label"
                            id="isNRI"
                            label="Is NRI?"
                             name="isNRI"
                            value={values.isNRI}
                            onChange={handleChange}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                    </FormControl>
    
                    <FormControl style={{ width: '100%', justifyContent: 'center' }} variant="outlined">
                <InputLabel id="is-kyc-done-label">Is KYC Done?</InputLabel>
                <Select
                    labelId="is-kyc-done-label"
                    id="isKycDone"
                    label="Is KYC Done?"
                     name="isKycDone"
                    value={values.isKycDone}
                    onChange={handleChange}
                >
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                </Select>
            </FormControl>
    </div>
   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap:'20px' }}>
   <FormControl style={{ width: '100%', justifyContent: 'center' }} variant="outlined">
                <InputLabel id="is-nominee-add-label">Is Nominee Add?</InputLabel>
                <Select
                    labelId="is-nominee-add-label"
                    id="isNomineeAdd"
                    label="Is Nominee Add?"
                    name="isNomineeAdd"
                    value={values.isNomineeAdd}
                    onChange={handleChange}
                >
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                </Select>
            </FormControl>

            <FormControl style={{ width: '100%', justifyContent: 'center' }} variant="outlined">
                <InputLabel id="is-bank-add-label">Is Bank Add?</InputLabel>
                <Select
                    labelId="is-bank-add-label"
                    id="isBankAdd"
                    label="Is Bank Add?"
                    name="isBankAdd"
                    value={values.isBankAdd}
                    onChange={handleChange}
                >
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                </Select>
            </FormControl>
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap:'20px' }}>
                <FormControl style={{ width: '100%', justifyContent: 'center' }} variant="outlined">
                    <InputLabel id="isDematAdd-label">Is Demat Add</InputLabel>
                    <Select
                        labelId="isDematAdd-label"
                        id="isDematAdd"
                        name="isDematAdd"
                        value={values.isDematAdd}
                        onChange={handleChange}
                        label="Is Demat Add"
                    >
                       
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </Select>
                </FormControl>

                <FormControl style={{ width: '100%', justifyContent: 'center' }} variant="outlined">
                    <InputLabel id="isSignatureAdd-label">Is Signature Add</InputLabel>
                    <Select
                        labelId="isSignatureAdd-label"
                        id="isSignatureAdd"
                        name="isSignatureAdd"
                        value={values.isSignatureAdd}
                        onChange={handleChange}
                        label="Is Signature Add"
                    >
                         <MenuItem value={1}>Yes</MenuItem>
                         <MenuItem value={0}>No</MenuItem>
                    </Select>
                </FormControl>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '50%', gap: '20px' }}>
                <FormControl style={{ width: '100%' }} variant="outlined">
                    <InputLabel id="isPasswordAdd-label">Is Password Add</InputLabel>
                    <Select
                        labelId="isPasswordAdd-label"
                        id="isPasswordAdd"
                        name="isPasswordAdd"
                        value={values.isPasswordAdd}
                        onChange={handleChange}
                        label="Is Password Add"
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </Select>
                </FormControl>
            </div>



    {/* <Typography variant="h4">KYC Details</Typography>

    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap:'20px' }}>
    <CommonInput
                                    type="string"
                                    label="KYC Id"
                                    variant="outlined"
                                    name="kyc.id"
                                    value={values.kyc.id}
                                    onChange={handleChange('kyc.id')} // Handle change
                                    id={'kyc.id'}                                />

                                  <CommonInput
                                    type="string"
                                    label="Aadhar Number"
                                    variant="outlined"
                                    name="kyc.aadharNumber"
                                    value={values.kyc.aadharNumber}
                                    onChange={handleChange('kyc.aadharNumber')} // Handle change
                                    id={'kyc.aadharNumber'}                                />
    
    </div>

   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap:'20px' }}>
                              

                                <CommonInput
                                   
                                    label="Aadhar Mobile Number"
                                    variant="outlined"
                                    name="kyc.aadharMobileNumber"
                                    value={values.kyc.aadharMobileNumber}
                                    onChange={handleChange('kyc.aadharMobileNumber')} // Handle change
                                    id={''} type={''}                                />
                                    
                                    <CommonInput
                                    label="Aadhar Card"
                                    variant="outlined"
                                    name="kyc.aadharCard"
                                    value={values.kyc.aadharCard}
                                    onChange={handleChange('kyc.aadharCard')} // Handle change
                                    id="aadharCard" type='file'                               />
                              
    </div>
   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap:'20px' }}>
  

                                <CommonInput
                                    type="string"
                                    label="Aadhar Verified"
                                    variant="outlined"
                                    name="kyc.isAadharVerified"
                                    value={values.kyc.isAadharVerified}
                                    onChange={handleChange('kyc.isAadharVerified')} // Handle change
                                    id={''}                                />
      <CommonInput
                                    type="text"
                                    label="Pancard Number"
                                    variant="outlined"
                                    name="kyc.pancardNumber"
                                    value={values.kyc.pancardNumber}
                                    onChange={handleChange('kyc.pancardNumber')} // Handle change
                                    id={'panCard'}                                />
                               
    </div>
   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap:'20px' }}>
 
                                <CommonInput
                                    type="string"
                                    label="Pancard Mobile Number"
                                    variant="outlined"
                                    name="kyc.pancardMobileNumber"
                                    value={values.kyc.pancardMobileNumber}
                                    onChange={handleChange('kyc.pancardMobileNumber')} // Handle change
                                    id={'kyc.pancardMobileNumber'}                                />
    <CommonInput
                                    type="file"
                                    label="Pancard"
                                    variant="outlined"
                                    name="kyc.pancard"
                                    value={values.kyc.pancard}
                                    onChange={handleChange('kyc.pancard')} // Handle change
                                    id={'panCard'}                                />
                               
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '50%', gap:'20px' }}>
    
                                <CommonInput
                                    type="number"
                                    label="Pan Verified"
                                    variant="outlined"
                                    name="kyc.isPanVerified"
                                    value={values.kyc.isPanVerified}
                                    onChange={handleChange('kyc.isPanVerified')} // Handle change
                                    id={'kyc.isPanVerified'}                                />
    </div>
    
     */}
    
    
    <Typography variant="h4">Nominee Details</Typography>
{values.nominee.map((nominee, index) => (
    <React.Fragment key={index}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '20px' }}>
            <CommonInput type="text" label="Nominee Name" variant="outlined" name={`nominee[${index}].name`} value={nominee.name} onChange={handleChange(`nominee[${index}].name`)} placeholder="Nominee Name" id={`nominee[${index}].name`} />
            <CommonInput type="text" label="Mobile Number" variant="outlined" name={`nominee[${index}].mobileNumber`} value={nominee.mobileNumber} onChange={handleChange(`nominee[${index}].mobileNumber`)} placeholder="Mobile Number" id={`nominee[${index}].mobileNumber`} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '20px' }}>
            <CommonInput type="email" label="Email" variant="outlined" name={`nominee[${index}].email`} value={nominee.email} onChange={handleChange(`nominee[${index}].email`)} placeholder="Email" id={`nominee[${index}].email`} />
            <CommonInput type="text" label="Relation" variant="outlined" name={`nominee[${index}].relation`} value={nominee.relation} onChange={handleChange(`nominee[${index}].relation`)} placeholder="Relation" id={`nominee[${index}].relation`} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '20px' }}>
            <CommonInput
                type="date"
                label="Date of Birth"
                variant="outlined"
                name={`nominee[${index}].dateOfBirth`}
                value={nominee.dateOfBirth} // Format the value using formatDate function
                onChange={handleChange(`nominee[${index}].dateOfBirth`)}
                id={`nominee[${index}].dateOfBirth`}
            />
            <CommonInput type="text" label="Percentage" variant="outlined" name={`nominee[${index}].percentage`} value={nominee.percentage} onChange={handleChange(`nominee[${index}].percentage`)} placeholder="Percentage" id={`nominee[${index}].percentage`} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '20px' }}>
            <CommonInput type="text" label="Nominee Address" variant="outlined" name={`nominee[${index}].nomineeAddress`} value={nominee.nomineeAddress} onChange={handleChange(`nominee[${index}].nomineeAddress`)} placeholder="Nominee Address" id={`nominee[${index}].nomineeAddress`} />
            <CommonInput type="text" label="Pincode" variant="outlined" name={`nominee[${index}].pincode`} value={nominee.pincode} onChange={handleChange(`nominee[${index}].pincode`)} placeholder="Pincode" id={`nominee[${index}].pincode`} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '20px' }}>
            <CommonInput type="number" label="Is Minor" variant="outlined" name={`nominee[${index}].isMinor`} value={nominee.isMinor} onChange={handleChange(`nominee[${index}].isMinor`)} placeholder="Is Minor" id={`nominee[${index}].isMinor`} />
            <CommonInput type="text" label="Guardian Details" variant="outlined" name={`nominee[${index}].guardianDetails`} value={nominee.guardianDetails} onChange={handleChange(`nominee[${index}].guardianDetails`)} placeholder="Guardian Details" id={`nominee[${index}].guardianDetails`} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '20px' }}>
            <CommonInput type="text" label="Aadhar Number" variant="outlined" name={`nominee[${index}].aadharNumber`} value={nominee.aadharNumber} onChange={handleChange(`nominee[${index}].aadharNumber`)} placeholder="Aadhar Number" id={`nominee[${index}].aadharNumber`} />
            <CommonInput type="file" label="Aadhar Card" variant="outlined" name={`nominee[${index}].aadharCard`} onChange={handleFileChange(index, 'aadharCard')} placeholder="Aadhar Card" id={`nominee[${index}].aadharCard`} value={undefined} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '20px' }}>
            <CommonInput type="text" label="Pancard Number" variant="outlined" name={`nominee[${index}].pancardNumber`} value={nominee.pancardNumber} onChange={handleChange(`nominee[${index}].pancardNumber`)} placeholder="Pancard Number" id={`nominee[${index}].pancardNumber`} />
            <CommonInput type="file" label="Pancard" variant="outlined" name={`nominee[${index}].pancard`} onChange={handleFileChange(index, 'pancard')} placeholder="Pancard" id={`nominee[${index}].pancard`} value={undefined} />
        </div>
    </React.Fragment>
))}


<Typography variant="h4">Account Details</Typography> 
{values.bank.map((bank, index) => (
 <>
 
      <Typography variant="h5">ID: {bank.id} Details</Typography>  
      <div key={index}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '20px' }}>
        <FormControl style={{ width: '100%', justifyContent: 'center' }} variant="outlined">
                                    <InputLabel id="account-type-label">Account Type</InputLabel>
                                    <Select
                                        labelId="account-type-label"
                                        id="accountType"
                                        label="Account Type"
                                        name={`bank[${index}].accountType`}
                                        value={bank.accountType}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="0">Savings</MenuItem>
                                        <MenuItem value="1">Current</MenuItem>
                                        <MenuItem value="2">NRE</MenuItem>
                                        <MenuItem value="3">NRO</MenuItem>
                                    </Select>
                                </FormControl> 
    
          <CommonInput
            type="string"
            label="Bank Name"
            variant="outlined"
            name={`bank[${index}].bankName`}
            value={bank.bankName}
            onChange={(e) => handleChange(`bank[${index}].bankName`)(e.target.value)}
            id={`bank[${index}].bankName`}
          />
        </div>
    
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '20px' }}>
          <CommonInput
            type="string"
            label="Account Number"
            variant="outlined"
            name={`bank[${index}].accountNumber`}
            value={bank.accountNumber}
            onChange={(e) => handleChange(`bank[${index}].accountNumber`)(e.target.value)}
            id={`bank[${index}].accountNumber`}
          />
    
          <CommonInput
            type="string"
            label="IFSC Code"
            variant="outlined"
            name={`bank[${index}].ifscCode`}
            value={bank.ifscCode}
            onChange={(e) => handleChange(`bank[${index}].ifscCode`)(e.target.value)}
            id={`bank[${index}].ifscCode`}
          />
        </div>
    
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '20px' }}>
          <CommonInput
            type="string"
            label="Bank Branch"
            variant="outlined"
            name={`bank[${index}].bankBranch`}
            value={bank.bankBranch}
            onChange={(e) => handleChange(`bank[${index}].bankBranch`)(e.target.value)}
            id={`bank[${index}].bankBranch`}
          />
    
          <CommonInput
            type="string"
            label="Bank Pin Code"
            variant="outlined"
            name={`bank[${index}].bankPinCode`}
            value={bank.bankPinCode}
            onChange={(e) => handleChange(`bank[${index}].bankPinCode`)(e.target.value)}
            id={`bank[${index}].bankPinCode`}
          />
        </div>
    
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '20px' }}>
          <CommonInput
            type="string"
            label="Bank MICR"
            variant="outlined"
            name={`bank[${index}].bankMICR`}
            value={bank.bankMICR}
            onChange={(e) => handleChange(`bank[${index}].bankMICR`)(e.target.value)}
            id={`bank[${index}].bankMICR`}
          />
    
          <CommonInput
            type="string"
            label="Bank Address"
            variant="outlined"
            name={`bank[${index}].bankAddress`}
            value={bank.bankAddress}
            onChange={(e) => handleChange(`bank[${index}].bankAddress`)(e.target.value)}
            id={`bank[${index}].bankAddress`}
          />
        </div>
    
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '50%', gap: '20px' }}>
          <CommonInput
            type="string"
            label="Cancel Cheque"
            variant="outlined"
            name={`bank[${index}].cancelCheque`}
            value={bank.cancelCheque}
            onChange={(e) => handleChange(`bank[${index}].cancelCheque`)(e.target.value)}
            id={`bank[${index}].cancelCheque`}
          />
        </div>
      </div>
 </>
))}


<Typography variant="h4">Demat Details</Typography>
   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap:'20px' }}>
   <CommonInput
                                    type="string"
                                    label="DP ID"
                                    variant="outlined"
                                    name="demat.dpId"
                                    value={values.demat.dpId}
                                    onChange={handleChange('demat.dpId')} // Handle change
                                    id={'demat.dpId'}                                />
                                <CommonInput
                                    type="string"
                                    label="Client ID"
                                    variant="outlined"
                                    name="demat.clientId"
                                    value={values.demat.clientId}
                                    onChange={handleChange('demat.clientId')} // Handle change
                                    id={'demat.clientId'}                                />
    
                               
    </div>
   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap:'20px' }}>
   <CommonInput
                                    type="string"
                                    label="Depository Name"
                                    variant="outlined"
                                    name="demat.depositoryName"
                                    value={values.demat.depositoryName}
                                    onChange={handleChange('demat.depositoryName')} // Handle change
                                    id={'demat.depositoryName'}                                />
                                <CommonInput
                                    type="string"
                                    label="CML File"
                                    variant="outlined"
                                    name="demat.cmlFile"
                                    value={values.demat.cmlFile}
                                    onChange={handleChange('demat.cmlFile')} // Handle change
                                    id={'demat.cmlFile'}                                />
    
                               
    </div>

        <Typography variant="h4">Preferences Details</Typography>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '20px' }}>
        <FormControl component="fieldset" style={{ width: '100%' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value.preferences.mutualFunds}
                        value={values.preferences.mutualFunds}
                        onChange={handleCheckboxChange}
                        name="mutualFunds"
                        color="primary"
                    />
                }
                label="Mutual Funds"
            />
        </FormControl>
        <FormControl component="fieldset" style={{ width: '100%' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value.preferences.equity}
                        value={values.preferences.equity}
                        onChange={handleCheckboxChange}
                        name="equity"
                        color="primary"
                    />
                }
                label="Equity"
            />
        </FormControl>
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '20px' }}>
        <FormControl component="fieldset" style={{ width: '100%' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value.preferences.corporateFD}
                        value={values.preferences.corporateFD }
                        onChange={handleCheckboxChange}
                        name="corporateFD"
                        color="primary"
                    />
                }
                label="Corporate FD"
            />
        </FormControl>
        <FormControl component="fieldset" style={{ width: '100%' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value.preferences.bonds}
                        value={values.preferences.bonds}
                        onChange={handleCheckboxChange}
                        name="bonds"
                        color="primary"
                    />
                }
                label="Bonds"
            />
        </FormControl>
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '20px' }}>
        <FormControl component="fieldset" style={{ width: '100%' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value.preferences.aif}
                        value={values.preferences.aif}
                        onChange={handleCheckboxChange}
                        name="aif"
                        color="primary"
                    />
                }
                label="AIF"
            />
        </FormControl>
        <FormControl component="fieldset" style={{ width: '100%' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value.preferences.pms}
                        value={values.preferences.pms}
                        onChange={handleCheckboxChange}
                        name="pms"
                        color="primary"
                    />
                }
                label="PMS"
            />
        </FormControl>
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '20px' }}>
        {/* <FormControl component="fieldset" style={{ width: '100%' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value.preferences.direct}
                        value={values.preferences.direct}
                        onChange={handleCheckboxChange}
                        name="direct"
                        color="primary"
                    />
                }
                label="Direct"
            />
        </FormControl> */}
        <FormControl component="fieldset" style={{ width: '100%' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value.preferences.regular}
                        value={values.preferences.regular}
                        onChange={handleCheckboxChange}
                        name="regular"
                        color="primary"
                    />
                }
                label="Regular"
            />
        </FormControl>
    </div>

    </div>

                                    </>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                        <Button type='submit' variant="contained" color="primary" >
                                            Save
                                        </Button>
                                        <Button variant="contained" onClick={handleDownload}>Download User Data</Button>
                                    </div>
                        </form>
                    )}
                </Formik>
    );
};

export default UserDetailsEditForm;
