import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';

interface DematDetails {
    id: number;
    dematAccountNumber: string;
    dematBalance: number;
    dematType: string;
    dematStatus: string;
}

interface DematDetailsEditFormProps {
    open: boolean;
    dematDetail: DematDetails;
    onSave: (editedDetail: DematDetails) => void;
    onCancel: () => void;
}

const DematDetailsEditForm: React.FC<DematDetailsEditFormProps> = ({ open, dematDetail, onSave, onCancel }) => {
    const [editedDetail, setEditedDetail] = useState<DematDetails>(dematDetail);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditedDetail({ ...editedDetail, [name]: value });
    };

    const handleSave = () => {
        onSave(editedDetail);
    };

    const handleClose = () => {
        onCancel();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit Demat Details</DialogTitle>
            <DialogContent>
                <TextField
                    label="Account Number"
                    name="dematAccountNumber"
                    value={editedDetail.dematAccountNumber}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Balance"
                    name="dematBalance"
                    value={editedDetail.dematBalance}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Type"
                    name="dematType"
                    value={editedDetail.dematType}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Status"
                    name="dematStatus"
                    value={editedDetail.dematStatus}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DematDetailsEditForm;
