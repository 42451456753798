import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, Box } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

interface UserDetailsProps {
    id: number;
    name: string;
    email: string;
    mobile: string;
}

interface NomineeDetailsProps {
    id: number;
    userId: number;
    nomineeName: string;
    relation: string;
    aadharNumber: string;
    panDetails: string;
    dob: string;
    address: string;
    user?: UserDetailsProps;
}

interface NomineeDetailsEditModalProps {
    open: boolean;
    nominee?: NomineeDetailsProps; // Make nominee prop optional
    onSave: (nominee: NomineeDetailsProps) => void;
    onClose: () => void;
}

const NomineeDetailsEditModal: React.FC<NomineeDetailsEditModalProps> = ({ open, nominee, onSave, onClose }) => {
    const [formValues, setFormValues] = useState<NomineeDetailsProps>({
        id: 0,
        userId: 0,
        nomineeName: '',
        relation: '',
        aadharNumber: '',
        panDetails: '',
        dob: '',
        address: '',
    });

    // Update formValues when nominee prop changes
    useEffect(() => {
        if (nominee) {
            setFormValues(nominee);
        }
    }, [nominee]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    };

    const handleSaveClick = () => {
        onSave(formValues);
        onClose();
    };

    const handleCancelClick = () => {
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Edit Nominee Details</DialogTitle>
            <DialogContent>
                <form noValidate autoComplete="off">
                    <TextField
                        label="Nominee Name"
                        name="nomineeName"
                        value={formValues.nomineeName}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Relation"
                        name="relation"
                        value={formValues.relation}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Aadhar Number"
                        name="aadharNumber"
                        value={formValues.aadharNumber}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="PAN Details"
                        name="panDetails"
                        value={formValues.panDetails}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Date of Birth"
                        name="dob"
                        value={formValues.dob}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Address"
                        name="address"
                        value={formValues.address}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <Box mt={2} textAlign="right">
                        <Button
                            color="primary"
                            onClick={handleSaveClick}
                        >
                            Save
                        </Button>
                        <Button
                            color="secondary"
                            onClick={handleCancelClick}
                            style={{ marginLeft: '1rem' }}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default NomineeDetailsEditModal;
