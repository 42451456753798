import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Typography,
  TablePagination,
  TableFooter,
  InputAdornment,
} from "@mui/material";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { colors } from "../../constants/colors";

interface User {
  id: number;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  mobile: string;
  logInBy: number;
  onboardStatus: number;
  createdAt: string;
}

interface UserTableProps {
  users: User[];
  editUser: User | null;
  onEdit: (user: User) => void;
  onSave: () => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCancel: () => void;
  totalPages: number; // Total number of users
  pageSize: number; // Current page size
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void; // Page change handler
  onPageSizeChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void; // Page size change handler
  currentPage: number; // Current page number
}

const UserTable: React.FC<UserTableProps> = ({
  users = [],
  editUser,
  onEdit,
  onSave,
  onChange,
  onCancel,
  totalPages,
  currentPage,
  pageSize,
  onPageChange,
  onPageSizeChange,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const userType = localStorage?.getItem("userType");

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const filteredUsers = Array.isArray(users)
    ? users.filter(
        (user) =>
          user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.mobile.includes(searchQuery)
      )
    : [];

  console.log("Filtered Users: ", filteredUsers);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "20px",
      }}
    >
      <Box
        width="90%"
        mb={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" style={{ textAlign: "left" }}>
          Investor Details
        </Typography>
        {userType === "4" && (
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`/addDetails`}
          >
            Add Investor
          </Button>
        )}
      </Box>
      <Box
        width="90%"
        mb={2}
        sx={{ display: "flex", justifyContent: "flex-start" }}
      >
        {userType === "4" && (
          <div style={{ position: "relative", width: "800px" }}>
            <input
              type="text"
              placeholder="Search users"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{
                width: "100%",
                padding: "10px 20px 10px 45px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                fontSize: "16px",
              }}
            />
            <SearchIcon
              style={{
                position: "absolute",
                top: "50%",
                left: "15px",
                transform: "translateY(-50%)",
                color: "#888",
              }}
            />
          </div>
        )}
      </Box>

      <div style={{ display: "flex", justifyContent: "center", width: "90%" }}>
        <TableContainer
          component={Paper}
          style={{ width: "100%", marginTop: "1rem" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold", minWidth: 120 }}>
                  Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold", minWidth: 120 }}>
                  Email
                </TableCell>
                <TableCell style={{ fontWeight: "bold", minWidth: 120 }}>
                  Mobile
                </TableCell>
                <TableCell style={{ fontWeight: "bold", minWidth: 120 }}>
                  Onboarding Status
                </TableCell>
                <TableCell style={{ fontWeight: "bold", minWidth: 120 }}>
                  Registered At
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} style={{ textAlign: "center" }}>
                  <Typography variant="body2" color="textSecondary">
    No users assigned
  </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                filteredUsers.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>
                      <Button component={Link} to={`/userDetails/${user.id}`}>
                        {`${user.firstName} ${user.lastName ? user.lastName : ""}`}
                      </Button>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.mobile}</TableCell>
                    <TableCell>
                      {(() => {
                        switch (user.onboardStatus) {
                          case 1:
                            return "Pending";
                          case 2:
                            return "Review";
                          case 3:
                            return (
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color: colors.primary,
                                }}
                              >
                                Verified
                              </span>
                            );
                          default:
                            return "";
                        }
                      })()}
                    </TableCell>
                    <TableCell>{formatDate(user.createdAt)}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  count={totalPages * pageSize}
                  rowsPerPage={pageSize}
                  page={currentPage - 1}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onPageSizeChange}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${currentPage} – ${totalPages}`
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default UserTable;
