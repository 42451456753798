import authReducer from "../redux/auth/authSlice";
import userReducer from "../redux/user/userSlice"
import fdReducer from '../redux/fd/fdSlice'
import bankReducer from '../redux/bank/bankSlice'
import nomineeReducer from '../redux/nominee/nomineeSlice'
import dematReducer from '../redux/demat/dematSlice'
import preferenceReducer from "./preference/preferenceSlice";
import notificationReducer from "./notification/notificationSlice";
import aifReducer from "./portfolio/aif/aifSlice";
import changeStatusReducer  from "./portfolio/changeStatus/statusSlice";
import dashboardReducer from "./dashboard/dashboardSlice";

import { configureStore } from '@reduxjs/toolkit';




export const store = configureStore ({
    reducer: {
        auth: authReducer,
        user: userReducer,
        fd:fdReducer,
        bank:bankReducer,
        nominee:nomineeReducer,
        demat:dematReducer,
        preference:preferenceReducer,
        notification:notificationReducer,
        aif:aifReducer,
        status:changeStatusReducer,
        dashboard:dashboardReducer
      },
      middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})
