import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';

interface Transaction {
  transactionDate: string;
  folioNumber: string;
  amcCode: string;
  schemeName: string;
  category: string;
  units: number;
  purchaseNAV: number;
  amount: number;
  currentValue: number;
  currentNAV: number;
  orderType: number;
  schemeType: string;
  unrealisedPNL: number;
  unrealisedPNLPercentage: number;
  closingBalance: number;
  costValue: number;
  fromMfCentral: number;
}

interface TransactionTableProps {
  startDate: number | null;
  endDate: number | null;
  fundType: string;
  mfData: Transaction[]; // Add mfData as a prop
}

const TransactionTable: React.FC<TransactionTableProps> = ({ startDate, endDate, fundType, mfData }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Transaction Date</TableCell>
            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Folio Number</TableCell>
            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>AMC Code</TableCell>
            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Scheme Name</TableCell>
            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Category</TableCell>
            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Units</TableCell>
            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Purchase NAV</TableCell>
            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Amount</TableCell>
            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Current Value</TableCell>
            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Current NAV</TableCell>
            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Order Type</TableCell>
            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Scheme Type</TableCell>
            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Unrealised PNL</TableCell>
            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Unrealised PNL %</TableCell>
            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Closing Balance</TableCell>
            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Cost Value</TableCell>
            <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>From MF Central</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mfData.map((transaction, index) => (
            <TableRow key={index}>
              <TableCell>{transaction.transactionDate ? new Date(transaction.transactionDate).toLocaleDateString() : ''}</TableCell>
              <TableCell>{transaction.folioNumber}</TableCell>
              <TableCell>{transaction.amcCode}</TableCell>
              <TableCell>{transaction.schemeName}</TableCell>
              <TableCell>{transaction.category}</TableCell>
              <TableCell>{transaction.units}</TableCell>
              <TableCell>{transaction.purchaseNAV}</TableCell>
              <TableCell>{transaction.amount}</TableCell>
              <TableCell>{transaction.currentValue}</TableCell>
              <TableCell>{transaction.currentNAV}</TableCell>
              <TableCell>{transaction.orderType}</TableCell>
              <TableCell>{transaction.schemeType}</TableCell>
              <TableCell>{transaction.unrealisedPNL}</TableCell>
              <TableCell>{transaction.unrealisedPNLPercentage}</TableCell>
              <TableCell>{transaction.closingBalance}</TableCell>
              <TableCell>{transaction.costValue}</TableCell>
              <TableCell>{transaction.fromMfCentral === 1 ? 'Approved' : 'Not Approved'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransactionTable;
