import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import preferenceService from "./preferenceService";


export const updatePreference = createAsyncThunk(
    "preference/update",
    async (data: FormData, thunkAPI: any) => {
      try {

        const response = await preferenceService.updatePreferences(data);
        console.log(response, ' this is the update nominee response');
        return response

      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  )

  const initialState = {
    data: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  };

  export const preferenceSlice = createSlice({
    name: "preference",
    initialState: initialState,
    reducers: {
      resetState: (state) => initialState,
    },
    extraReducers: (builder) => {
      builder
        .addCase(updatePreference.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(updatePreference.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.data = action.payload.data;
          console.log(state.data, ' this is demat slice')
        })
        .addCase(updatePreference.rejected, (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = action.error.message || "";
        })
    },
  });
  
  export const { resetState } = preferenceSlice.actions;
  export default preferenceSlice.reducer;