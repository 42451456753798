import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Box,
    Typography,
    InputAdornment,
    TextField,
    TablePagination,
    TableFooter,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import NomineeDetailsEditModal from './NomineeEditingForm';
import { Search } from '@mui/icons-material';

interface UserDetailsProps {
    id: number;
    name: string;
    email: string;
    mobile: string;
}

interface NomineeDetailsProps {
    id: number;
    userId: number;
    nomineeName: string;
    relation: string;
    aadharNumber: string;
    panDetails: string;
    dob: string;
    address: string;
    user?: UserDetailsProps;
}

interface NomineeDetailsTableProps {
    nomineeDetails: NomineeDetailsProps[];
    onEdit: (nominee: NomineeDetailsProps) => void;
    data: NomineeDetailsProps[]
}

const NomineeDetailsTable: React.FC<NomineeDetailsTableProps> = ({ onEdit, data }) => {
    const [editingNominee, setEditingNominee] = useState<NomineeDetailsProps | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filteredData, setFilteredData] = useState<NomineeDetailsProps[]>(data);

    const handleEditClick = (nominee: NomineeDetailsProps) => {
        setEditingNominee(nominee);
    };

    const handleSave = (editedNominee: NomineeDetailsProps) => {
        onEdit(editedNominee);
        setEditingNominee(null);
    };

    const handleCancel = () => {
        setEditingNominee(null);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearchTerm(searchTerm);

        const filteredData = data.filter(
            (row) =>
                row.nomineeName.toLowerCase().includes(searchTerm) ||
                row.relation.toLowerCase().includes(searchTerm) ||
                row.aadharNumber.toLowerCase().includes(searchTerm) ||
                row.panDetails.toLowerCase().includes(searchTerm) ||
                row.dob.toLowerCase().includes(searchTerm) ||
                row.address.toLowerCase().includes(searchTerm)
        );
        setFilteredData(filteredData);
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box width="100%" display="flex" flexDirection="column" alignItems="center" marginBottom={2}>
            <Box width="90%" mb={2} display="flex" justifyContent="space-between">
                <Typography variant="h5">Nominee Details</Typography>
                <TextField
                    size="small"
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <div style={{ display: 'flex', justifyContent: 'center', width: '90%' }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Investor Name</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Nominee Name</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Investor Email</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Investor Mobile</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Relation</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Nominee Aadhar Number</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Nominee PAN Details</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Date of Birth</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Address</TableCell>
                                <TableCell style={{ fontWeight: 'bold', minWidth: 120 }}>Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.user?.name}</TableCell>
                                    <TableCell>{row.nomineeName}</TableCell>
                                    <TableCell>{row.user?.email}</TableCell>
                                    <TableCell>{row.user?.mobile}</TableCell>
                                    <TableCell>{row.relation}</TableCell>
                                    <TableCell>{row.aadharNumber}</TableCell>
                                    <TableCell>{row.panDetails}</TableCell>
                                    <TableCell>{row.dob}</TableCell>
                                    <TableCell>{row.address}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEditClick(row)}>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    count={filteredData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
            <NomineeDetailsEditModal
                open={!!editingNominee}
                nominee={editingNominee || undefined}
                onSave={handleSave}
                onClose={handleCancel}
            />
        </Box>
    );
};

export default NomineeDetailsTable;
