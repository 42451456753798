import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled, useTheme } from "@mui/material/styles";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Collapse,
} from "@mui/material";
import { Link } from "react-router-dom"; // Assuming you're using React Router for navigation
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { colors } from "../../constants/colors";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

interface PersistentDrawerLeftProps {
  toggleDrawer: () => void;
  open: boolean;
}

const DrawerLeft: React.FC<PersistentDrawerLeftProps> = ({
  toggleDrawer,
  open,
}) => {
  const theme = useTheme();
  const userType = localStorage?.getItem("userType");
  const handleDrawerClose = () => {
    toggleDrawer();
  };

  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [mfSubMenuOpen, setMfSubMenuOpen] = useState(false);

  // Function to toggle submenu open/close
  const toggleSubMenu = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const toggleMfSubMenu = () => {
    setMfSubMenuOpen(!mfSubMenuOpen);
  };

    // State for toggling FD, AIF and PMS submenus
    const [aifOpen, setAifOpen] = useState(false);
    const [pmsOpen, setPmsOpen] = useState(false);
    const [fdOpen, setFDOpen] = useState(false);
  
    // Toggles for AIF and PMS
    const toggleAifSubMenu = () => {
      setAifOpen(!aifOpen);
    };
  
    const togglePmsSubMenu = () => {
      setPmsOpen(!pmsOpen);
    };

    const toggleFDSubMenu = () => {
      setFDOpen(!fdOpen);
    };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {userType === "4" ? (
          <List>
            {/* Requests*/}

            {/* <ListItem button>
            <Typography variant="h6" sx={{ fontSize: '12px', color:colors.primary }}>
              <ListItemText primary="Requests" />
            </Typography>
          </ListItem>
         
         <div className='ml-5'>

      <ListItem button component={Link} to="/masters/fixed-deposits">
                  <ListItemText primary="Fixed Deposits" />
                </ListItem>
                <ListItem button component={Link} to="/masters/bonds">
                  <ListItemText primary="Bonds" />
                </ListItem>
         </div> */}

            {/* Reports */}

            {/* Masters */}
            {/* <ListItem button>
            <Typography variant="h6" sx={{ fontSize: '12px', color:colors.primary }}>
              <ListItemText primary="Masters" />
            </Typography>
          </ListItem>

        <div className='ml-5'>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/masters/fixed-deposits">
                  <ListItemText primary="Fixed Deposits" />
                </ListItem>
                <ListItem button component={Link} to="/masters/bonds">
                  <ListItemText primary="Bonds" />
                </ListItem>
                <ListItem button component={Link} to="/masters/mutual-funds">
                  <ListItemText primary="Mutual Funds" />
                </ListItem>
                <ListItem button component={Link} to="/masters/common">
                  <ListItemText primary="Common" />
                </ListItem>
                <ListItem button component={Link} to="/masters/permissions">
                  <ListItemText primary="Permissions" />
                </ListItem>
              </List>
        </div> */}
            <ListItem button>
              <Typography
                variant="h6"
                sx={{ fontSize: "12px", color: colors.primary }}
              >
                <ListItemText primary="Investor" />
              </Typography>
            </ListItem>
            {/* Submenu for Masters */}
            <div className="ml-5">
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/investor">
                  <ListItemText primary="Add Investors" />
                </ListItem>
                <ListItem button component={Link} to="/assign-rm">
                  <ListItemText primary="Assign RM" />
                </ListItem>
                {/* <ListItem button component={Link} to="/investor/nominee">
                  <ListItemText primary="Nominee Details" />
                </ListItem>
                <ListItem button component={Link} to="/investor/demat">
                  <ListItemText primary="Demat Details" />
                </ListItem>
                <ListItem button component={Link} to="/investor/agent">
                  <ListItemText primary="Agent Master" />
                </ListItem> */}
              </List>
            </div>
            <ListItem button>
              <Typography
                variant="h6"
                sx={{ fontSize: "12px", color: colors.primary }}
              >
                <ListItemText primary="Relation Manager" />
              </Typography>
            </ListItem>
            {/* Submenu for Masters */}
            <div className="ml-5">
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/rm">
                  <ListItemText primary="RM Details" />
                </ListItem>
              </List>
            </div>
            <ListItem button>
              <Typography
                variant="h6"
                sx={{ fontSize: "12px", color: colors.primary }}
              >
                <ListItemText primary="Upload" />
              </Typography>
            </ListItem>
            {/* Submenu for Uploads */}
            <div className="ml-5">
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/upload/file-aif">
                  <ListItemText primary="AIF" />
                </ListItem>
                <ListItem button component={Link} to="/upload/file-pms">
                  <ListItemText primary="PMS" />
                </ListItem>
                <ListItem button disabled component={Link} to="/upload/file-bonds">
                  <ListItemText primary="Bonds" />
                </ListItem>
              </List>
            </div>
          </List>
        ) : (
          <List>
            {/* Reports */}

            <ListItem button>
              <Typography
                variant="h6"
                sx={{ fontSize: "12px", color: colors.primary }}
              >
                <ListItemText primary="Requests" />
              </Typography>
            </ListItem>
            {/* Submenu for Reports */}
            <div className="ml-5">
              <List component="div" disablePadding>
              <ListItem button onClick={toggleFDSubMenu}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "12px", color: colors.primary }}
                  >
                    <ListItemText primary="Fixed Deposits" />
                  </Typography>
                  {fdOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={fdOpen} timeout="auto" unmountOnExit sx={{marginLeft:2}}>
                  <List component="div" disablePadding>
                    <ListItem button component={Link} to="/reports/fd/buy">
                      <ListItemText primary="Buy" />
                    </ListItem>
                    <ListItem button component={Link} to="/reports/fd/sell">
                      <ListItemText primary="Sell" />
                    </ListItem>
                  </List>
                </Collapse>
                <div>
                  <List component="div" disablePadding>
                    <ListItem button component={Link} to="/reports/mf">
                      <ListItemText primary="Mutual Funds" />
                    </ListItem>
                  </List>
                </div>
                {/* <ListItem button>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "12px", color: colors.primary }}
                  >
                    <ListItemText primary="AIF" />
                  </Typography>
                </ListItem>
                <div className="ml-5">
                  <List component="div" disablePadding>
                    <ListItem button component={Link} to="/aif">
                      <ListItemText primary="Buy" />
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem button component={Link} to="/aif">
                      <ListItemText primary="Sell" />
                    </ListItem>
                  </List>
                </div>
                <ListItem button>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "12px", color: colors.primary }}
                  >
                    <ListItemText primary="PMS" />
                  </Typography>
                </ListItem>
                <div className="ml-5">
                  <List component="div" disablePadding>
                    <ListItem button component={Link} to="/pms">
                      <ListItemText primary="Buy" />
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem button component={Link} to="/pms">
                      <ListItemText primary="Sell" />
                    </ListItem>
                  </List>
                </div> */}
                <ListItem button onClick={toggleAifSubMenu}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "12px", color: colors.primary }}
                  >
                    <ListItemText primary="AIF" />
                  </Typography>
                  {aifOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={aifOpen} timeout="auto" unmountOnExit sx={{marginLeft:2}}>
                  <List component="div" disablePadding>
                    <ListItem button component={Link} to="/aif/buy">
                      <ListItemText primary="Buy" />
                    </ListItem>
                    <ListItem button component={Link} to="/aif/sell">
                      <ListItemText primary="Sell" />
                    </ListItem>
                  </List>
                </Collapse>

                <ListItem button onClick={togglePmsSubMenu}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "12px", color: colors.primary }}
                  >
                    <ListItemText primary="PMS" />
                  </Typography>
                  {pmsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={pmsOpen} timeout="auto" unmountOnExit sx={{marginLeft:2}}>
                  <List component="div" disablePadding>
                    <ListItem button component={Link} to="/pms/buy">
                      <ListItemText primary="Buy" />
                    </ListItem>
                    <ListItem button component={Link} to="/pms/sell">
                      <ListItemText primary="Sell" />
                    </ListItem>
                  </List>
                </Collapse>
                <div>
                  <List component="div" disablePadding>
                    <ListItem button component={Link} to="/bonds">
                      <ListItemText primary="Bond" />
                    </ListItem>
                  </List>
                </div>
                <ListItem button component={Link} to="/requests">
                  <Typography variant="h6" sx={{ fontSize: "12px" }}>
                    <ListItemText primary="Profile" />
                  </Typography>
                </ListItem>
              </List>
            </div>
            <ListItem onClick={toggleMfSubMenu}>
              <Typography
                variant="h6"
                sx={{ fontSize: "12px", color: colors.primary }}
              >
                <ListItemText primary="Transactions" />
              </Typography>
            </ListItem>

            <div className="ml-5">
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/reports/mf/transactions">
                  <ListItemText primary="Mutual Funds" />
                </ListItem>
              </List>
            </div>
            {/* Masters */}
            {/* <ListItem button>
            <Typography variant="h6" sx={{ fontSize: '12px', color:colors.primary }}>
              <ListItemText primary="Masters" />
            </Typography>
          </ListItem>

        <div className='ml-5'>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/masters/fixed-deposits">
                  <ListItemText primary="Fixed Deposits" />
                </ListItem>
                <ListItem button component={Link} to="/masters/bonds">
                  <ListItemText primary="Bonds" />
                </ListItem>
                <ListItem button component={Link} to="/masters/mutual-funds">
                  <ListItemText primary="Mutual Funds" />
                </ListItem>
                <ListItem button component={Link} to="/masters/common">
                  <ListItemText primary="Common" />
                </ListItem>
                <ListItem button component={Link} to="/masters/permissions">
                  <ListItemText primary="Permissions" />
                </ListItem>
              </List>
        </div> */}
            <ListItem button>
              <Typography
                variant="h6"
                sx={{ fontSize: "12px", color: colors.primary }}
              >
                <ListItemText primary="Investor" />
              </Typography>
            </ListItem>
            {/* Submenu for Masters */}
            <div className="ml-5">
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/investor">
                  <ListItemText primary="Assigned Investors" />
                </ListItem>
              </List>
            </div>
            <ListItem button>
              <Typography
                variant="h6"
                sx={{ fontSize: "12px", color: colors.primary }}
              >
                <ListItemText primary="Holdings" />
              </Typography>
            </ListItem>
            {/* Submenu for Masters */}
            <div className="ml-5">
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/holdings">
                  <ListItemText primary="AIF/FD/PMS/MF" />
                </ListItem>
                <ListItem button component={Link} to="/uploadHoldings">
                  <ListItemText primary="Upload"/>
                </ListItem>
              </List>
            </div>
            {/* <ListItem button>
              <Typography
                variant="h6"
                sx={{ fontSize: "12px", color: colors.primary }}
              >
                <ListItemText primary="Upload" />
              </Typography>
            </ListItem> */}
            {/* Submenu for Uploads */}
            {/* <div className="ml-5">
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/upload/file-aif">
                  <ListItemText primary="AIF" />
                </ListItem>
                <ListItem button component={Link} to="/upload/file-bonds">
                  <ListItemText primary="Bonds" />
                </ListItem>
                <ListItem button component={Link} to="/upload/file-pms">
                  <ListItemText primary="PMS" />
                </ListItem>
              </List>
            </div> */}
          </List>
        )}
      </Drawer>
      <Main open={open}>{/* Your main content goes here */}</Main>
    </Box>
  );
};

export default DrawerLeft;
