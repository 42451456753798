import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Header from "../../components/Header/Header";
import { colors } from "../../constants/colors";
import PMSReportsTable from "../../components/ReportsTable/PMS/PMSReportsTable";
import { notification } from "../../redux/notification/notificationSlice";

const PMSReports = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [notifyType, setNotifyType] = useState<any>();
  const dispatch = useDispatch<any>();

  const fetchReports = async () => {
    setLoading(true);
    try {
      const data = {
        notifyType: 8,
        pageIndex,
        pageSize,
      };
      const response = await dispatch(notification(data));
      console.log("Full response:", response); // Log the full response

      // Access the response properly
      if (response?.payload?.data?.data) {
        const filteredReports = response.payload.data.data.filter(
          (report: any) => report.notifyTitle === "PMS Withdraw Request"
        );
        const uniqueNotifyTypes = Array.from(
          new Set(filteredReports.map((report: any) => report.notifyType))
        );

        console.log("Unique notifyTypes:", uniqueNotifyTypes);
        console.log("Filtered reports:", filteredReports);

        setReports(filteredReports);
        setNotifyType(uniqueNotifyTypes);
        setTotalPages(response.payload.data.totalPages); // Make sure to access totalPages from the correct path
      } else {
        console.error("Response data is undefined:", response);
      }
    } catch (error) {
      console.error("Error fetching reports", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReports(); // Initial fetch
    const interval = setInterval(fetchReports, 180000); // Fetch every 3 minutes (180000 ms)

    return () => clearInterval(interval); // Cleanup on unmount
  }, [pageIndex, pageSize]);

  const handlePageChange = (newPageIndex: number) => {
    if (newPageIndex >= 1 && newPageIndex <= totalPages) {
      setPageIndex(newPageIndex);
    }
  };

  const handlePageSizeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setPageSize(Number(event.target.value));
    setPageIndex(1); // Reset to the first page when page size changes
  };

  return (
    <>
      <Header />
      <div>
        <div className="flex justify-start items-center mb-4 mx-[5rem]">
          <h1 className="text-2xl font-bold" style={{ color: colors.primary }}>
            PMS Withdraw Requests
          </h1>
        </div>
        <PMSReportsTable
          schemes={reports}
          loading={loading}
          notifyType={notifyType}
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          fetchData={fetchReports}
        />
      </div>
    </>
  );
};

export default PMSReports;
