import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import logo from "../../assets/images/logo.svg";
import {
  Menu,
  MenuItem,
  CircularProgress,
  Typography,
  Divider,
  ListItemIcon,
  Avatar,
} from "@mui/material";
import { AccountCircle, Logout , } from "@mui/icons-material";
import { colors } from "../../constants/colors";
import DrawerLeft from "../Drawer/Drawer";
import authService from "../../redux/auth/authService";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetState } from "../../redux/auth/authSlice";
import { useAuth } from "../../hooks/useAuth";
import notificationService from "../../redux/notification/notificationService";
import { Link } from "react-router-dom";
import userService from "../../redux/user/userService";
import api from "../../config/apiConfig";

const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] =
    useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState<number>(0); // State for unread notifications count
  const [countInNotification, setCountInNotification] = useState<number>(0); // State for unread notifications count
  const [newNotificationCount, setNewNotificationCount] = useState<number>(0);

  const [username, setUsername] = useState("");
  const userType = localStorage?.getItem("userType");
  console.log("usertype", userType);

  
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const logout = () => {
    authService.logout();
    dispatch(resetState());
    navigate("/");
  };

  const markAllAsRead = async () => {
    await Promise.all(
      notifications.map((n) =>
        notificationService.readRequest({ requestId: n.id })
      )
    );
    setNotifications((prevNotifications) =>
      prevNotifications.map((n) => ({ ...n, isRead: true }))
    );
    setNewNotificationCount(0);
  };

  const handleNotificationClick = async (
    notificationId: string,
    notifyType: number,
    notifyTitle:string
  ) => {
    try {
      await notificationService.readRequest({ requestId: notificationId });

      setNotifications((prevNotifications) =>
        prevNotifications.map((n) =>
          n.id === notificationId ? { ...n, isRead: true } : n
        )
      );

      if (notifyType === 2) {
        navigate("/reports/fd/buy");
      } else if (notifyType === 3) {
        navigate("/requests");
        window.location.reload();
      } else if (notifyType === 4) {
        navigate("/reports/mf");
        window.location.reload();
      } else if (notifyType === 5) {
        navigate("/aif/buy");
        window.location.reload();
      }  else if (notifyType === 6) {
        navigate("/pms/buy");
        window.location.reload();
      }  else if (notifyType === 7) {
        navigate("/bonds");
        window.location.reload();
      } else if (notifyType === 8 && notifyTitle === "AIF Withdraw Request") {
        navigate("/aif/sell");
        window.location.reload();
      }else if (notifyType === 8 && notifyTitle === "PMS Withdraw Request") {
        navigate("/pms/sell");
        window.location.reload();
      }else if (notifyType === 9) {
        navigate("/reports/fd");
        window.location.reload();
      } else if (notifyType === 10) {
        navigate("/reports/fd/sell");
        window.location.reload();
      } 

      handleNotificationClose();
    } catch (error) {
      console.error("Failed to mark notification as read", error);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationsAnchorEl(event.currentTarget);
    setTimeout(() => {
      handleBellClick();
    }, 0); // slight delay to ensure the menu opens first
  };

  const handleBellClick = async () => {
    try {
      await markAllAsRead();
    } catch (error) {
      console.error("Failed to mark notifications as read", error);
    }
  };

  const fetchNotifications = async () => {
    setLoading(true);
    setRowsPerPage(50);
    try {
      const response = await notificationService.notification({
        notifyType: 0,
        pageIndex: page + 1,
        pageSize: rowsPerPage,
      });
      const notificationsData = response.data.data ?? [];

      const today = new Date();
      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 2);

      const filteredNotifications = notificationsData.filter(
        (notification: any) => {
          const notificationDate = new Date(notification.createdAt);
          return notificationDate >= lastWeek && notificationDate <= today;
        }
      );

      const sortedNotifications = filteredNotifications.sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

      setNotifications(sortedNotifications);

      // Calculate unread count
      const countUnread = sortedNotifications.filter(
        (n: any) => !n.isRead
      ).length;
      setCountInNotification(countUnread);
      setNewNotificationCount(countUnread); // Set the new notification count

      setError(null);
    } catch (error) {
      setError("No notifications available");
      setNotifications([]);
      console.error("Failed to fetch notifications", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchUnreadCount = async () => {
      try {
        const response = await notificationService.requestCount({});
        console.log("Unread count data:", response); // Log response to verify the structure
        setUnreadCount(response.data); // Update this line to match the response structure
      } catch (error) {
        console.error("Failed to fetch unread notification count", error);
      }
    };

    fetchUnreadCount(); // Fetch count on component mount

    const interval = setInterval(() => {
      fetchUnreadCount(); // Fetch count every 3 minutes
    }, 180000); // 180000 milliseconds = 3 minutes

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      setRowsPerPage(50);
      try {
        const response = await notificationService.notification({
          notifyType: 0,
          pageIndex: page + 1,
          pageSize: rowsPerPage,
        });
        const notificationsData = response.data.data ?? [];

        const today = new Date();
        const lastWeek = new Date(today);
        lastWeek.setDate(today.getDate() - 2);

        const filteredNotifications = notificationsData.filter(
          (notification: any) => {
            const notificationDate = new Date(notification.createdAt);
            return notificationDate >= lastWeek && notificationDate <= today;
          }
        );

        const sortedNotifications = filteredNotifications.sort(
          (a: any, b: any) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );

        setNotifications(sortedNotifications);

        // Calculate unread count
        const countUnread = sortedNotifications.filter(
          (n: any) => !n.isRead
        ).length;
        setCountInNotification(countUnread);

        setError(null);
      } catch (error) {
        setError("No notifications available");
        setNotifications([]);
        console.error("Failed to fetch notifications", error);
      } finally {
        setLoading(false);
      }
    };

    if (notificationsAnchorEl) {
      fetchNotifications();
    }
  }, [notificationsAnchorEl, page, rowsPerPage]);

  useEffect(() => {
    fetchNotifications(); // Fetch notifications on mount

    const interval = setInterval(() => {
      fetchNotifications(); // Fetch notifications every 3 minutes
    }, 180000); // 180000 milliseconds = 3 minutes

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [page, rowsPerPage]); // Optional dependencies if page or rowsPerPage change

  const handleNotificationClose = () => {
    setNotificationsAnchorEl(null);
  };

  const fetchDetails = async () => {
    try {
      const accessToken = localStorage?.getItem("accessToken");
      const response = await api.post(
        "user/getById",
        {},
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
      setUsername(response?.data?.data[0]?.firstName);
      console.log("name", response?.data?.data[0]?.firstName);
    } catch (error) {
      console.error("Failed to fetch user Details", error);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <>
      <AppBar
        position="static"
        sx={{ height: "120px", width: "100%", backgroundColor: colors.white }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon
              sx={{
                color: "#255288",
                "&:hover": { backgroundColor: "transparent" },
              }}
            />
          </IconButton>
          <Link to="/analytics">
            <img
              src={logo}
              alt="Logo"
              className="ml-10 w-[90px] h-[90px] md:w-[120px] md:h-[120px] object-contain float-left"
            />
          </Link>
          <div style={{ flexGrow: 1 }} />
          <div className="flex items-center">
            <div className="mr-10">
              {userType !== "4" && (
                <IconButton
                  size="large"
                  aria-label="notifications"
                  color="inherit"
                  onClick={handleClick}
                  sx={{ "&:hover": { backgroundColor: "transparent" } }}
                >
                  <Badge
                    badgeContent={newNotificationCount}
                    color="error"
                    sx={{
                      "& .MuiBadge-dot": { backgroundColor: colors.primary },
                    }}
                  >
                    <NotificationsIcon
                      sx={{
                        color: colors.primary,
                        fontSize: "30px",
                        marginRight: 1,
                      }}
                    />
                  </Badge>
                </IconButton>
              )}
            </div>
            <Menu
              id="notifications-menu"
              anchorEl={notificationsAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(notificationsAnchorEl)}
              onClose={handleNotificationClose}
              PaperProps={{
                sx: {
                  maxHeight: "400px",
                  width: "500px",
                  overflowY: "auto",
                },
              }}
            >
              {loading ? (
                <MenuItem sx={{ justifyContent: "center" }}>
                  <CircularProgress size={24} />
                </MenuItem>
              ) : error ? (
                <MenuItem disabled sx={{ justifyContent: "center" }}>
                  {error}
                </MenuItem>
              ) : notifications && notifications.length > 0 ? (
                notifications.map((notification) => (
                  <React.Fragment key={notification.id}>
                    <MenuItem
                      sx={{
                        backgroundColor: notification.isRead
                          ? colors.darkGrey
                          : colors.white,
                        color: notification.isRead
                          ? colors.white
                          : colors.black,
                        "&:hover": {
                          backgroundColor: notification.isRead
                            ? colors.lightGrey
                            : colors.lightGrey,
                        },
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px",
                        maxHeight: "150px", // Set a max height for the notification item
                        overflowY: "auto", // Allow scrolling if the content is too long
                      }}
                      onClick={() =>
                        handleNotificationClick(
                          notification.id,
                          notification.notifyType,
                          notification.notifyTitle
                        )
                      }
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          color: notification.isRead
                            ? colors.white
                            : colors.black,
                          fontWeight: "bold",
                          marginBottom: "5px",
                        }} // Title styling
                      >
                        {notification.notifyTitle}{" "}
                        {/* Assuming you have a title field */}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: notification.isRead
                            ? colors.white
                            : colors.black,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "100%",
                        }}
                      >
                        {notification.notifyMessage
                          ? notification.notifyMessage.length > 60
                            ? `${notification.notifyMessage.substring(
                                0,
                                60
                              )}...`
                            : notification.notifyMessage
                          : "No message available"}
                      </Typography>
                    </MenuItem>

                    <Divider />
                  </React.Fragment>
                ))
              ) : (
                <MenuItem sx={{ justifyContent: "center" }}>
                  No notifications available.
                </MenuItem>
              )}
            </Menu>

            <Typography
              sx={{
                color: colors.primary,
                marginRight: 2,
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              {username}
            </Typography>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle sx={{ color: colors.primary, fontSize: "50px" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              keepMounted
              transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 0,
                  ml:-1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -1,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
            >
              <Link to="/profile">
            <MenuItem onClick={handleClose}>
              <Avatar sx={{ width: 30, height: 30,marginRight:2,marginLeft:-1 }}/> Profile
            </MenuItem>
          </Link>
              {/* <MenuItem
                onClick={handleClose}
                sx={{
                  color: colors.primary,
                  "&:hover": { color: colors.orange },
                  padding: "10px 50px",
                }}
              >
                Profile
              </MenuItem> */}
               <Divider />
              <MenuItem
                onClick={() => {
                  logout();
                  handleClose();
                }}
              >
                <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <DrawerLeft toggleDrawer={toggleDrawer} open={drawerOpen} />
    </>
  );
};

export default Header;
