// BasicBars.tsx
import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

interface BasicBarsProps {
    data: Array<{ name: string; value: number }>;
}

const BasicBars: React.FC<BasicBarsProps> = ({ data }) => {
    console.log("Bar Chart Data:", data); // Log the data received
    return (
        <BarChart
            margin={{ top: 50, right: 30, left: 80, bottom: 50 }}
            xAxis={[{ scaleType: 'band', data: data.map(item => item.name) }]}
            series={[{ data: data.map(item => item.value) }]}
            width={800}
            height={400}
           />
    );
};


export default BasicBars;
