import { Navigate, Outlet } from 'react-router-dom';


const isAuthenticated = () => {
  return localStorage.getItem('accessToken'); 
};

const PrivateRoute = () => {
  return isAuthenticated() ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
