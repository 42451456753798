import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import mfService from "./mfService";


export const getMF = createAsyncThunk(
    "mf/getMF",
    async (data: any, thunkAPI) => {
      try {
         const response = await mfService.getMF(data);
         console.log('API call successful. Response:', response);
         return response;
      } catch (error: any) {
        console.error('Error in API call:', error);
        return thunkAPI.rejectWithValue(error.response?.data);
      }
    }
  );

  export const getMFHoldings = createAsyncThunk(
    "funds/getHoldings",
    async (data: any, thunkAPI) => {
      try {
         const response = await mfService.getMFHoldings(data);
         console.log('API call successful. Response:', response);
         return response;
      } catch (error: any) {
        console.error('Error in API call:', error);
        return thunkAPI.rejectWithValue(error.response?.data);
      }
    }
  );

const initialState = {
    data: {},
    totalPages: 0,
    pageIndex: 1,
    isError: false,
    isSuccess: false,
    isLoading: false,
    isDownloading: false, // New state to manage download operation
    message: "",
};

export const mfSlice = createSlice({
    name: "mf",
    initialState,
    reducers: {
      resetState: (state) => initialState,
    },
    
    extraReducers: (builder) => {
      builder
        .addCase(getMF.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getMF.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.data = action.payload; 
          console.log('hey this is mf slice', state.data)
        })
        .addCase(getMF.rejected, (state, action) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.message = action.error.message || "";
          })
          .addCase(getMFHoldings.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(getMFHoldings.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = true;
            state.data = action.payload; 
            console.log('hey this is mf slice', state.data)
          })
          .addCase(getMFHoldings.rejected, (state, action) => {
              state.isError = true;
              state.isLoading = false;
              state.isSuccess = false;
              state.message = action.error.message || "";
            })
    }
    
  
  });
  
  export const { resetState } = mfSlice.actions;
  export default mfSlice.reducer;
