import { LoginModel } from "../../models/login.model";
import {jwtDecode} from "jwt-decode";
import api from "../../config/apiConfig";

const accessTokenKey = "accessToken";
const refreshTokenKey = "refreshToken";
const userTypeKey="userType";

const getToken = () => {
  return {
    accessToken: localStorage.getItem(accessTokenKey),
    refreshToken: localStorage.getItem(refreshTokenKey),
    userType: parseInt(localStorage.getItem(userTypeKey) || '0', 10)
  };
};

const setToken = (accessToken: string, refreshToken: string ,userType :number) => {
  localStorage.setItem(accessTokenKey, accessToken);
  localStorage.setItem(refreshTokenKey, refreshToken);
  localStorage.setItem(userTypeKey, userType.toString());
};

const isTokenExpired = () => {
  const { accessToken } = getToken();
  if (!accessToken) {
    return true;
  }
  const decoded: any = jwtDecode(accessToken);
  return decoded.exp && Date.now() >= decoded.exp * 1000;
};

const getAccessToken = async () => {
  const { refreshToken } = getToken();
  if (!refreshToken) {
    throw new Error("No refresh token available");
  }
  return api.post("/user/refreshToken", { refreshToken });
};

const refreshToken = async () => {
  try {
    const response = await getAccessToken();
    const { accessToken, refreshToken,userType} = response.data?.data;
    setToken(accessToken, refreshToken, userType);
  } catch (error) {
    console.error("Failed to refresh token", error);
    logout();
  }
};

const login = async (data: LoginModel) => {
  const response = await api.post("/admin/logIn", data);
  const { accessToken, refreshToken ,userType } = response.data?.data;
  setToken(accessToken, refreshToken ,userType);
  return response.data;
};

const logout = () => {
  localStorage.removeItem(accessTokenKey);
  localStorage.removeItem(refreshTokenKey);
  localStorage.removeItem("admin");
  window.location.href = "/";
};

const authService = {
  getToken,
  isTokenExpired,
  refreshToken,
  login,
  logout,
};

export default authService;
