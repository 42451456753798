import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bankService from "./bankService";

export const updateBank = createAsyncThunk(
    "bank/update",
    async (data: FormData, thunkAPI: any) => {
      try {

        const response = await bankService.updateBank(data);
        console.log(response, ' this is the update bank response');
        return response

      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  )

  const initialState = {
    data: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  };

  export const bankSlice = createSlice({
    name: "bank",
    initialState: initialState,
    reducers: {
      resetState: (state) => initialState,
    },
    extraReducers: (builder) => {
      builder
        .addCase(updateBank.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(updateBank.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.data = action.payload.data;
        })
        .addCase(updateBank.rejected, (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = action.error.message || "";
        })
    },
  });
  
  export const { resetState } = bankSlice.actions;
  export default bankSlice.reducer;