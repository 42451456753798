import React, { useState } from 'react';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    TableFooter,
    CircularProgress,
    Button,
    Backdrop,
} from '@mui/material';
import { CheckCircle as CheckCircleIcon, Cancel as CancelIcon, UnfoldMore as UnfoldMoreIcon } from '@mui/icons-material';
import StatusUpdateModal from '../../Modal/Modal';
import { colors } from '../../../constants/colors';

interface Scheme {
    id: number;
    userId: number; // Add userId
    notifyTitle: string;
    notifyMessage: string;
    isRead: number; // Add isRead
    requestStatus: number;
    createdAt: string; // Add createdAt
}

interface MFTableProps {
    schemes: Scheme[];
    loading: boolean;
    pageIndex: number;
    pageSize: number;
    totalPages: number;
    onPageChange: (newPageIndex: number) => void;
    onPageSizeChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
    updateSchemeStatus: (id: number, status: number) => Promise<void>; // Ensure this is a Promise
    refreshReports: () => void;  // New prop to refresh reports
}

const MFTable: React.FC<MFTableProps> = ({
    schemes,
    loading,
    pageIndex,
    pageSize,
    totalPages,
    onPageChange,
    onPageSizeChange,
    updateSchemeStatus,
    refreshReports,
}) => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedScheme, setSelectedScheme] = useState<Scheme | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [statusMessage, setStatusMessage] = useState<string>('Loading...');

    const handleStatusChange = (id: number) => {
        const scheme = schemes.find(scheme => scheme.id === id);
        if (scheme) {
            setSelectedScheme(scheme);
            setOpenModal(true);
        }
    };

    const handleModalClose = () => {
        setOpenModal(false);
        setSelectedScheme(null);
    };

    const handleModalSubmit = async (data: { requestId: number; status: number }) => {
        setIsLoading(true); // Show the backdrop
        setStatusMessage('Updating status...'); // Set loading message
        await updateSchemeStatus(data.requestId, data.status);
        
        // Wait for 2 seconds before closing the modal and refreshing reports
        setTimeout(() => {
            handleModalClose();
            refreshReports(); // Call refreshReports after modal is submitted
            setIsLoading(false); // Hide the backdrop
        }, 2000); // 2000 milliseconds = 2 seconds
    };
    
    return (
        <Box width="100%" display="flex" flexDirection="column" alignItems="center" marginBottom={2}>
            <Box width="90%">
                <TableContainer component={Paper} sx={{ padding: '0 20px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        Title
                                        <UnfoldMoreIcon sx={{ fontSize: 'small' }} />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        Message
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        Status
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : schemes.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        No data available.
                                    </TableCell>
                                </TableRow>
                            ) : (
                                schemes.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.notifyTitle}</TableCell>
                                        <TableCell sx={{ width: 700 }}>
                                            {row.notifyMessage}
                                        </TableCell>
                                        <TableCell>
                                            {row.requestStatus === 0 ? (
                                                <Button
                                                    variant="contained"
                                                    sx={{ background: colors.primary }}
                                                    onClick={() => handleStatusChange(row.id)}
                                                >
                                                    Update Status
                                                </Button>
                                            ) : row.requestStatus === 1 ? (
                                                <CheckCircleIcon style={{ color: 'green' }} />
                                            ) : (
                                                <CancelIcon style={{ color: 'red' }} />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    count={totalPages * pageSize}
                                    rowsPerPage={pageSize}
                                    page={pageIndex - 1}
                                    onPageChange={(event, newPage) => onPageChange(newPage + 1)}
                                    onRowsPerPageChange={onPageSizeChange}
                                    labelDisplayedRows={({ from, to, count }) =>
                                        `${pageIndex} – ${totalPages}`
                                    }
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>

            {selectedScheme && (
                <StatusUpdateModal
                    open={openModal}
                    onClose={handleModalClose}
                    onSubmit={handleModalSubmit}
                    scheme={selectedScheme}
                />
            )}

            <Backdrop
                open={isLoading}
                style={{
                    position: "fixed",
                    zIndex: 10,
                    color: "#fff",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    left: 0,
                    top: 0,
                    width: "100vw",
                    height: "100vh",
                }}
            >
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <CircularProgress color="inherit" />
                    <Typography variant="h6" style={{ marginTop: "16px" }}>
                        {statusMessage}
                    </Typography>
                </div>
            </Backdrop>
        </Box>
    );
};

export default MFTable;
