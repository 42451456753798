import React, { useState, useRef } from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import { colors } from '../../constants/colors';
import Header from '../../components/Header/Header';

const UploadComponent: React.FC = () => {
  const [file, setFile] = useState<File | null>(null); // State to track uploaded file
  const fileInputRef = useRef<HTMLInputElement | null>(null); // Ref for file input

  // Function to handle file upload
  const handleFileUpload = (file: File) => {
    // Your file upload logic here
    console.log(`Uploading file: ${file.name}`);
  };

  // Function to handle file change event
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      // Optionally handle the file upload after selection
      // handleFileUpload(selectedFile);
    }
  };

  // Function to open file input dialog
  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input click
    }
  };

  const handleUploadAndReplace = () => {
    console.log('Upload and Replace clicked');
    openFileDialog(); // Open file dialog
  };

  const handleUploadAndAdd = () => {
    console.log('Upload and Add clicked');
    openFileDialog(); // Open file dialog
  };

  const handleRemoveFile = () => {
    setFile(null); // Clear the file state
    console.log('File removed');
  };

  const handleChangeFile = () => {
    openFileDialog(); // Open file dialog to select a new file
  };

  const handleUpload = () => {
    if (file) {
      handleFileUpload(file);
      // Optionally clear file after upload
      setFile(null);
    }
  };

  return (
    <>
      <Header />
      <div className="flex justify-center items-center p-8">
        {/* Big Box Wrapper */}
        <div
          style={{
            border: '2px dotted #ccc',
            borderRadius: '8px',
            padding: '32px',
            width: '800px',
            height: '300px',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            textAlign: 'center'
          }}
        >
          {file ? (
            <div className='flex flex-col items-center'>
              <h3 className='font-bold mb-5'>Uploaded File</h3>
              <div
                style={{
                  backgroundColor: colors.lightBg,
                  border: '1px solid',
                  padding: '16px',
                  borderRadius: '2px',
                  width: '100%',
                  textAlign: 'center'
                }}
              >
                <p>{file.name}</p>
                {/* Text options below the file name */}
                <div className="mt-4">
                  <span 
                    onClick={handleChangeFile}
                    style={{ color: colors.primary, cursor: 'pointer', marginRight: '15px' }}
                  >
                    Change File
                  </span>
                  |
                  <span 
                    onClick={handleRemoveFile}
                    style={{ color: colors.primary, cursor: 'pointer', marginLeft: '15px' }}
                  >
                    Remove File
                  </span>
                </div>
              </div>
              <button
                  onClick={handleUpload}
                  className="mt-4"
                  style={{
                    cursor: 'pointer',
                    border: '2px solid #ccc',
                    borderRadius: '8px',
                    padding: '10px 20px',
                    backgroundColor: colors.primary,
                    color: 'white',
                    fontWeight: 'bold'
                  }}
                >
                  Upload
                </button>
            </div>
          ) : (
            // Show upload buttons if no file is uploaded
            <div className="flex justify-center items-center gap-4">
              {/* Upload and Replace Button */}
              <button
                onClick={handleUploadAndReplace}
                className="flex flex-col items-center"
                style={{
                  cursor: 'pointer',
                  border: '2px dashed #ccc',
                  borderRadius: '8px',
                  padding: '16px',
                  width: '150px',
                  height: '150px',
                  backgroundColor: 'white',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AddIcon style={{ fontSize: 50, color: colors.primary }} />
                <span style={{ marginTop: '10px', fontWeight: 'bold' }}>Upload and Replace</span>
              </button>

              {/* Slash Separator */}
              <div
                style={{
                  width: '30px',
                  height: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  opacity: 0.3, // Low opacity
                }}
              >
                <span style={{ fontSize: '24px', color: '#ccc' }}>|</span>
              </div>

              {/* Upload and Add Button */}
              <button
                onClick={handleUploadAndAdd}
                className="flex flex-col items-center"
                style={{
                  cursor: 'pointer',
                  border: '2px dashed #ccc',
                  borderRadius: '8px',
                  padding: '16px',
                  width: '150px',
                  height: '150px',
                  backgroundColor: 'white',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AddIcon style={{ fontSize: 50, color: colors.primary }} />
                <span style={{ marginTop: '10px', fontWeight: 'bold' }}>Upload and Add</span>
              </button>
            </div>
          )}

          {/* Hidden file input field */}
          <input
            type="file"
            accept=".xml,.xlsx"
            ref={fileInputRef} // Attach ref
            style={{ position: 'absolute', top: 0, left: 0, width: '0', height: '0', opacity: 0, cursor: 'pointer' }}
            onChange={handleFileChange} // Handle file selection
          />
        </div>
      </div>
    </>
  );
};

export default UploadComponent;
