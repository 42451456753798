import { Box, Card, Typography, Grid, Button, IconButton, Snackbar } from "@mui/material";
import React, { useState } from "react";
import { colors } from "../../constants/colors";
import { FormInput } from "../FormInput/FormInput";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import EditModal from "../Modal/confirmationModal";
import ConfirmationSuccessModal from "../Modal/confirmationSuccessModal";
import { updateProfile } from "../../redux/user/userSlice";
import { useDispatch } from "react-redux";
interface GeneralDetailsProps {
  user: {
    id:string;
    firstName: string;
    lastName: string;
    email:string;
    mobile:string;
  };
}

interface UserData {
  id:number;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  address1?: string;
  address2?: string;
  address3?: string;
  Entity?: string;
  kyc?: {
    pancardNumber?: string;
    aadharNumber?: string;
  };
}

interface EditState {
  [key: string]: boolean;
}

interface InputValues {
  [key: string]: string;
}

const GeneralDetails: React.FC<GeneralDetailsProps> = ({user}) => {
  const dispatch = useDispatch<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState('');
  const [updateStatus, setUpdateStatus] = useState<string | null>(null);
  const [newValue, setNewValue] = useState<any>();
  const [modalData, setModalData] = useState<{
    type: 'phone' | 'email' | 'text' | 'address';
    value: string;
  }>({ type: 'phone', value: '' });

  const [editState, setEditState] = useState<EditState>({
    name: false,
    mobile: false,
    email: false,
  });

  const [inputValues, setInputValues] = useState<InputValues>({
    name: `${user?.firstName ? user?.firstName + user?.lastName : ""}`,
    mobile: user?.mobile || "",
    email: user?.email||"",
  });

  const [notificationMessage, setNotificationMessage] = useState<string | null>(null);

  const handleEditClick = (field: keyof EditState) => {
    if (field === "mobile" || field === "email") {
        setModalData({
            type: field === "mobile" ? "phone" : "email",
            value: field === "mobile" ? user.mobile : user.email,
        });
        setIsModalOpen(true);
    } else {
        setEditState((prevState) => ({
            ...prevState,
            [field]: true,
        }));
    }
};

  

  const handleModalConfirm = (newValue: string) => {
    setNewValue(newValue);

    // Update the specific field in inputValues
    setInputValues((prevState) => ({
      ...prevState,
      [modalData.type === 'phone' ? 'mobile' : 'email']: newValue, // Update with new value
    }));

    setEditState((prevState) => ({
      ...prevState,
      [modalData.type === 'phone' ? 'mobile' : 'email']: true,
    }));

    handleUpdateProfile(newValue); // Update profile with new value
    setIsModalOpen(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsConfirmationModalOpen(false);

    // Clear the input field and modal data
    setNewValue('');
    setModalData({ type: 'phone', value: '' });
  };

  const handleInputChange = (field: keyof InputValues, value: string) => {
    setInputValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleConfirmationConfirm = () => {
    // Handle confirmation action here
    console.log('Confirmation confirmed!');
    
    // Close ConfirmationModal
    setIsConfirmationModalOpen(false);
    window.location.reload();
  };

  const handleUpdateProfile = async (newValue: string) => {
    let data ;
  
    // Add details to the USER section if applicable
    if (modalData.type === 'phone') {
      data=
        {
          mobile: newValue,
        }
    } else if (modalData.type === 'email') {
      data= 
        {          
          email: newValue,
        }
    }  
    console.log('Data to send:', data);
  
    try {
      const response = await dispatch(updateProfile(data)); // Send the data payload to the API
      setUpdateStatus('Profile updated successfully!');
      console.log('API Response:', response);
  
      const mobileRequested = modalData.type === 'phone';
      const emailRequested = modalData.type === 'email';  
      setNotificationMessage(
        mobileRequested && emailRequested 
          ? 'Phone number, email, and address have been requested for change.'
          : mobileRequested && emailRequested
          ? 'Both phone number and email have been requested for change.'
          : mobileRequested
          ? 'Phone number has been requested for change.'
          : emailRequested
          ? 'Email has been requested for change.'
          : ''
      );
  
      setIsConfirmationModalOpen(true);
      
    } catch (error) {
      setUpdateStatus('Failed to update profile.');
      console.error('API Error:', error);
    }
  
    // Reset edit state after saving
    setEditState({
      name: false,
      mobile: false,
      email: false,
      address: false,
      entity: false,
      designation: false,
      pan: false,
      aadhar: false,
    });
  };
  

  const handleCancel = (field: keyof EditState) => {
    setInputValues((prevState) => ({
      ...prevState,
      name: `${user?.firstName} ${user?.lastName}`,
      mobile: user.mobile,
      email: user.email,
    }));

    setEditState((prevState) => ({
      ...prevState,
      [field]: false,
    }));
  };


  return (
    <div className=" border border-lightGrey rounded-md ">
      <Card
        style={{
          backgroundColor: colors.lightBg,
          minHeight: "60px",
          display: "flex",
          alignItems: "center",
          padding: "0",
        }}
        className="h-8 w-full"
      >
        <Typography variant="h5" marginLeft={2} color={colors.darkGrey} fontWeight='bold'>
          General Details
        </Typography>
      </Card>

      {/* Display Notification Message */}
      
      <div className="justify-center pt-4 mx-4 ">
      {/* {notificationMessage && (
        <div style={{ backgroundColor: '#f17b21', padding: '10px', borderRadius: '5px', marginBottom: '10px', textAlign: 'center' }}>
          <Typography variant="body1" color="textSecondary" fontWeight='bold'>
            {notificationMessage}
          </Typography>
        </div>
      )} */}
      <Grid
          container
          spacing={2}
          className="flex flex-wrap justify-between py-12 items-center"
        >
          <Grid item xs={12} md={6} lg={4}>
            {editState.name ? (
              <Box display="flex" alignItems="center">
                <FormInput
                  label="Name"
                  name="name"
                  value={inputValues.name}
                  onChange={(e: any) =>
                    handleInputChange("name", e.target.value)
                  }
                  placeholder="Full Name"
                  className="mb-4"
                  required
                />
                <IconButton onClick={() => handleCancel("name")}>
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : (
              <div>
                <span className="text-textGrey text-sm md:text-base">
                  Name
                </span>
                <Typography
                  variant="h6"
                  color={colors.darkGrey}
                  className="font-bold text-sm md:text-base"
                >
                  {user?.firstName} {user?.lastName}
                </Typography>
              </div>
            )}
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <div>
              <span className="text-textGrey text-sm md:text-base">
                Phone Number{" "}
                <IconButton
                  onClick={() => handleEditClick("mobile")}
                  size="small"
                >
                  <EditIcon sx={{ color: colors.primary }} />
                </IconButton>
              </span>
              <Typography
                variant="h6"
                color={colors.darkGrey}
                className="font-bold text-sm md:text-base"
              >
                {user.mobile}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <div>
              <span className="text-textGrey text-sm md:text-base">
                Email{" "}
                <IconButton
                  onClick={() => handleEditClick("email")}
                  size="small"
                >
                  <EditIcon sx={{ color: colors.primary }} />
                </IconButton>
              </span>
              <Typography
                variant="h6"
                color={colors.darkGrey}
                className="font-bold text-sm md:text-base"
              >
                {user.email}
              </Typography>
            </div>
          </Grid>
        </Grid>

        {/* <Grid
          container
          spacing={2}
          className="border-t border-lightGrey py-8"
        >
          <Grid item xs={12} md={6} lg={4}>
            <div>
              <span className="text-textGrey text-sm md:text-base">
                Entity type
              </span>
              <Typography
                variant="h6"
                color={colors.darkGrey}
                className="font-bold text-sm md:text-base"
              >
                {user.Entity || "-"}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <div>
              <span className="text-textGrey text-sm md:text-base">
                Designation
              </span>
              <Typography
                variant="h6"
                color={colors.darkGrey}
                className="font-bold text-sm md:text-base"
              >
                {user.Entity || "-"}
              </Typography>
            </div>
          </Grid>
        </Grid> */}
{/* 
        <Grid container spacing={2}  className="border-t border-lightGrey py-8">
          <Grid item xs={12} md={6} lg={4}>
            <div>
              <span className="text-textGrey text-sm md:text-base">
                PAN No.
              </span>
              <Typography
                variant="h6"
                color={colors.darkGrey}
                className="font-bold text-sm md:text-base"
              >
                {user?.kyc?.pancardNumber || "-"}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <div>
              <span className="text-textGrey text-sm md:text-base">
                Aadhar No.
              </span>
              <Typography
                variant="h6"
                color={colors.darkGrey}
                className="font-bold text-sm md:text-base"
              >
                {user?.kyc?.aadharNumber || "-"}
              </Typography>
            </div>
          </Grid>
        </Grid> */}

        <EditModal
          open={isModalOpen}
          type={modalData.type}
          value={modalData.value}
          onConfirm={handleModalConfirm}
          onClose={handleModalClose}
        />

       <ConfirmationSuccessModal
        open={isConfirmationModalOpen}
        type={modalData.type}
        value={modalData.value}
        onConfirm={handleConfirmationConfirm}
        onClose={handleModalClose}
      />

      </div>
    </div>

  );
};

export default GeneralDetails;
