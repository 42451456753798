const getInitials = (name: string) => {
    if (!name) {
        return;
    }
    const words = name.split(' ');
    const firstInitial = words[0][0];
    const secondInitial = words.length > 1 ? words[1][0] : '';
    return `${firstInitial}${secondInitial}`.toUpperCase();
}

export default getInitials;
