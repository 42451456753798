import RegisterForm from '../../components/RMTable/AddRM';
import Header from '../../components/Header/Header';

  const AddUser = () => {

    return (
      <>
          <Header/>
          <div style={{padding:'50px 100px'}}>    
              <RegisterForm/>
          </div>
      </>
  
    );
};

export default AddUser;
