import React, { useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TableFooter,
  FormControl,
  CircularProgress,
  Select,
  MenuItem,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import { UnfoldMore as UnfoldMoreIcon, CheckCircle as CheckCircleIcon, Cancel as CancelIcon, Visibility as EyeIcon } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { changeStatus } from '../../../redux/portfolio/changeStatus/statusSlice';
import { getNotificationById } from '../../../redux/notification/notificationSlice';
import { colors } from '../../../constants/colors';
import EditIcon from '@mui/icons-material/Edit';

interface InvestmentData {
  bondName: string;
  minInvestment: string;
  inception: string;
  coupon: number;
  investmentAmount: number;
}

interface Scheme {
  id: number;
  notifyTitle: string;
  notifyMessage: string;
  isRead: number;
  requestStatus: number | ""; 
  createdAt: string;
  investmentData?: InvestmentData; // Add this field to the Scheme interface
}

interface BondsReportsTableProps {
  schemes: Scheme[];
  loading: boolean;
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  onPageChange: (newPageIndex: number) => void;
  onPageSizeChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  notifyType?: number[];
}

const BondsReportsTable: React.FC<BondsReportsTableProps> = ({
  schemes,
  loading,
  pageIndex,
  pageSize,
  totalPages,
  onPageChange,
  onPageSizeChange,
  notifyType
}) => {
  const [loadingRows, setLoadingRows] = useState<number[]>([]);
  const [confirmedStatus, setConfirmedStatus] = useState<{ [key: number]: number | undefined }>({});
  const [selectedInvestmentData, setSelectedInvestmentData] = useState<InvestmentData | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<string>("");
  const dispatch = useDispatch<any>();
  const [editMode, setEditMode] = useState(false);


  const fetchNotificationById = (id: number) => {
    dispatch(getNotificationById({ id }))
      .then((response: any) => {
        if (response.payload.data) {
          setSelectedInvestmentData(response.payload.data.investmentData); // Set investmentData
          setDialogType(notifyType && notifyType[0] === 7 ? "Buy" : "Sell");
          setDialogOpen(true); // Open dialog with the details
        } else {
          console.error('Unexpected response structure:', response);
        }
      })
      .catch((error: any) => {
        console.error(`Error fetching notification for ID: ${id}`, error);
      });
  };

  const handleStatusChange = (id: number, status: number) => {
    setLoadingRows((prev) => [...prev, id]);

    dispatch(changeStatus({ id, status }))
      .then(() => {
        setConfirmedStatus((prev) => ({ ...prev, [id]: status }));
        window.location.reload();
      })
      .catch((error: any) => {
        console.error(`Error changing status for ID: ${id}`, error);
      })
      .finally(() => {
        setLoadingRows((prev) => prev.filter((rowId) => rowId !== id));
      });
  };

  const handleEditClick = () => {
    setEditMode(!editMode); // Toggle edit mode
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedInvestmentData) {
      setSelectedInvestmentData({
        ...selectedInvestmentData,
        [e.target.name]: e.target.value, // Update investment data fields on change
      });
    }
  };

  const handleSave = () => {
    // Implement the save functionality (API call or state update)
    console.log("Updated Investment Data:", selectedInvestmentData);
    setEditMode(false); // Switch back to view mode after saving
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedInvestmentData(null);
    setEditMode(false); // Reset edit mode when dialog is closed
  };
  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center" marginBottom={2}>
      <Box width="90%" mb={2} display="flex" justifyContent="space-between"></Box>
      <Box width="90%">
        <TableContainer component={Paper} sx={{ padding: '0 20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                {[
                  { label: 'Title', key: 'notifyTitle' },
                  { label: 'Message', key: 'notifyMessage' },
                  { label: 'Status', key: 'requestStatus' },
                  { label: 'Date', key: 'createdAt' },
                  { label: 'Details', key: 'details' },
                ].map(({ label, key }) => (
                  <TableCell key={key}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {label}
                      <UnfoldMoreIcon sx={{ fontSize: 'small' }} />
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {schemes.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.notifyTitle}</TableCell>
                  <TableCell>{row.notifyMessage}</TableCell>
                  <TableCell
                      sx={{
                        width: "150px",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      {row.requestStatus === 1 ? (
                        <CheckCircleIcon style={{ color: "green" }} />
                      ) : row.requestStatus === 2 ? (
                        <CancelIcon style={{ color: "red" }} />
                      ) : (
                        <FormControl
                          fullWidth
                          disabled={loadingRows.includes(row.id)}
                        >
                          <Select
                            value={row.requestStatus ?? ""} // Default to empty string if undefined
                            onChange={(e) => {
                              const newValue = e.target.value;
                              if (newValue !== undefined) {
                                handleStatusChange(row.id, newValue as number);
                              }
                            }}
                            sx={{ width: "100%" }}
                          >
                            <MenuItem value={0} disabled>
                              Select
                            </MenuItem>
                            <MenuItem value={1}>Accept</MenuItem>
                            <MenuItem value={2}>Reject</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                      {loadingRows.includes(row.id) && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "rgba(255, 255, 255, 0.8)",
                            zIndex: 1,
                          }}
                        >
                          <CircularProgress size={20} />
                        </div>
                      )}
                    </TableCell>
                  <TableCell>{new Date(row.createdAt).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => fetchNotificationById(row.id)}>
                         <EyeIcon sx={{color:colors.primary}}/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  count={totalPages * pageSize}
                  rowsPerPage={pageSize}
                  page={pageIndex - 1}
                  onPageChange={(event, newPage) => onPageChange(newPage)}
                  onRowsPerPageChange={onPageSizeChange}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${pageIndex} – ${totalPages}`
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>

      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
      <DialogTitle style={{ backgroundColor: colors.primary, color: colors.white }}>
        Investment Details
        {!editMode && (
          <IconButton onClick={handleEditClick} style={{ color: colors.white, float: "right" }}>
            <EditIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent style={{ backgroundColor: colors.lightBg, paddingTop: 16, paddingBottom: 16 }}>
        {selectedInvestmentData ? (
          <Box>
            {editMode ? (
              <>
                <TextField
                  fullWidth
                  label="Bond Name"
                  name="bondName"
                  value={selectedInvestmentData.bondName}
                  onChange={handleFieldChange}
                  style={{ marginBottom: 8 }}
                />
                <TextField
                  fullWidth
                  label="Minimum Investment"
                  name="minInvestment"
                  value={selectedInvestmentData.minInvestment}
                  onChange={handleFieldChange}
                  style={{ marginBottom: 8 }}
                />
                <TextField
                  fullWidth
                  label="Coupon"
                  name="coupon"
                  type="number"
                  value={selectedInvestmentData.coupon}
                  onChange={handleFieldChange}
                  style={{ marginBottom: 8 }}
                />
                <TextField
                  fullWidth
                  label="Investment Amount"
                  name="investmentAmount"
                  type="number"
                  value={selectedInvestmentData.investmentAmount}
                  onChange={handleFieldChange}
                  style={{ marginBottom: 8 }}
                />
              </>
            ) : (
              <>
                <Typography variant="body1" style={{ marginBottom: 8 }}>
                  <strong style={{ color: colors.primary }}>Bond Name:</strong> {selectedInvestmentData.bondName}
                </Typography>
                <Typography variant="body1" style={{ marginBottom: 8 }}>
                  <strong style={{ color: colors.primary }}>Minimum Investment:</strong> {selectedInvestmentData.minInvestment}
                </Typography>
                <Typography variant="body1" style={{ marginBottom: 8 }}>
                  <strong style={{ color: colors.primary }}>Coupon:</strong> {selectedInvestmentData.coupon}
                </Typography>
                <Typography variant="body1" style={{ marginBottom: 8 }}>
                  <strong style={{ color: colors.primary }}>Investment Amount:</strong> {selectedInvestmentData.investmentAmount}
                </Typography>
                <Typography variant="body1" style={{ marginBottom: 8 }}>
                  <strong style={{ color: colors.primary }}>Type:</strong> {dialogType}
                </Typography>
              </>
            )}
          </Box>
        ) : (
          <Typography>No details available.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} style={{ color: colors.primary }}>
          Close
        </Button>
        {editMode && (
          <Button onClick={handleSave} style={{ color: colors.primary }}>
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
    </Box>
  );
};

export default BondsReportsTable;
