import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import Header from '../../components/Header/Header';

const PageNotFound: React.FC = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate('/investor');
    };

    return (
      <>
      <Header/>
      <Container 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '70vh',
                textAlign: 'center',
            }}
        >
            <Typography variant="h1" component="div" gutterBottom>
                404
            </Typography>
            <Typography variant="h4" component="div" gutterBottom>
                Page Not Found
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
                The user you are looking for does not exist.
            </Typography>
            <Button 
                variant="contained" 
                color="primary" 
                onClick={handleGoBack}
                sx={{ mt: 3 }}
            >
                Go Back
            </Button>
        </Container>
      </>
    );
};

export default PageNotFound;
